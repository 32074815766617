import { useState } from 'react';
import { isValidInput } from './validation';

export function useForm({ defaultValues = {}, isFormValidDefault = false } = {}) {
  const [form, setForm] = useState(defaultValues);
  const [isFormValid, setIsFormValid] = useState(isFormValidDefault);

  const handleChange = (value, name, rules) => {
    setForm({ ...form, [name]: value });
    setIsFormValid(isValidInput(value, rules));
  };

  const register = (name, rules = {}) => {
    return {
      name,
      value: form[name],
      onChange: (event) => handleChange(event.target.value, name, rules),
    };
  };

  return {
    form,
    register,
    isFormValid,
  };
}
