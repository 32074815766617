const DEFAULT_DISPLAY_MESSAGE = 'No pudimos procesar los datos de tu tarjeta';

export const CARD_TOKENIZATION_ERROR_NAME = 'CardTokenizationError';

class CardTokenizationError extends Error {
  constructor(message, params = { displayMessage: DEFAULT_DISPLAY_MESSAGE }) {
    super(message);
    this.name = CARD_TOKENIZATION_ERROR_NAME;
    this.displayMessage = params.displayMessage;
  }
}

export default CardTokenizationError;
