import { ACTION_TYPES } from './actionTypes';
import { STATE_KEYS } from './constants';

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.UPDATE_CHECKOUT_CONFIRMATION_STATUS: {
      return {
        ...state,
        ...payload,
      };
    }
    case ACTION_TYPES.UPDATE_CHECKOUT_BACKEND_ACTION_STATUS: {
      return {
        ...state,
        [STATE_KEYS.CHECKOUT_BACKEND_ACTION_STATUS]: {
          ...state[STATE_KEYS.CHECKOUT_BACKEND_ACTION_STATUS],
          ...payload[STATE_KEYS.CHECKOUT_BACKEND_ACTION_STATUS],
        },
      };
    }
    case ACTION_TYPES.UPDATE_EXPIRED_CHECKOUT_REDIRECT_URL: {
      return {
        ...state,
        [STATE_KEYS.ON_EXPIRED_CHECKOUT_REDIRECT_TO]: payload[STATE_KEYS.ON_EXPIRED_CHECKOUT_REDIRECT_TO],
      };
    }
    case ACTION_TYPES.UPDATE_CHECKOUT_TYPE: {
      return {
        ...state,
        [STATE_KEYS.CHECKOUT_TYPE]: payload[STATE_KEYS.CHECKOUT_TYPE],
      };
    }
    case ACTION_TYPES.UPDATE_TERMS_AND_CONDITIONS_STATUS: {
      return {
        ...state,
        [STATE_KEYS.MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED]: payload[STATE_KEYS.MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED],
      };
    }
    case ACTION_TYPES.ADD_PAYMENT_INSTRUMENT_CHALLENGE_RESULT: {
      const { paymentInstrumentId, challengeId, result } = payload;

      return {
        ...state,
        [STATE_KEYS.PAYMENT_INSTRUMENTS_CHALLENGES_RESULTS]: {
          ...state[STATE_KEYS.PAYMENT_INSTRUMENTS_CHALLENGES_RESULTS],
          [paymentInstrumentId]: {
            ...state[STATE_KEYS.PAYMENT_INSTRUMENTS_CHALLENGES_RESULTS][paymentInstrumentId],
            [challengeId]: result,
          },
        },
      };
    }
    case ACTION_TYPES.RESET_PAYMENT_INSTRUMENTS_CHALLENGES: {
      return {
        ...state,
        [STATE_KEYS.PAYMENT_INSTRUMENTS_CHALLENGES_RESULTS]: {},
      };
    }
    case ACTION_TYPES.UPDATE_HISTORY_LENGTH_BEFORE_NAVIGATION: {
      return {
        ...state,
        [STATE_KEYS.HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS]:
          payload[STATE_KEYS.HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS],
      };
    }
    case ACTION_TYPES.INVALIDATE_SESSION_CACHE: {
      return {
        ...state,
        [STATE_KEYS.MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED]: undefined,
        [STATE_KEYS.CHECKOUT_TYPE]: undefined,
        [STATE_KEYS.CHECKOUT_ID]: undefined,
        [STATE_KEYS.BASKET_ID]: undefined,
        [STATE_KEYS.PURCHASE_ID]: undefined,
        [STATE_KEYS.ON_EXPIRED_CHECKOUT_REDIRECT_TO]: undefined,
        [STATE_KEYS.HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS]: {},
      };
    }
    default:
      return state;
  }
};
