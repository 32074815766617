import React from 'react';
import messages from '@app/modules/vendors/pages/PreOrderTimes/messages';
import ShellFooter from '@components/ShellFooter';
import { useIntl } from 'react-intl';
import { SimpleButton } from '@appscore/web-components';
import { useIsDesktop } from '@hooks/layout/useIsDesktop';

const DeliveryDetailFooter = ({ onSubmit, disabled }) => {
  const { formatMessage } = useIntl();
  const isDesktop = useIsDesktop();

  return (
    <ShellFooter isDesktop={isDesktop}>
      <SimpleButton
        onClick={onSubmit}
        label={formatMessage(messages.footerText)}
        disabled={disabled}
        size="full"
        color="primary"
      />
    </ShellFooter>
  );
};

export default DeliveryDetailFooter;
