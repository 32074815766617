import { defineMessages } from 'react-intl';

export default defineMessages({
  headerTitle: {
    id: 'app.billing.header.title',
    defaultMessage: 'Datos de facturación',
  },
  footerText: {
    id: 'app.billing.footer.text',
    defaultMessage: 'Aceptar',
  },
  companyNameLabel: {
    id: 'app.billing.inputs.companyName.label',
    defaultMessage: 'Nombre / Razón Social',
  },
  companyNumberLabel: {
    id: 'app.billing.inputs.companyNumber.label',
    defaultMessage: 'CUIT / DNI',
  },
});
