import { useCallback } from 'react';
import { usePaymentInstrumentsChallengesResults } from './usePaymentInstrumentsChallengesResults';

export const useGetMissingChallengesForInstrument = () => {
  const results = usePaymentInstrumentsChallengesResults();

  const getMissingChallengesForInstrument = useCallback(
    (paymentInstrument) => {
      const completedChallenges = Object.keys(results[paymentInstrument?.instrument_id] || {});

      // filter challenges that are already completed
      return paymentInstrument?.challenges_required.filter((rch) => !completedChallenges.includes(rch));
    },
    [results],
  );

  return getMissingChallengesForInstrument;
};
