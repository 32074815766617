import { useAppTracker } from '@app/contexts/mainContext/selectors/useAppTracker';
import { useEffect, useMemo } from 'react';

export const useComponentTracking = ({ commonTrackingData, onMountEvent, actionEvents = [] }) => {
  const { tracker } = useAppTracker();

  useEffect(() => {
    if (commonTrackingData && onMountEvent) {
      tracker.track(onMountEvent, commonTrackingData);
    }
  }, [tracker, commonTrackingData, onMountEvent]);

  const actionTrackers = useMemo(() => {
    const trackEvent =
      (event) =>
      (additionalTrackingData = {}) => {
        tracker.track(event, { ...commonTrackingData, ...additionalTrackingData });
      };

    return actionEvents.map((eventName) => trackEvent(eventName));
  }, [actionEvents, tracker, commonTrackingData]);

  return actionTrackers;
};
