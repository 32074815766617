import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@components/Spinner';
import Skeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import styled from 'styled-components';
import { GlobalStyle } from '../GlobalStyle';

export const StyledBox = styled.div`
  display: flex;
  align-items: ${({ align }) => align || 'initial'};
  flex-direction: ${({ direction }) => direction || 'row'};
  padding: ${({ theme, padding }) => theme.space(padding || 'space-0')};
  justify-content: ${({ justifyContent }) => justifyContent || 'left'};
  gap: ${({ theme, gap }) => (gap ? theme.space(gap) : 'initial')};
  height: ${({ theme, height }) => (height ? theme.space(height) : 'auto')};
  margin-top: ${({ theme, marginTop }) => theme.space(marginTop || 'space-0')};
  margin-bottom: ${({ theme, marginBottom }) => theme.space(marginBottom || 'space-0')};
  flex: ${({ theme, flex }) => (flex ? theme.space(flex) : 'initial')};
`;

export const StyledBottomNavigation = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.space('space-08')};
  box-shadow: ${({ theme }) => theme.boxShadow('shadow-low-up')};
  background-color: white;
`;

const CustomTipLoading = ({ isDesktop }) => {
  if (isDesktop) return <Spinner />;

  return (
    <div aria-label="Cargando Formulario de Agregar Propina">
      <GlobalStyle />
      <StyledBox padding="space-08" direction="column" gap="space-10">
        <Skeleton shape="square" height="shape-size-action-7" width="shape-size-action-7" />
        <Skeleton shape="square" height="shape-size-action-7" width="shape-size-action-60" />
      </StyledBox>

      <StyledBox padding="space-08" direction="column">
        <Skeleton shape="square" outlined height="shape-size-action-12" width="">
          <StyledBox align="center" gap="space-14" height="space-11" padding="space-06">
            <Skeleton shape="square" height="shape-size-action-5" width="shape-size-action-4" />
            <Skeleton shape="square" height="shape-size-action-5" width="shape-size-action-16" />
          </StyledBox>
        </Skeleton>
        <StyledBox padding="space-06" direction="column" gap="space-03">
          <Skeleton shape="square" height="shape-size-action-3" width="shape-size-action-46" />
        </StyledBox>
      </StyledBox>

      <StyledBottomNavigation>
        <Skeleton shape="square" height="shape-size-action-12" width="" />
      </StyledBottomNavigation>
    </div>
  );
};

CustomTipLoading.propTypes = {
  isDesktop: PropTypes.bool,
};

CustomTipLoading.defaultProps = {
  isDesktop: false,
};

export default CustomTipLoading;
