import ResponsiveModal from '@app/components/ResponsiveModal';
import { useCheckoutTypeUpdate } from '@app/contexts/mainContext/hooks/useCheckoutTypeUpdate';
import { useExpiredCheckoutUrlUpdate } from '@app/contexts/mainContext/hooks/useExpiredCheckoutUrlUpdate';
import { useIsCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import Billing from '@app/modules/billing/pages/Billing';
import AddressForm from '@app/modules/checkout/pages/AddressForm';
import ChallengesFlow from '@app/modules/checkout/pages/ChallengesFlow';
import ChallengesFlowCallback from '@app/modules/checkout/pages/ChallengesFlowCallback';
import ChallengesFlowSuccess from '@app/modules/checkout/pages/ChallengesFlowSuccess';
import CheckoutSummary from '@app/modules/checkout/pages/CheckoutSummary';
import Confirmation from '@app/modules/checkout/pages/Confirmation';
import DeliveryAddress from '@app/modules/checkout/pages/DeliveryAddress';
import DeliveryAddressSelectionSuccess from '@app/modules/checkout/pages/DeliveryAddressSelectionSuccess';
import FenixCheckoutSummary from '@app/modules/checkout/pages/FenixCheckoutSummary';
import PaymentInstrument from '@app/modules/checkout/pages/PaymentInstrument';
import PaymentInstrumentSelectionSuccess from '@app/modules/checkout/pages/PaymentInstrumentSelectionSuccess';
import TermsAndConditionsDetail from '@app/modules/checkout/pages/TermsAndConditionsDetail';
import SecurityCodeValidation from '@app/modules/paymentMethod/pages/SecurityCodeValidation';
import { STATUS_CODE } from '@app/modules/shared/constants';
import Error from '@app/modules/shared/containers/Error';
import PreOrderTimes from '@app/modules/vendors/pages/PreOrderTimes';
import { CHECKOUT_TYPES } from '@commons/constants';
import { ROUTES } from '@commons/services/routes.service';
import { useIsDesktop } from '@hooks/layout/useIsDesktop';
import React from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import DeliveryInfo from '@app/modules/checkout/pages/DeliveryInfo';
import CustomTipForm from '@app/modules/checkout/pages/CustomTipForm';

const ModalOptionRoute = ({ canUseIFrameStyles, showModal }) =>
  showModal ? (
    <ResponsiveModal canUseIFrameStyles={canUseIFrameStyles}>
      <Outlet />
    </ResponsiveModal>
  ) : (
    <Outlet />
  );

function CheckoutRouter() {
  useExpiredCheckoutUrlUpdate();
  useCheckoutTypeUpdate();
  const isCheckoutType = useIsCheckoutType();
  const isDesktop = useIsDesktop();
  const location = useLocation();
  /**
   * The background value is an object of type 'location' used to implement the Modal Gallery technique
   * https://v5.reactrouter.com/web/example/modal-gallery
   */
  const backgroundLocation = location.state?.background;
  const showModal = Boolean(backgroundLocation && isDesktop);

  return (
    <>
      <Routes location={showModal ? backgroundLocation : location}>
        <Route
          path={ROUTES.LANDING}
          element={isCheckoutType(CHECKOUT_TYPES.CART_LEGACY) ? <CheckoutSummary /> : <FenixCheckoutSummary />}
        />
        <Route path={ROUTES.PAYMENT_INSTRUMENT_SELECTION_SUCCESS} element={<PaymentInstrumentSelectionSuccess />} />
        <Route path={ROUTES.CHALLENGES_FLOW_CALLBACK} element={<ChallengesFlowCallback />} />
        <Route path={ROUTES.CHALLENGES_FLOW_SUCCESS} element={<ChallengesFlowSuccess />} />
        <Route path={ROUTES.CONFIRMATION} element={<Confirmation />} />
        <Route path={ROUTES.DELIVERY_ADDRESS_SELECTION_SUCCESS} element={<DeliveryAddressSelectionSuccess />} />
        <Route element={<Error errorCode={STATUS_CODE.NOT_FOUND} />} />
      </Routes>
      <Routes>
        <Route path="/" element={<ModalOptionRoute showModal={showModal} canUseIFrameStyles />}>
          <Route path={ROUTES.BILLING_FORM} element={<Billing />} />
          <Route path={ROUTES.ORDER_TIMES} element={<PreOrderTimes />} />
          <Route path={ROUTES.PAYMENT_INSTRUMENT} element={<PaymentInstrument />} />
          <Route path={ROUTES.DELIVERY_ADDRESS} element={<DeliveryAddress />} />
          <Route path={ROUTES.CHALLENGES_FLOW} element={<ChallengesFlow />} />
          <Route path={ROUTES.CARD_SECURITY_CODE} element={<SecurityCodeValidation />} />
          <Route path={ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditionsDetail />} />
        </Route>
        <Route path="/" element={<ModalOptionRoute showModal={showModal} />}>
          <Route path={ROUTES.DELIVERY_INFO} element={<DeliveryInfo />} />
          <Route path={ROUTES.ADDRESS_FORM} element={<AddressForm />} />
          <Route path={ROUTES.CUSTOM_TIP_FORM} element={<CustomTipForm />} />
        </Route>
      </Routes>
    </>
  );
}

export default CheckoutRouter;
