import { addDataToScope } from '@app/logger/sentry/addDataToScope';
import { QUERY_PARAM } from '@commons/constants';
import { getQueryParams } from '@utils/domRouter';

export const useCheckoutParams = () => {
  const basketId = getQueryParams(window?.location, QUERY_PARAM.CHECKOUT.BASKET_ID);
  const checkoutType = getQueryParams(window?.location, QUERY_PARAM.CHECKOUT.TYPE);

  const sentryData = [
    { key: 'basketId', value: basketId },
    { key: 'checkoutType', value: checkoutType },
  ];
  addDataToScope(sentryData);

  return { basketId, checkoutType };
};
