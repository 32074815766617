import { useBFFNavigate } from '@checkout-ui/backend-driven';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigateToAddressForm } from '../navigation/useNavigateToAddressForm';
import { useNavigateToDeliveryAddress } from '../navigation/useNavigateToDeliveryAddress';
import { useNavigateToUserPaymentInstruments } from '../navigation/useNavigateToUserPaymentInstruments';
import { useGoBackToCheckoutSummary } from '@app/hooks/navigation/useGoBackToCheckoutSummary';

export const EXTERNAL_ROUTES = {
  SELECT_INSTRUMENTS: 'SELECT_INSTRUMENTS',
  DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
  ADDRESS_FORM: 'ADDRESS_FORM',
};

export const useNavigateActionDefinition = () => {
  const { navigate } = useBFFNavigate();
  const location = useLocation();
  const { navigateToAddressForm } = useNavigateToAddressForm();
  const { navigateToDeliveryAddress } = useNavigateToDeliveryAddress();
  const { navigateToUserPaymentInstruments } = useNavigateToUserPaymentInstruments();

  const routeNavigationDefinitions = useMemo(
    () => ({
      /**
       * @param {object} queryParams
       * @param {string} queryParams.purchaseId
       * @param {string} queryParams.instrumentsSelected Payment Instruments IDs separated by commas
       */
      [EXTERNAL_ROUTES.SELECT_INSTRUMENTS]: (queryParams) => {
        navigateToUserPaymentInstruments(queryParams?.purchaseId, {
          instrumentIds: queryParams?.instrumentsSelected,
        });
      },
      /**
       * @param {object} queryParams
       * @param {string} queryParams.cartGUID
       * @param {string} queryParams.addressSelected AddressId
       */
      [EXTERNAL_ROUTES.DELIVERY_ADDRESS]: (queryParams) => {
        const { cartGUID, addressSelected } = queryParams;
        navigateToDeliveryAddress(cartGUID, { addressSelected });
      },
      /**
       * @param {object} queryParams Contains Address Data
       */
      [EXTERNAL_ROUTES.ADDRESS_FORM]: (queryParams) => {
        const addressData = { ...queryParams };
        navigateToAddressForm(addressData);
      },
    }),
    [navigateToAddressForm, navigateToDeliveryAddress, navigateToUserPaymentInstruments],
  );

  const navigateActionDefinition = (payload) => {
    const navigationAction = routeNavigationDefinitions[payload?.native_page?.name];
    if (navigationAction) {
      navigationAction(payload?.native_page?.query_params);
    } else {
      navigate(payload, { state: { background: location } });
    }
  };

  return { navigateActionDefinition };
};
