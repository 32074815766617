import { useCheckoutId } from '@app/contexts/mainContext/selectors/useCheckoutId';
import { useIsCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import { usePurchaseId } from '@app/contexts/mainContext/selectors/usePurchaseId';
import { useExternalRouterNavigation } from '@checkout-ui/backend-driven';
import { CHECKOUT_TYPES, QUERY_PARAM } from '@commons/constants';
import { CHALLENGES_CODES } from '@commons/constants/challengesCodes';
import {
  createPaymentMethodsMicrositePath,
  EXTERNAL_URIS_FRONT,
  ROUTES,
  URIS_FRONT,
} from '@commons/services/routes.service';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateToChallenge = () => {
  const { externalRouterNavigation } = useExternalRouterNavigation();
  const checkoutId = useCheckoutId();
  const purchaseId = usePurchaseId();
  const navigate = useNavigate();
  const location = useLocation();
  const isCheckoutType = useIsCheckoutType();

  const getUrlByChallengeType = useCallback(
    (challengeType, payload) => {
      const [resourceId, successCallbackPath] = isCheckoutType(CHECKOUT_TYPES.CART_LEGACY)
        ? [purchaseId, URIS_FRONT.CHALLENGES_FLOW_CALLBACK]
        : [checkoutId, URIS_FRONT.CHALLENGES_FLOW_SUCCESS];

      switch (challengeType) {
        case CHALLENGES_CODES.CARD_SECURITY_CODE:
          return createPaymentMethodsMicrositePath(
            EXTERNAL_URIS_FRONT.PAYMENT_METHODS_SECURITY_CODE,
            resourceId,
            successCallbackPath,
            {
              [QUERY_PARAM.INSTRUMENT_ID]: payload.paymentInstrumentId,
            },
          );
        default:
          return createPaymentMethodsMicrositePath(
            EXTERNAL_URIS_FRONT.PAYMENT_METHODS_CHALLENGES,
            resourceId,
            successCallbackPath,
          );
      }
    },
    [checkoutId, isCheckoutType, purchaseId],
  );

  const navigateToChallenge = useCallback(
    (challengeType, payload) => {
      const url = getUrlByChallengeType(challengeType, payload);
      // In a future all flow goes to PAYMENT_INSTRUMENT screen
      if (!isCheckoutType(CHECKOUT_TYPES.CART_LEGACY)) externalRouterNavigation(url);
      else navigate(ROUTES.CHALLENGES_FLOW, { state: { url, background: location } });
    },
    [externalRouterNavigation, getUrlByChallengeType, isCheckoutType, location, navigate],
  );

  return navigateToChallenge;
};
