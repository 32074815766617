import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGoBackToCheckoutSummary } from '@hooks/navigation/useGoBackToCheckoutSummary';
import { useInvalidateQueriesAndRefetchInactive } from '@app/contexts/reactQuery/hooks/useInvalidateQueriesAndRefetchInactive';
import { BrandError } from '@app/components/Error';
import { QUERIES_NAMES } from '../../queries';
import { getParcelPropsByScreen } from './utils/getParcelPropsByScreen';
import { useNavigateBack } from '@app/hooks/navigation/useNavigateBack';
import { useIsDesktop } from '@app/hooks/layout/useIsDesktop';
import { getFooterNavigationStyle } from './utils/getFooterNavigationStyle';
import DeliveryInfoParcel from './components/DeliveryInfoParcel';
import Header from './components/Header';
import logger from '@app/logger';

function DeliveryInfo() {
  const { goBackToCheckoutSummary } = useGoBackToCheckoutSummary('DELIVERY_INFO');
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();
  const { checkoutId, screenName } = useParams();
  const [searchParams] = useSearchParams();
  const flow = searchParams.get('flow');
  const { navigateBack } = useNavigateBack();
  const [error, setError] = useState(false);
  const isDesktop = useIsDesktop();
  const footerNavigationStyle = getFooterNavigationStyle(isDesktop);

  const handleSave = () => {
    logger.debug(`[DeliveryInfo][SAVE_${screenName.toUpperCase()}_SUCCESS]`);
    invalidateQueriesAndRefetchInactive([QUERIES_NAMES.CHECKOUT]);
    goBackToCheckoutSummary();
  };

  const handleSaveError = (error) => {
    logger.error(`[DeliveryInfo][HANDLE_SAVE_${screenName.toUpperCase()}_ERROR]`, `error: ${error.message}`);
    setError(true);
  };

  const handleLoadError = (error) => {
    logger.error('[DeliveryInfo][HANDLE_LOAD_ERROR]', `error: ${error.message}`);
    setError(true);
  };

  const handleClose = () => {
    goBackToCheckoutSummary();
  };

  const parcelPropsByScreen = getParcelPropsByScreen(screenName, {
    onSave: handleSave,
    onLoadError: handleLoadError,
    onSaveError: handleSaveError,
  });

  if (error || !parcelPropsByScreen) return <BrandError onClick={handleClose} />;

  return (
    <div role="dialog" aria-label={screenName}>
      <DeliveryInfoParcel
        checkoutId={checkoutId}
        flow={flow}
        footerNavigationStyle={footerNavigationStyle}
        header={<Header onBack={navigateBack} />}
        {...parcelPropsByScreen}
      />
    </div>
  );
}

export default DeliveryInfo;
