import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import messages from './messages';

function NoFollow() {
  return (
    <>
      <meta name="robots" content="noindex, nofollow" />
      <meta name="googlebot" content="noindex" />
    </>
  );
}

function BasicHelmet({ noFollow }) {
  const { formatMessage } = useIntl();
  const title = formatMessage(messages.metaTitle);
  const metaContent = formatMessage(messages.metaDescription);

  const helmetTitle = title;
  const helmetMeta = [
    {
      name: 'description',
      content: metaContent,
    },
  ];

  return (
    <>
      <Helmet title={helmetTitle} meta={helmetMeta} />
      {noFollow && <NoFollow />}
    </>
  );
}

BasicHelmet.defaultProps = {
  noFollow: true,
};

BasicHelmet.propTypes = {
  noFollow: PropTypes.bool,
};

export default BasicHelmet;
