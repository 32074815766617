import { ACTION_TYPES } from '../actionTypes';
import { STATE_KEYS } from '../constants';

export const updateCheckoutConfirmationStatus = (dispatch, { isConfirming, error = {} }) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CHECKOUT_CONFIRMATION_STATUS,
    payload: {
      [STATE_KEYS.CHECKOUT_CONFIRMATION_STATUS]: {
        isConfirming,
        error,
      },
    },
  });
};
