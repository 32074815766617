import { Spinner } from '@appscore/web-components';
import { useHeightViewport } from '@hooks/layout/useHeightViewport';
import React, { useState } from 'react';
import styled from 'styled-components';

const IframeContainer = styled.div`
  height: ${({ heightViewport }) => (heightViewport ? `${heightViewport}px` : '100%')};
`;

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: unset;
  display: block;
`;

const IFrameWithSpinner = ({ isDesktop, ...props }) => {
  const [iframeLoading, setIframeLoading] = useState(true);
  const { heightViewport } = useHeightViewport({ isDesktop });

  return (
    <IframeContainer heightViewport={heightViewport}>
      <IFrame {...props} onLoadCapture={() => setIframeLoading(false)} autoFocus />
      {iframeLoading ? <Spinner /> : null}
    </IframeContainer>
  );
};

export default IFrameWithSpinner;
