/*
 * Confirm Messages
 *
 * This contains all the text for the Confirm component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationSuccessTitle: {
    id: 'app.components.CheckoutSummary.confirmationSuccessTitle',
    defaultMessage: '¡Gracias por tu pedido!',
  },
  confirmationSuccessSubtitle: {
    id: 'app.components.CheckoutSummary.confirmationSuccessSubtitle',
    defaultMessage: 'En unos segundos, verás el estado en tiempo real',
  },
  errorDefaultTitle: {
    id: 'app.components.CheckoutSummary.errorDefaultTitle',
    defaultMessage: 'No pudimos procesar tu pago',
  },
  errorDefaultButtonLabel: {
    id: 'app.components.CheckoutSummary.errorDefaultButtonLabel',
    defaultMessage: 'Cerrar',
  },
});
