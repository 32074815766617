import { getMinutesToMilliseconds } from '@app/utils/date';

export const CACHE_KEYS = {
  HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS: 'HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS',
  ON_EXPIRED_CHECKOUT_REDIRECT_TO: 'MICROSITE_CHECKOUT_ON_EXPIRED_CHECKOUT_REDIRECT_TO',
  CHECKOUT_SESSION: 'MICROSITE_CHECKOUT_SESSION',
  CHECKOUT_TYPE: 'MICROSITE_CHECKOUT_TYPE',
  MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED: 'MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED',
};

export const TTLS = {
  WITHOUT: -1,
  // 20 mins
  CHECKOUT_SESSION_TTL: getMinutesToMilliseconds(20),
};
