import { BrandError } from '@app/components/Error';
import Spinner from '@app/components/Spinner';
import { useGoBackToCheckoutSummary } from '@hooks/navigation/useGoBackToCheckoutSummary';
import { useInvalidateQueriesAndRefetchInactive } from '@app/contexts/reactQuery/hooks/useInvalidateQueriesAndRefetchInactive';
import { useIsDesktop } from '@hooks/layout/useIsDesktop';
import logger from '@app/logger';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdateAddress } from '../../mutations';
import { QUERIES_NAMES } from '../../queries';
import AddressFormParcel from './components/AddressFormParcel';

const Container = styled.div`
  height: ${({ isDesktop }) => (isDesktop ? '100%' : '100vh')};
`;

/**
 * This page only works in Web Desktop / Mobile contexts
 */
function AddressForm() {
  const { goBackToCheckoutSummary } = useGoBackToCheckoutSummary('ADDRESS_FORM_FLOW');
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();
  const { state } = useLocation();
  const isDesktop = useIsDesktop();
  const addressData = state?.addressData;
  const [address, setAddress] = useState(addressData);
  const { mutate: updateAddress, isLoading: isUpdatingAddress, isError: isUpdateAddressError } = useUpdateAddress();

  const handleSuccess = (newAddress) => {
    setAddress(newAddress);
    updateAddress(newAddress, {
      onSuccess: () => {
        logger.debug('[AddressForm][UPDATE_ADDRESS_SUCCESS]');
        invalidateQueriesAndRefetchInactive([QUERIES_NAMES.CHECKOUT]);
        goBackToCheckoutSummary();
        return;
      },
      onError: (error) => logger.error('[AddressForm][UPDATE_ADDRESS_ERROR]', `error: ${error.message}`),
    });
  };

  const handleClose = () => {
    goBackToCheckoutSummary();
  };

  if (isUpdatingAddress) return <Spinner />;
  if (isUpdateAddressError) return <BrandError onClick={handleSuccess(address)} />;

  return (
    <Container isDesktop={isDesktop}>
      <AddressFormParcel data={addressData} onSuccess={handleSuccess} onClose={handleClose} />
    </Container>
  );
}

export default AddressForm;
