import { ACTION_TYPES } from '../actionTypes';
import { STATE_KEYS } from '../constants';

export const updateCheckoutBackendActionStatus = (dispatch, { isPerformingAction, error, success }) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CHECKOUT_BACKEND_ACTION_STATUS,
    payload: {
      [STATE_KEYS.CHECKOUT_BACKEND_ACTION_STATUS]: {
        isPerformingAction,
        error,
        success,
      },
    },
  });
};
