import React, { useEffect, useState } from 'react';
import Snackbar from '@pedidosya/web-fenix/molecules/Snackbar';

const Notification = ({ show = false, type = 'error', isDesktop = false }) => {
  const [isActive, setIsActive] = useState(show);

  useEffect(() => {
    setIsActive(show);
  }, [show]);

  return (
    <Snackbar
      label="No pudimos agregar la propina"
      onClose={() => setIsActive(false)}
      show={isActive}
      type={type}
      position={isDesktop ? 'top-right' : 'bottom-center'}
      style={{ marginBottom: isDesktop ? 0 : 80 }}
    />
  );
};

export default Notification;
