import logger from '@app/logger';
import { ACTION_TYPES } from '../actionTypes';

export const addPaymentInstrumentChallengeResult = (dispatch, paymentInstrumentId, challengeId, result) => {
  if (!paymentInstrumentId || !challengeId || !result) {
    logger.error(
      '[mainContext][paymentInstrumentsChallenges]',
      'missing required params:',
      paymentInstrumentId,
      challengeId,
      result,
    );
    return;
  }

  dispatch({
    type: ACTION_TYPES.ADD_PAYMENT_INSTRUMENT_CHALLENGE_RESULT,
    payload: {
      paymentInstrumentId,
      challengeId,
      result,
    },
  });
};
