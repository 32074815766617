import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Modal from '@pedidosya/web-fenix/organisms/Modal';
import React from 'react';
import { useMessageModalContext } from '..';

const GlobalModal = () => {
  const { globalModalProps, setGlobalModalProps } = useMessageModalContext();
  const {
    open,
    actionDefinitions,
    title,
    message,
    primary_button: primaryButton,
    secondary_button: secondaryButton,
    onCancel,
  } = globalModalProps;
  const { executeRecursiveActions } = useBFFActionsExecutor(actionDefinitions);

  const onButtonClick = (actions) => {
    setGlobalModalProps({ open: false });
    executeRecursiveActions(actions);
  };

  return (
    <Modal
      open={open}
      title={title?.texts?.reduce((acc, curr) => (acc ? `${acc} ${curr.value}` : curr.value), '') || ''}
      text={message?.texts?.reduce((acc, curr) => (acc ? `${acc} ${curr.value}` : curr.value), '') || ''}
      primaryButton={
        <Button
          fullWidth
          label={primaryButton?.title || ''}
          aria-label={primaryButton?.title || ''}
          size="large"
          onClick={() => onButtonClick(primaryButton?.actions)}
        />
      }
      secondaryButton={
        secondaryButton ? (
          <Button
            fullWidth
            label={secondaryButton.title}
            aria-label={secondaryButton.title}
            size="large"
            onClick={() => onButtonClick(secondaryButton.actions)}
            hierarchy="secondary"
          />
        ) : null
      }
      onClose={() => onButtonClick(onCancel)}
    />
  );
};

export default GlobalModal;
