const validationRules = {
  isRequired: (value, isRequired) => (isRequired ? Boolean(value) : true),
  minValue: (value, min) => !(value < min),
  maxValue: (value, max) => !(value > max),
  minLength: (value, minLength) => !(value.length < minLength),
  maxLength: (value, maxLength) => !(value.length > maxLength),
};

export function isValidInput(inputValue, inputRules = {}) {
  const isValid = Object.entries(inputRules).every(([ruleKey, ruleValue]) => {
    const validationRule = validationRules[ruleKey];
    if (!validationRule) return true;

    const isRequiredRule = ruleKey === 'isRequired';
    if (!isRequiredRule && !inputValue) return true;

    return validationRule(inputValue, ruleValue);
  });
  return isValid;
}
