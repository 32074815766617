import { SCREEN_NAMES } from './constans';

export function getParcelPropsByScreen(screen, callbacks) {
  switch (screen) {
    case SCREEN_NAMES.ADDRESS:
      return {
        loadChangeAddress: true,
        changeAddressCallbacks: callbacks,
      };
    case SCREEN_NAMES.NOTES:
      return {
        loadNotes: true,
        notesCallbacks: callbacks,
      };
    case SCREEN_NAMES.DELIVERY_TYPE:
      return {
        loadDeliveryType: true,
        deliveryTypeCallbacks: callbacks,
      };
    case SCREEN_NAMES.DELIVERY_PHONE:
      return {
        loadDeliveryPhone: true,
        deliveryPhoneCallbacks: callbacks,
      };
    default:
      return;
  }
}
