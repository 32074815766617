import { invalidateSessionCache } from '@app/contexts/mainContext/actions/invalidateSessionCache';
import { resetPaymentInstrumentsChallenges } from '@app/contexts/mainContext/actions/resetPaymentInstrumentsChallenges';
import { updateCheckoutConfirmationStatus } from '@app/contexts/mainContext/actions/updateCheckoutConfirmationStatus';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import logger from '@app/logger';
import { addQueryParamsToUrlString } from '@app/utils/queryString';
import { useExternalRouterNavigation } from '@checkout-ui/backend-driven';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BFF_COMPONENTS_TYPES } from '../constants/bff';

export const useCheckoutBFFConfirmation = () => {
  const dispatch = useAppDispatch();
  const { externalRouterNavigation } = useExternalRouterNavigation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleCheckoutBFFConfirmationSuccess = useCallback(
    (response, { trackTransactionSuccess, isInternal, url, queryParams = {} }) => {
      queryClient.invalidateQueries({ refetchActive: false });
      trackTransactionSuccess(response.trackingData);
      invalidateSessionCache(dispatch);
      if (isInternal) {
        const newUrl = addQueryParamsToUrlString(url, {
          ...queryParams,
          redesign: response.checkoutRedesignHeader,
        });
        navigate(newUrl);
      } else {
        externalRouterNavigation(url, { queryParams });
      }
    },
    [dispatch, externalRouterNavigation, navigate, queryClient],
  );

  const handleCheckoutBFFConfirmation = useCallback(
    (response, { errorCallback, secondaryErrorCallback, trackTransactionFailed, trackTransactionSuccess }) => {
      // TODO: support challenge errors (CVV_CHALLENGE, BNPL_REQUIRED)
      if (response.result === 'ERROR' && response.action?.render?.type === 'DIALOG') {
        const confirmCheckoutComponents = response.action?.render?.components || [];
        const errorDialog = confirmCheckoutComponents.find((c) => c.type === BFF_COMPONENTS_TYPES.ERROR_DIALOG);
        trackTransactionFailed();
        updateCheckoutConfirmationStatus(dispatch, {
          isConfirming: false,
          error: {
            isError: true,
            data: {
              title: errorDialog?.title,
              description: errorDialog?.description,
              icon: errorDialog?.icon,
              firstOptionLabel: errorDialog?.call_to_action,
              onFirstOptionAction: errorCallback,
              secondaryOptionLabel: errorDialog?.secondary_call_to_action,
              onSecondaryOptionAction: secondaryErrorCallback,
            },
          },
        });
        resetPaymentInstrumentsChallenges(dispatch);
        return;
      }

      if (response.result === 'SUCCESS') {
        const { internal: isInternal, redirect_url: url, query_params: queryParams } = response?.action || {};
        handleCheckoutBFFConfirmationSuccess(response, { trackTransactionSuccess, isInternal, url, queryParams });
        return;
      }

      logger.error(
        '[useConfirmCheckout][CONFIRM_CHECKOUT_ERR]',
        'error confirming checkout, unknown response result:',
        response.result,
      );
      updateCheckoutConfirmationStatus(dispatch, { isConfirming: false, error: { data: {}, isError: true } });
    },
    [dispatch, handleCheckoutBFFConfirmationSuccess],
  );

  return { handleCheckoutBFFConfirmation };
};
