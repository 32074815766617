import { Card } from '@appscore/web-components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SummarySectionTitle from '../../SummarySectionTitle';

const Container = styled.div`
  padding: ${({ theme }) => `${theme.spacing(10)} 0 ${theme.spacing(4)}`};
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

function CheckoutPaymentMethodContainer({ header, onClick, children }) {
  return (
    <Container>
      <SummarySectionTitle>{header}</SummarySectionTitle>
      <Card direction="column">
        <PaymentMethodContainer onClick={onClick}>{children}</PaymentMethodContainer>
      </Card>
    </Container>
  );
}

CheckoutPaymentMethodContainer.propTypes = {
  header: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
};

export default CheckoutPaymentMethodContainer;
