import { useCountry } from '@app/contexts/mainContext/selectors/useCountry';
import messages from '@app/modules/checkout/pages/FenixCheckoutSummary/messages';
import { addQueryParamsToUrlString } from '@app/utils/queryString';
import { REDIRECT_PAGES } from '@app/utils/redirection';
import { ROUTES } from '@commons/services/routes.service';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export const useDingDongActionDefinition = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const country = useCountry();

  const dingDongActionDefinition = ({ order_id: orderId }) => {
    const url = addQueryParamsToUrlString(ROUTES.CONFIRMATION, {
      redirectPage: REDIRECT_PAGES.ORDER_STATUS,
      title: formatMessage(messages.confirmationSuccessTitle),
      subtitle: formatMessage(messages.confirmationSuccessSubtitle),
      orderId,
      country: country.shortName,
    });
    navigate(url);
  };

  return { dingDongActionDefinition };
};
