import logger from '@app/logger';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

import getAxiosInstance from '../axios';
import { URLS } from './constants';

async function validateHTML(url, axios = getAxiosInstance()) {
  try {
    if (!url) {
      throw new Error(`invalid URL for HTML fetching: ${url}`);
    }
    const { data } = await axios.get(URLS.VALIDATE_HTML, {
      params: {
        url,
      },
    });

    return data;
  } catch (error) {
    logger.error('[services][validateHTML]', parseAxiosError(error).message);
    throw error;
  }
}

export default validateHTML;
