import pkg from '../../package.json';

export const QUERY_PARAM = {
  ORIGIN: 'origin',
  CHECKOUT: {
    BASKET_ID: 'basketId',
    TYPE: 'type',
    ON_EXPIRED_REDIRECT_TO: 'onExpiredCheckoutRedirectTo',
  },
  CHECKOUT_ID: 'checkoutId',
  PURCHASE_ID: 'purchaseId',
  CALLBACKS: {
    SUCCESS: 'successCallback',
  },
  SELECTED_INSTRUMENTS: 'selectedInstruments',
  INSTRUMENT_ID: 'instrumentId',
  BILLING: {
    COMPANY_NAME: 'companyName',
    COMPANY_NUMBER: 'companyNumber',
  },
  VENDOR_ID: 'vendorId',
  ORDER_ADDRESS: {
    CALLBACK: 'callback',
  },
  DISMISS: 'dismiss',
  ENTRY_POINT: 'entry_point',
};

export const API_BASE_URI = `/api/${pkg.name}`;

export const ERROR_CODES = {
  SESSION_EXPIRED: 'CHECKOUT_SESSION_EXPIRED',
};

export const CHECKOUT_TYPES = {
  LOYALTY_SUBSCRIPTION: 'LOYALTY_SUBSCRIPTION',
  BNPL_DEBT: 'BNPL_DEBT',
  CART_LEGACY: 'CART_LEGACY',
  DONATION: 'DONATION',
};
