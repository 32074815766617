import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@appscore/web-fenix/icons/Close';

const StlyedHeader = styled.header`
  padding: 16px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
`;

const Header = ({ onBack }) => {
  const handleBack = () => {
    onBack();
  };
  return (
    <StlyedHeader aria-label="dialog-header">
      <CloseIcon size="medium" onClick={handleBack} />
    </StlyedHeader>
  );
};

Header.propTypes = {
  onBack: PropTypes.func,
};

export default Header;
