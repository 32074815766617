import { useDeviceInfo } from '@app/contexts/mainContext/selectors/useDeviceInfo';
import logger from '@app/logger';
import { addQueryParamsToUrlString } from '@app/utils/queryString';
import { ORDER_STATUS_URL, REDIRECT_PAGES } from '@app/utils/redirection';
import { getHomeLink } from '@utils/deeplinks';
import { useCallback, useMemo } from 'react';
import { useHardNavigate } from './useHardNavigate';

export const useRedirectToPage = () => {
  const deviceInfo = useDeviceInfo();
  const hardNavigate = useHardNavigate();

  const redirectPagesActions = useMemo(
    () => ({
      [REDIRECT_PAGES.HOME]: ({ replace }) => {
        hardNavigate(getHomeLink(deviceInfo), { replace });
      },
      [REDIRECT_PAGES.ORDER_STATUS]: ({ replace, orderId, country }) => {
        const url = addQueryParamsToUrlString(ORDER_STATUS_URL, {
          orderId,
          origin: 'order_confirmation',
          c: `.${country}`,
        });
        hardNavigate(url, { replace });
      },
    }),
    [deviceInfo, hardNavigate],
  );

  const redirectToPage = useCallback(
    (page, options = {}) => {
      const pageAction = redirectPagesActions[page];
      if (pageAction) {
        pageAction(options);
      } else {
        logger.error('[useRedirectToPage][INVALID_PAGE]', 'Invalid Page:', page);
      }
    },
    [redirectPagesActions],
  );

  return { redirectToPage };
};
