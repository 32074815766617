import React from 'react';
import PropTypes from 'prop-types';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { isLocal } from '@utils/environment';
import { initializeReactQueryClient } from './client';

const client = initializeReactQueryClient();

function ReactQueryProvider({ children }) {
  return (
    <QueryClientProvider client={client}>
      <>
        {children}
        {isLocal() ? <ReactQueryDevtools /> : null}
      </>
    </QueryClientProvider>
  );
}

ReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReactQueryProvider;
