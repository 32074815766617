import { CACHE_KEYS, sessionCache } from '@app/services/cache';
import { ACTION_TYPES } from '../actionTypes';
import { STATE_KEYS } from '../constants';

export const updateTermsAndConditionsStatus = (dispatch, areTermsAccepted) => {
  sessionCache.set(CACHE_KEYS.MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED, areTermsAccepted);
  dispatch({
    type: ACTION_TYPES.UPDATE_TERMS_AND_CONDITIONS_STATUS,
    payload: { [STATE_KEYS.MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED]: areTermsAccepted },
  });
};
