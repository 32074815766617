import { Image } from '@appscore/web-components';
import PedidosYaPagos from '@assets/images/pedidosya_pagos.svg';
import React from 'react';
import styled from 'styled-components';
import SummarySectionSubtitle from './SummarySectionSubtitle';
import SummarySectionTitle from './SummarySectionTitle';

const Container = styled.section`
  margin: ${({ theme }) => `${theme.spacing(8)} 0`};
`;

const ComponentContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing(8)} 0 0`};
`;

const StyledSubtitle = styled(SummarySectionSubtitle)`
  margin: ${({ theme, margin }) => margin || `${theme.space('spacing-layout-xsmall')} 0 0`};
`;

const IMAGES = {
  'ic-pedidos-ya-pagos': PedidosYaPagos,
};

const SummarySectionBFF = ({ titleImage, title, subtitle, ComponentBFF, componentProps }) => {
  return (
    <Container>
      <SummarySectionTitle>
        {titleImage ? <Image src={IMAGES[titleImage.key]} alt={title} /> : title}
      </SummarySectionTitle>
      {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
      <ComponentContainer>
        <ComponentBFF {...componentProps} />
      </ComponentContainer>
    </Container>
  );
};

export default SummarySectionBFF;
