import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputText } from '@appscore/web-components';

function ValidatedInputText({ errorText, helpText, onChange, onInvalid, onValid, regexString, value, ...props }) {
  const [error, setError] = useState(false);
  const [alreadyTouched, setAlreadyTouched] = useState(false);
  const regex = useMemo(() => new RegExp(regexString), [regexString]);

  const isValid = v => regex.test(v);

  const handleChange = event => {
    const newValue = event?.target?.value;

    onChange(newValue);
    const valid = isValid(newValue);
    if (valid) {
      onValid();
      setError(false);
    } else {
      if (alreadyTouched) {
        setError(true);
      }
      onInvalid();
    }
  };

  const onBlur = () => {
    if (!alreadyTouched) setAlreadyTouched(true);
    setError(!isValid(value));
  };

  return (
    <InputText
      error={error}
      helpText={error ? errorText : helpText}
      onBlur={onBlur}
      onChange={handleChange}
      value={value}
      {...props}
    />
  );
}

ValidatedInputText.propTypes = {
  errorText: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  regexString: PropTypes.string,
  value: PropTypes.any,
};

export default ValidatedInputText;
