import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card, Image } from '@appscore/web-components';

const Resume = styled.div`
  margin-left: ${({ theme }) => theme.spacing(6)};
  line-height: ${({ theme }) => theme.spacing(12)};
`;

const Title = styled.h2`
  margin: 0;
  ${({ theme }) => theme.fontFamily.secondaryFont.smallBold}
  color: ${({ theme }) => theme.colors.night_blue_1};
`;

const Subtitle = styled.p`
  margin: 0;
  ${({ theme }) => theme.fontFamily.secondaryFont.xSmallRegular};
  color: ${({ theme }) => theme.colors.night_blue_2};
`;

const Logo = styled(Image)`
  border-radius: 4px;
`;

function CheckoutResume({ title, subtitle, icon }) {
  return (
    <Card direction="row">
      <Logo alt="resume-logo" src={icon} height={48} width={48} />
      <Resume>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Resume>
    </Card>
  );
}

CheckoutResume.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default CheckoutResume;
