import styled from 'styled-components';

export const StyledBox = styled.div`
  display: flex;
  align-items: ${({ align }) => align || 'initial'};
  flex-direction: ${({ direction }) => direction || 'row'};
  padding: ${({ theme, padding }) => theme.space(padding || 'space-0')};
  justify-content: ${({ justifyContent }) => justifyContent || 'left'};
  gap: ${({ theme, gap }) => (gap ? theme.space(gap) : 'initial')};
  height: ${({ theme, height }) => (height ? theme.space(height) : 'auto')};
  margin-top: ${({ theme, marginTop }) => theme.space(marginTop || 'space-0')};
  margin-bottom: ${({ theme, marginBottom }) => theme.space(marginBottom || 'space-0')};
  flex: ${({ theme, flex }) => (flex ? theme.space(flex) : 'initial')};
`;

export const StyledBottomNavigation = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.space('space-08')};
  box-shadow: ${({ theme }) => theme.boxShadow('shadow-low-up')};
  background-color: white;
`;
