import BottomSheetBody from '@components/BottomSheet/components/BottomSheetBody';
import BottomSheetContent from '@components/BottomSheet/components/BottomSheetContent';
import Handler from '@components/BottomSheet/components/Handler';
import Overlay from '@components/BottomSheet/components/Overlay';
import PropTypes from 'prop-types';
import React from 'react';

const BottomSheet = ({ children, open, showOverlay, showHandler, className }) => {
  return (
    <Overlay toggle={open} showOverlay={showOverlay} className={className}>
      <BottomSheetContent>
        {showHandler ? <Handler /> : null}
        <BottomSheetBody>{children}</BottomSheetBody>
      </BottomSheetContent>
    </Overlay>
  );
};

BottomSheet.defaultProps = {
  showOverlay: true,
  showHandler: false,
};

BottomSheet.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  showOverlay: PropTypes.bool,
  showHandler: PropTypes.bool,
  className: PropTypes.string,
};

export default BottomSheet;
