import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function confirmCheckout({ version, isRepeatOrder = false } = {}, axios = getAxiosInstance()) {
  try {
    const response = await axios.post(URLS.CONFIRM_CHECKOUT, {
      version,
      confirm_repeat_order: isRepeatOrder,
    });
    return response.data;
  } catch (error) {
    logger.error('[services][confirmCheckout]', parseAxiosError(error).message);
    throw error;
  }
}

export default confirmCheckout;
