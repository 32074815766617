import { defineMessages } from 'react-intl';

export default defineMessages({
  headerTitle: {
    id: 'app.orderTimes.header.title',
    defaultMessage: '¿Cuándo llega tu pedido?',
  },
  footerText: {
    id: 'app.orderTimes.footer.text',
    defaultMessage: 'Aceptar',
  },
  asSoonAsPossibleItem: {
    id: 'app.orderTimes.defaultOption.label',
    defaultMessage: 'Entrega inmediata ({minFrom} - {maxTo} minutos)',
  },
  orderTime: {
    id: 'app.orderTimes.option.label',
    defaultMessage: 'Entre {from} y {to}hs',
  },
});
