import { invalidateSessionCache } from '@app/contexts/mainContext/actions/invalidateSessionCache';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import messages from '@app/modules/checkout/pages/FenixCheckoutSummary/messages';
import { addQueryParamsToUrlString } from '@app/utils/queryString';
import { REDIRECT_PAGES } from '@app/utils/redirection';
import { ROUTES } from '@commons/services/routes.service';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const useCartLegacyConfirmation = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleCartLegacyConfirmation = useCallback(
    (response, { trackTransactionSuccess }) => {
      queryClient.invalidateQueries({ refetchActive: false });
      trackTransactionSuccess(response.trackingData);
      invalidateSessionCache(dispatch);
      const url = addQueryParamsToUrlString(ROUTES.CONFIRMATION, {
        redirectPage: REDIRECT_PAGES.ORDER_STATUS,
        title: formatMessage(messages.confirmationSuccessTitle),
        subtitle: formatMessage(messages.confirmationSuccessSubtitle),
        orderId: response.id,
        country: response.country,
      });
      navigate(url);
      return;
    },
    [dispatch, formatMessage, navigate, queryClient],
  );

  return { handleCartLegacyConfirmation };
};
