let buffer = [];

export const withBuffer = (func, isReady) => {
  const funcWithBuffer = (...args) => {
    const ready = typeof isReady === 'function' ? isReady() : isReady;
    if (ready) func(...args);
    else buffer.push({ func, args });
  };

  return funcWithBuffer;
};

export const runBuffer = () => {
  buffer.forEach(({ func, args }) => func(...args));
  buffer = [];
};
