import { invalidateSessionCache } from '@app/contexts/mainContext/actions/invalidateSessionCache';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useOnExpiredCheckoutRedirectTo } from '@app/contexts/mainContext/selectors/useOnExpiredCheckoutRedirectTo';
import logger from '@app/logger';
import { ERROR_CODES } from '@commons/constants';
import PropTypes from 'prop-types';
import React from 'react';

const withHooksProps = (Component) => {
  return (props) => {
    const dispatch = useAppDispatch();
    const onExpiredCheckoutRedirectTo = useOnExpiredCheckoutRedirectTo();

    return <Component onExpiredCheckoutRedirectTo={onExpiredCheckoutRedirectTo} dispatch={dispatch} {...props} />;
  };
};
class ErrorBoundary extends React.Component {
  state = { showErrorBoundarySubtree: true };

  isChunkLoadingError(error) {
    return error?.message.indexOf('Loading chunk') !== -1 || error?.message.indexOf('Loading CSS chunk') !== -1;
  }

  isCheckoutSessionExpiredError(error) {
    return error?.response?.data?.code === ERROR_CODES.SESSION_EXPIRED;
  }

  componentDidCatch(error) {
    if (error?.__sentry_captured__) {
      // Allow the hub to re-catch the error if the main sentry instance already caught it
      delete error.__sentry_captured__;
    }

    if (this.isCheckoutSessionExpiredError(error)) {
      const { dispatch, onExpiredCheckoutRedirectTo } = this.props;
      logger.debug('[ErrorBoundary][isCheckoutSessionExpiredError]', 'redirectURL:', onExpiredCheckoutRedirectTo);
      invalidateSessionCache(dispatch);
      window.location = onExpiredCheckoutRedirectTo;
      return;
    }

    if (!this.isChunkLoadingError(error)) {
      this.setState({
        showErrorBoundarySubtree: false,
      });

      this.log(error);
    }
  }

  log(error) {
    logger.error('[ERROR_BOUNDARY]', error?.message, error?.stack, error);
  }

  render() {
    const { showErrorBoundarySubtree } = this.state;
    const { children, fallback } = this.props;
    return showErrorBoundarySubtree ? children : fallback || null;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

export default withHooksProps(ErrorBoundary);
