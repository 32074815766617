import styled from 'styled-components';

const SecurityCodeIcon = styled.div`
  color: ${({ theme }) => theme.colors.deep_purple};
  border: 1px solid ${({ theme }) => theme.colors.deep_purple};
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default SecurityCodeIcon;
