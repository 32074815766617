export const NATIVE_EVENTS = {
  CLOSE_WEBVIEW: 'close_webview',
};

export function closeWebview(payload = {}) {
  const { canRefreshSummary = false } = payload;
  const data = {
    event: NATIVE_EVENTS.CLOSE_WEBVIEW,
    data: {
      should_refresh_summary: canRefreshSummary,
    },
  };

  if (isAndroid()) {
    window.sendEvents.onReceiveGenericEvent(JSON.stringify(data));
  } else if (isIos()) {
    window.webkit.messageHandlers.ListenEventsInterface.postMessage(data);
  }
}

function isAndroid() {
  return Boolean(window?.sendEvents);
}

function isIos() {
  return Boolean(
    window?.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ListenEventsInterface,
  );
}

export default {
  closeWebview,
};
