import React, { useEffect, useState } from 'react';

export const useHeightViewport = ({ isDesktop }) => {
  const [heightViewport, setHeightViewport] = useState(0);

  useEffect(() => {
    if (!isDesktop) {
      let pendingUpdate = false;

      const viewportHandler = (event) => {
        if (pendingUpdate) return;
        pendingUpdate = true;

        requestAnimationFrame(() => {
          pendingUpdate = false;
          setHeightViewport(event.target.height);
        });
      };

      // Check Visual Viewport API is supported
      if (window.visualViewport) {
        window.visualViewport.addEventListener('resize', viewportHandler);
        window.visualViewport.addEventListener('scroll', viewportHandler);
      }

      return () => {
        if (window.visualViewport) {
          window.visualViewport.removeEventListener('resize', viewportHandler);
          window.visualViewport.removeEventListener('scroll', viewportHandler);
        }
      };
    }
  }, [isDesktop]);

  return { heightViewport };
};
