import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function updatePaymentInstruments(selectedInstruments, axios = getAxiosInstance()) {
  try {
    const { data } = await axios.put(URLS.PAYMENT_INSTRUMENTS, { selectedInstruments });
    return data;
  } catch (error) {
    logger.error('[services][updatePaymentInstruments]', parseAxiosError(error).message);
    throw error;
  }
}

export default updatePaymentInstruments;
