import { useIsCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import logger from '@app/logger';
import { BFF_COMPONENTS_TYPES } from '@app/modules/checkout/constants/bff';
import { useSetCheckoutInstruments } from '@app/modules/checkout/mutations';
import { QUERIES_NAMES as CHECKOUT_QUERIES } from '@app/modules/checkout/queries';
import { CHECKOUT_TYPES } from '@commons/constants';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

const invalidateQueryAndRefetchInactive = (queryClient, queryName) =>
  queryClient.invalidateQueries(queryName, {
    refetchInactive: true,
  });

export const useHandleInstrumentSelection = (selectedInstruments, onSuccess, onError) => {
  const isCheckoutType = useIsCheckoutType();
  const queryClient = useQueryClient();
  const { mutateAsync: updatePaymentInstruments } = useSetCheckoutInstruments(selectedInstruments);
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [error, setError] = useState({ isError: false, data: {} });

  const handleCartLegacySelection = useCallback(() => {
    return onSuccess();
  }, [onSuccess]);

  const handleCheckoutBFFSelection = useCallback(
    (response) => {
      if (response.result === 'ERROR' && response.action?.render?.type === 'DIALOG') {
        const updatePaymentComponents = response.action?.render?.components || [];
        const errorDialog = updatePaymentComponents.find((c) => c.type === BFF_COMPONENTS_TYPES.ERROR_DIALOG);
        setError({
          isError: true,
          data: {
            title: errorDialog?.title,
            description: errorDialog?.description,
            icon: errorDialog?.icon,
            firstOptionLabel: errorDialog?.call_to_action,
            onFirstOptionAction: onError,
          },
        });
        return;
      }

      if (response.result === 'SUCCESS' && response.action?.navigation_action === 'BACK') {
        return onSuccess();
      }

      logger.error('[PaymentInstrumentSelection][UNKNOWN_RESPONSE_BFF]', 'unknown response result:', response.result);
      setError({ data: {}, isError: true });
    },
    [onError, onSuccess],
  );

  const handlePaymentInstrumentSelected = useCallback(async () => {
    try {
      const [response] = await Promise.all([
        updatePaymentInstruments(),
        invalidateQueryAndRefetchInactive(queryClient, CHECKOUT_QUERIES.CHECKOUT),
      ]);

      const handler = isCheckoutType(CHECKOUT_TYPES.CART_LEGACY)
        ? handleCartLegacySelection
        : handleCheckoutBFFSelection;
      return handler(response);
    } catch (err) {
      logger.error('[PaymentInstrumentSelection][ERROR]', 'error:', err.message);
      setError({ data: {}, isError: true });
    }
  }, [isCheckoutType, handleCartLegacySelection, handleCheckoutBFFSelection, queryClient, updatePaymentInstruments]);

  useEffect(() => {
    if (!hasEffectRun) {
      setHasEffectRun(true);
      handlePaymentInstrumentSelected();
    }
  }, [handlePaymentInstrumentSelected, hasEffectRun]);

  return { error };
};
