export const DEFAULT_STYLES = {
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    outline: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    textAlign: 'center',
  },
};

export const MODAL_STYLES = {
  maxWidth: '580px',
  width: '70vw',
  height: '60vh',
  display: 'inline-block',
  textAlign: 'left',
  verticalAlign: 'middle',
  background: '#ffffff',
  boxShadow: '0 12px 15px 0 rgba(0, 0, 0, 0.25)',
  marginTop: 'auto',
  marginBottom: 'auto',
  position: 'relative',
  overflowY: 'auto',
};

export const MODAL_STYLES_WITHOUT_IFRAME = {
  maxHeight: '60vh',
  minHeight: '15vh',
  minWidth: '580px',
  height: 'auto',
  width: 'auto',
};

export function getStyles(canUseIFrameStyles = false) {
  return {
    ...DEFAULT_STYLES,
    modal: getModalStyles(canUseIFrameStyles),
  };
}

function getModalStyles(canUseIFrameStyles) {
  if (canUseIFrameStyles) return MODAL_STYLES;
  return {
    ...MODAL_STYLES,
    ...MODAL_STYLES_WITHOUT_IFRAME,
  };
}
