import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Subtitle } from '@appscore/web-components';
import { useDeviceInfo } from '@app/contexts/mainContext/selectors/useDeviceInfo';
import { useHeaderConfiguration } from '@hooks/layout/useHeaderConfiguration';
import { Web, Webview, AppVersion } from '@app/contexts/mainContext/deviceInfo/components';
import { webNavigationHeaderVersion } from '@app/utils/constants';

const Container = styled.div`
  top: 0;
  position: sticky;
  background: white;
  @media ${({ theme }) => theme.mediaQueries.mobileIos} {
    padding-top: ${({ theme, isIOSWebview }) => (isIOSWebview ? theme.spacing(5) : 0)};
  }
  @media ${({ theme }) => theme.mediaQueries.mobileIosNotch} {
    padding-top: ${({ theme, isIOSWebview }) => (isIOSWebview ? theme.spacing(15) : 0)};
  }
`;

function FixedHeader({ children, action }) {
  const deviceInfo = useDeviceInfo();
  const isWebView = deviceInfo.isWebview();

  useHeaderConfiguration({ isWebView });

  const headerLeft = <span>{action}</span>;

  return (
    <Container isIOSWebview={isWebView && deviceInfo.isIos()}>
      <Header background="white">
        <Header.Left>
          <Webview.Android>
            <AppVersion min={webNavigationHeaderVersion.ANDROID_VERSION}>{headerLeft}</AppVersion>
          </Webview.Android>
          <Webview.Ios>
            <AppVersion min={webNavigationHeaderVersion.IOS_VERSION}>{headerLeft}</AppVersion>
          </Webview.Ios>
          <Web>{headerLeft}</Web>
        </Header.Left>
        <Header.Main>
          <Subtitle label={children} separator={false} marginBottom={false} />
        </Header.Main>
      </Header>
    </Container>
  );
}

FixedHeader.propTypes = {
  children: PropTypes.string,
  action: PropTypes.node.isRequired,
};

export default FixedHeader;
