import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: ${({ theme, desktopMode }) => (desktopMode ? theme.spacing(20) : theme.spacing(35, 0, 12, 0))};
`;

const Title = styled.div`
  ${({ theme }) => theme.fontFamily.mainFont.xxLargeExtraBold};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Subtitle = styled.div`
  ${({ theme }) => theme.fontFamily.secondaryFont.mediumRegular};
  margin-bottom: ${({ theme }) => theme.spacing(16)};
`;

function Content({ title, subtitle, desktopMode }) {
  return (
    <Container desktopMode={desktopMode}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
}

Content.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  desktopMode: PropTypes.bool,
};

export default Content;
