import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import { getStyles } from './getStyles';
function ResponsiveModal({ children, canUseIFrameStyles }) {
  return (
    <Modal
      open={true}
      showCloseIcon={false}
      styles={getStyles(canUseIFrameStyles)}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      {children}
    </Modal>
  );
}

ResponsiveModal.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
  canUseIFrameStyles: PropTypes.bool,
};

ResponsiveModal.defaultProps = {
  open: false,
  canUseIFrameStyles: false,
};

export default ResponsiveModal;
