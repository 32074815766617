import messages from '@app/modules/vendors/pages/PreOrderTimes/messages';
import FixedHeader from '@components/FixedHeader';
import GenericButton from '@components/GenericButton';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import ArrowLeft from '@pedidosya/web-fenix/icons/ArrowLeft';
import React from 'react';
import { useIntl } from 'react-intl';

const DeliveryDetailHeader = () => {
  const { formatMessage } = useIntl();
  const { navigateBack } = useNavigateBack();

  return (
    <FixedHeader
      action={
        <GenericButton title="close" onClick={() => navigateBack()}>
          <ArrowLeft size="medium" />
        </GenericButton>
      }
    >
      {formatMessage(messages.headerTitle)}
    </FixedHeader>
  );
};

export default DeliveryDetailHeader;
