import { useOpenGlobalModal } from '@app/contexts/GlobalModal/hooks/useOpenGlobalModal';
import { useAppTracker } from '@app/contexts/mainContext/selectors/useAppTracker';
import transformEvent from '@app/hooks/tracking/transformEvent';
import { BFF_ACTION_TYPES } from '@app/modules/checkout/constants/bff';
import { useMemo } from 'react';
import { useCloseCheckout } from '../navigation/useCloseCheckout';
import { useConfirmCheckoutActionDefinition } from './useConfirmCheckoutActionDefinition';
import { useDingDongActionDefinition } from './useDingDongActionDefinition';
import { useNavigateActionDefinition } from './useNavigateActionDefinition';
import { useNavigateToWebview } from './useNavigateToWebview';
import { useOrderEventActionDefinition } from './useOrderEventActionDefinition';

export const EXTERNAL_ROUTES = {
  SELECT_INSTRUMENTS: 'SELECT_INSTRUMENTS',
  DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
  ADDRESS_FORM: 'ADDRESS_FORM',
};

export const LEGACY_TRACKING_KEYS = {
  'transaction.success': 'transaction',
};

export const useCheckoutActionDefinitions = ({
  trackCloseClick,
  isNativeClose,
  trackingData,
  trackTransactionFailed,
  trackTransactionSuccess,
  confirmationOptions,
  paymentDetails,
}) => {
  const { tracker } = useAppTracker();
  const { closeCheckout } = useCloseCheckout({ isNativeClose });
  const { navigateToWebview } = useNavigateToWebview();
  const { navigateActionDefinition } = useNavigateActionDefinition();
  const openGlobalModal = useOpenGlobalModal();
  const { orderEventActionDefinition } = useOrderEventActionDefinition({
    trackTransactionFailed,
    trackTransactionSuccess,
    ...confirmationOptions,
  });
  const { dingDongActionDefinition } = useDingDongActionDefinition();
  const { confirmCheckoutActionDefinition } = useConfirmCheckoutActionDefinition({
    trackTransactionFailed,
    trackTransactionSuccess,
    confirmationOptions,
    paymentDetails,
  });

  const actionDefinitions = useMemo(
    () => ({
      [BFF_ACTION_TYPES.NAVIGATE_BACK]: () => closeCheckout(trackCloseClick),
      [BFF_ACTION_TYPES.NAVIGATE]: navigateActionDefinition,
      [BFF_ACTION_TYPES.TRACKING]: ({ event, attributes }) => {
        // We need to add locationAreaAddress attribute because it is required for DH and BFF doesn't have it
        const finalAttributes =
          event === 'transaction' || event === 'purchase'
            ? { ...attributes, locationAreaAddress: trackingData.locationAreaAddress }
            : attributes;
        tracker.track(transformEvent(event), finalAttributes);
      },
      [BFF_ACTION_TYPES.TRACKING_LEGACY]: ({
        tracking_key: trackingKey,
        additional_attributes: additionalAttributes = {},
      }) => {
        tracker.track(LEGACY_TRACKING_KEYS[trackingKey] || trackingKey, { ...trackingData, ...additionalAttributes });
      },
      [BFF_ACTION_TYPES.NAVIGATE_TO_WEBVIEW]: (payload) => navigateToWebview(payload),
      [BFF_ACTION_TYPES.OPEN_MODAL]: (modalProps) => {
        openGlobalModal({ ...modalProps, actionDefinitions });
      },
      [BFF_ACTION_TYPES.ORDER_EVENT]: orderEventActionDefinition,
      [BFF_ACTION_TYPES.CONFIRM_CHECKOUT]: async (payload) => await confirmCheckoutActionDefinition(payload),
      [BFF_ACTION_TYPES.DING_DONG]: dingDongActionDefinition,
    }),
    [
      closeCheckout,
      confirmCheckoutActionDefinition,
      dingDongActionDefinition,
      navigateActionDefinition,
      navigateToWebview,
      openGlobalModal,
      orderEventActionDefinition,
      trackCloseClick,
      tracker,
      trackingData,
    ],
  );

  return { actionDefinitions };
};
