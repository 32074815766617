import { useCallback } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

export const useNavigateToWebview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  /**
   * @param {object} payload contains action data for NAVIGATE_TO_WEBVIEW type
   */
  const navigateToWebview = useCallback(
    ({ url, params }) => {
      const route = params ? `${url}?${createSearchParams(params)}` : url;
      navigate(route, { state: { background: location } });
    },
    [location, navigate],
  );
  return { navigateToWebview };
};
