import { FwfFlagsProvider } from '@app/contexts/FwfFlags';
import logger from '@app/logger';
import ErrorBoundary from '@app/modules/shared/components/ErrorBoundary';
import Error from '@app/modules/shared/containers/Error/Loadable';
import { theme } from '@app/theme';
import { BackendDrivenProvider } from '@checkout-ui/backend-driven';
import { getBasename, ROUTES } from '@commons/services/routes.service';
import { BrandError } from '@components/Error';
import { useBFFActionDefinitions } from '@hooks/BFF/useBFFActionDefinitions';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import CheckoutRouter from './CheckoutRouter';
import { MessageModalProvider } from './contexts/GlobalModal';
import GlobalModal from './contexts/GlobalModal/components/GlobalModal';
import { createIntlProvider, getMessages } from './contexts/IntlProvider';
import AppProviderWithResources from './contexts/mainContext/providerWithResources';
import { useDeviceInfo } from './contexts/mainContext/selectors/useDeviceInfo';
import ReactQueryProvider from './contexts/reactQuery';
import { useIsDesktop } from './hooks/layout/useIsDesktop';
import BasicHelmet from './modules/shared/components/Helmet/BasicHelmet';
import { AppGlobalStyleSheet, RootGlobalStyleSheet } from './theme/ConditionalGlobalStyleSheet';

function App({ tracker, context, initialState, fwfPromise }) {
  const { locale } = context;
  const loaderProps = useLoaderProps(initialState);

  return (
    <ThemeProvider theme={theme}>
      <ReactQueryProvider>
        <AppProviderWithResources tracker={tracker} context={context} initialState={initialState}>
          <FenixFonts />
          <AppGlobalStyleSheet />
          <IntlProvider locale={locale} loaderProps={loaderProps}>
            <Router basename={getBasename()}>
              <BasicHelmet />
              <FwfFlagsProvider fwfPromise={fwfPromise} contextData={context}>
                <ErrorBoundary fallback={<Error />}>
                  <MessageModalProvider>
                    <BDUIProvider>
                      <GlobalModal />
                      <CheckoutRouter />
                    </BDUIProvider>
                  </MessageModalProvider>
                </ErrorBoundary>
              </FwfFlagsProvider>
            </Router>
          </IntlProvider>
        </AppProviderWithResources>
      </ReactQueryProvider>
    </ThemeProvider>
  );
}

const useLoaderProps = (initialState) => {
  const isDesktop = useIsDesktop();
  const { checkoutType, entryPoint } = initialState;

  return { checkoutType, entryPoint, isDesktop };
};

const IntlProvider = (props) => {
  const { locale } = props;
  return createIntlProvider(locale, getMessages)(props);
};

const BDUIProvider = ({ children }) => {
  const deviceInfo = useDeviceInfo();
  const { actionDefinitions: defaultActionDefinitions } = useBFFActionDefinitions();
  const navigate = useNavigate();

  return (
    <BackendDrivenProvider
      ROUTES={ROUTES}
      logger={logger}
      deviceInfo={deviceInfo}
      defaultActionDefinitions={defaultActionDefinitions}
      navigate={navigate}
    >
      {children}
    </BackendDrivenProvider>
  );
};

export function LoadAppError() {
  const hasHistory = window?.history?.length > 1;
  const secondaryLabel = hasHistory ? 'Volver' : null;
  const onSecondaryAction = hasHistory
    ? () => {
        window.history.go(-1);
      }
    : null;
  return (
    <ThemeProvider theme={theme}>
      <>
        <FenixFonts />
        <RootGlobalStyleSheet />
        <BrandError
          onClick={() => window.location.reload()}
          secondaryActionLabel={secondaryLabel}
          secondaryActionClick={onSecondaryAction}
        />
      </>
    </ThemeProvider>
  );
}
App.propTypes = {
  context: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    tracker: PropTypes.any, // Validated in Provider
    deviceInfo: PropTypes.object.isRequired,
  }).isRequired,
};

export default App;
