import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function updateAddress(address, axios = getAxiosInstance()) {
  try {
    const { data } = await axios.put(URLS.UPDATE_ADDRESS, { address });
    return data;
  } catch (error) {
    logger.error('[services][updateAddress]', parseAxiosError(error).message);
    throw error;
  }
}

export default updateAddress;
