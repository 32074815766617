import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getFormattedValueToCurrency } from './utils/getFormattedValueToCurrency';
import { getEvent } from './utils/getEvent';
import Input from '@pedidosya/web-fenix/atoms/Input';
import EmptyStateIcon from '@pedidosya/web-fenix/icons/EmptyState';

const InputCurrencyFormatter = ({ name, label, placeholder, helpText, currency, onChange }) => {
  const { countryCode, symbol, precision } = currency;
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const { currencyFormattedValue, numericValue } = getFormattedValueToCurrency({
      inputValue: event.target.value,
      precision,
      country: countryCode,
    });
    setValue(currencyFormattedValue);
    onChange(getEvent(numericValue, event));
  };

  const handleClear = () => {
    setValue('');
    onChange(getEvent(''));
  };

  return (
    <Input
      aria-label={name}
      name={name}
      value={value}
      label={label}
      placeholder={placeholder}
      helpText={helpText}
      prefix={symbol}
      suffix={value ? <EmptyStateIcon onClick={handleClear} /> : null}
      onChange={handleChange}
      autoComplete="off"
      pattern="[0-9]*"
      inputMode="numeric"
    />
  );
};

InputCurrencyFormatter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  currency: PropTypes.shape({
    countryCode: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    precision: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
};

export default InputCurrencyFormatter;
