import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@components/Spinner';
import { CHECKOUT_TYPES } from '@commons/constants';
import LoyaltySkeleton from './components/LoyaltySkeleton';
import DonationSkeleton from './components/DonationSkeleton';
import BNPLSkeleton from './components/BNPLSkeleton';

const CheckoutSummaryLoading = ({ isDesktop, checkoutType, entryPoint }) => {
  if (isDesktop) return <Spinner />;

  switch (checkoutType) {
    case CHECKOUT_TYPES.CART_LEGACY:
      return <Spinner />;
    case CHECKOUT_TYPES.LOYALTY_SUBSCRIPTION:
      return <LoyaltySkeleton isLandingAllInOne={Boolean(entryPoint)} />;
    case CHECKOUT_TYPES.DONATION:
      return <DonationSkeleton />;
    case CHECKOUT_TYPES.BNPL_DEBT:
      return <BNPLSkeleton />;
    default:
      return <Spinner />;
  }
};

CheckoutSummaryLoading.defaultProps = {
  isDesktop: false,
};

CheckoutSummaryLoading.propTypes = {
  isDesktop: PropTypes.bool,
  checkoutType: PropTypes.oneOf(Object.keys(CHECKOUT_TYPES)).isRequired,
  entryPoint: PropTypes.string,
};

export default CheckoutSummaryLoading;
