import CheckoutSummaryLoading from '@app/components/CheckoutSummaryLoading';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

export const getMessages = (locale) => import(`../../../build/translations/${locale}.json`);

export const createIntlProvider =
  (locale, getMessages) =>
  ({ initialMessages, loaderProps, children }) => {
    const [messages, setMessages] = useState(initialMessages);

    const setNoMessages = () => setMessages([]);

    useEffect(() => {
      if (messages) return;
      getMessages(locale).then(setMessages).catch(setNoMessages);
    }, [messages]);

    if (!messages) return <CheckoutSummaryLoading {...loaderProps} />;

    return (
      <IntlProvider messages={messages} locale={locale} defaultLocale="es">
        {children}
      </IntlProvider>
    );
  };
