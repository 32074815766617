import { Severity } from '@sentry/browser';
import { canLog, setSentryInstance, setLogLevel, getSentryInstance } from './instance';
import { initSentry } from './initialize';
import { isProduction } from '@app/utils/environment';
import { runBuffer, withBuffer } from './withBuffer';
import { addDataToScope } from './addDataToScope';

function onSentryInitialized() {
  runBuffer();
}

export function initializeSentry({ country, environment, deviceInfo, userId }) {
  const instance = initSentry({ environment });
  setSentryInstance(instance);
  const dataToScope = [
    { key: 'country', value: country?.name },
    { key: 'platform', value: deviceInfo?.isWebview ? 'webview' : 'web' },
    { key: 'os', value: deviceInfo?.os },
    { key: 'deviceType', value: deviceInfo?.type },
    { key: 'userId', value: userId },
  ];
  addDataToScope(dataToScope, instance);
  const logLevelSentry = isProduction() ? Severity.Info : Severity.Debug;
  setLogLevel(logLevelSentry);
  onSentryInitialized();
}

function logErrorToSentry(error, data) {
  if (canLog(Severity.Error)) {
    const instance = getSentryInstance();
    if (data) {
      instance.withScope((scope) => {
        scope.setExtra('data', data);
        instance.captureException(error);
      });
    } else instance.captureException(error);
  }
}

export const logError = withBuffer(logErrorToSentry, getSentryInstance);

// We use prefix, to avoid confusing events that aren't from live env.
function logEventToSentry(event, { data, level = Severity.Info } = {}) {
  if (canLog(level)) {
    const instance = getSentryInstance();
    const prefix = isProduction() ? '' : '[STG]';
    const message = `${prefix}${event}`;
    instance.withScope((scope) => {
      scope.setLevel(level);
      scope.setFingerprint([message]);
      if (data) scope.setExtra('data', data);
      instance.captureMessage(message);
    });
  }
}

export const logEvent = withBuffer(logEventToSentry, getSentryInstance);
