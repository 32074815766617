import { ROUTES } from '@commons/services/routes.service';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigateToAddressForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  /**
   * @param {object} addressData Contains Address Data
   */
  const navigateToAddressForm = useCallback(
    (addressData) => {
      navigate(ROUTES.ADDRESS_FORM, { state: { addressData, background: location } });
    },
    [location, navigate],
  );
  return { navigateToAddressForm };
};
