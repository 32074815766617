import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function confirmBilling(billingData, axios = getAxiosInstance()) {
  try {
    const { data } = await axios.post(URLS.CONFIRM_BILLING, billingData);
    return data;
  } catch (error) {
    logger.error('[services][confirmBilling]', parseAxiosError(error).message);
    throw error;
  }
}

export default confirmBilling;
