export const BFF_COMPONENTS_TYPES = {
  BILLING_FORM: 'billing_company',
  BILLING_CHECKBOX: 'billing_checkbox',
  BILLING_NAME: 'billing_name',
  BILLING_NUMBER: 'billing_number',
  BILLING_BUTTON: 'billing_button',
};

export const BFF_ACTION_TYPES = {
  NAVIGATE_BACK: 'NAVIGATE_BACK',
};
