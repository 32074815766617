import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@appscore/web-components';
import Spinner from '@components/Spinner';

const StyledDialog = styled(Dialog)`
  flex: unset;
  padding: 48px;
`;

function OverlaySpinner() {
  return (
    <StyledDialog open>
      <Spinner />
    </StyledDialog>
  );
}

export default OverlaySpinner;
