import { API_BASE_URI } from '@commons/constants';

export const URLS = {
  START_CHECKOUT: `${API_BASE_URI}/start`,
  GET_CHECKOUT: `${API_BASE_URI}`,
  CONFIRM_CHECKOUT: `${API_BASE_URI}/confirm`,
  GET_CHECKOUT_CONTEXT: `${API_BASE_URI}/checkout-context`,
  UPDATE_ADDRESS: `${API_BASE_URI}/address`,
  PERFORM_BACKEND_ACTION: `${API_BASE_URI}/perform-backend-action`,
};
