import { useAppState } from '../context';
import { DEVICE_TYPE } from '@utils/constants';

/** Navigator Web Mobile **/
export const useIsMobile = () => {
  const [{ context }] = useAppState();
  const { device } = context;

  return device === DEVICE_TYPE.MOBILE;
};
