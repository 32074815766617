import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SummarySectionTitle from '../../SummarySectionTitle';

const Container = styled.div`
  margin: ${({ theme }) => `0 0 ${theme.space('spacing-component-medium')}`};
  :first-child {
    margin: ${({ theme }) =>
      `${theme.space('spacing-component-2xlarge')} 0 ${theme.space('spacing-component-medium')}`};
    margin-bottom: ${({ theme }) => theme.space('spacing-component-medium')};
  }
`;

const StyledCardContainer = styled(CardContainer)`
  padding: ${({ theme }) => theme.space('spacing-component-2xlarge')};
  width: auto;
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

function CheckoutPaymentMethodContainer({ header, onClick, children }) {
  return (
    <Container>
      {header ? <SummarySectionTitle>{header}</SummarySectionTitle> : null}
      <StyledCardContainer withShadow={false}>
        <PaymentMethodContainer onClick={onClick}>{children}</PaymentMethodContainer>
      </StyledCardContainer>
    </Container>
  );
}

CheckoutPaymentMethodContainer.propTypes = {
  header: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
};

export default CheckoutPaymentMethodContainer;
