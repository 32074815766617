import { defineMessages } from 'react-intl';

export default defineMessages({
  tokenizationErrorTitle: {
    id: 'app.paymentMethod.securityCodeValidation.tokenizationError.title',
    defaultMessage: 'Código de seguridad inválido',
  },
  tokenizationErrorDescription: {
    id: 'app.paymentMethod.securityCodeValidation.tokenizationError.description',
    defaultMessage: 'Por favor, vuelve a ingresar el código de seguridad e intenta nuevamente',
  },
  tokenizationErrorActionLabel: {
    id: 'app.paymentMethod.securityCodeValidation.tokenizationError.actionLabel',
    defaultMessage: 'Probar de nuevo',
  },
});
