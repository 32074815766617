import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function startCheckout(basketId, checkoutType, axios = getAxiosInstance()) {
  try {
    const { data } = await axios.post(URLS.START_CHECKOUT, {
      basketId,
      checkoutType,
    });
    return data;
  } catch (error) {
    logger.error('[services][startCheckout]', parseAxiosError(error).message);
    throw error;
  }
}

export default startCheckout;
