import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ROUTES } from '@commons/services/routes.service';
import { splitTextByAnotherText } from '@utils/string';
import TermsAndConditionsUI from '@checkout-ui/terms-and-conditions';

const parseTermsAndConditions = ({ fullText, linkText, linkUrl }) => {
  const { parts, wasSplit } = splitTextByAnotherText(fullText, linkText);
  const [first, last] = parts;
  const to = {
    pathname: ROUTES.TERMS_AND_CONDITIONS,
    state: {
      url: linkUrl,
    },
  };
  return wasSplit ? (
    <>
      {first}
      <Link to={to.pathname} state={to.state}>
        {linkText}
      </Link>
      {last}
    </>
  ) : (
    <Link to={to.pathname} state={to.state}>
      {fullText}
    </Link>
  );
};

const Container = styled.section`
  margin-top: ${({ theme }) => theme.spacing(10)};
`;

function TermsAndConditions({ fullText, linkText, linkUrl, isSelected, handleClick }) {
  return (
    <Container>
      <TermsAndConditionsUI isSelected={isSelected} onClick={handleClick}>
        {parseTermsAndConditions({ fullText, linkText, linkUrl })}
      </TermsAndConditionsUI>
    </Container>
  );
}

TermsAndConditions.propTypes = {
  fullText: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  handleClick: PropTypes.func,
};

TermsAndConditions.defaultProps = {
  isSelected: false,
};

export default TermsAndConditions;
