import { useGetMissingChallengesForInstrument } from '@app/contexts/mainContext/selectors/useGetMissingChallengesForInstrument';
import { useNavigateToChallenge } from '@app/hooks/navigation/useNavigateToChallenge';
import { useConfirmCheckout } from '@app/modules/checkout/hooks/useConfirmCheckout';

export const useConfirmCheckoutActionDefinition = ({
  trackTransactionFailed,
  trackTransactionSuccess,
  confirmationOptions,
  paymentDetails,
}) => {
  const { confirmCheckout } = useConfirmCheckout();
  const getMissingChallengesForInstrument = useGetMissingChallengesForInstrument();
  const navigateToChallenge = useNavigateToChallenge();

  const confirmCheckoutActionDefinition = async ({ version, is_repeat_order: isRepeatOrder }) => {
    const paymentInstrumentToChallenge = paymentDetails.find((paymentInstrument) => {
      const uncompletedChallenges = getMissingChallengesForInstrument(paymentInstrument);
      return Boolean(uncompletedChallenges?.length);
    });

    if (paymentInstrumentToChallenge) {
      const paymentInstrumentId = paymentInstrumentToChallenge?.instrument_id;
      const uncompletedChallenges = getMissingChallengesForInstrument(paymentInstrumentToChallenge);
      navigateToChallenge(uncompletedChallenges[0], { paymentInstrumentId });
      return;
    }

    const result = await confirmCheckout({
      trackTransactionFailed,
      trackTransactionSuccess,
      ...confirmationOptions,
      version,
      isRepeatOrder,
    });
    return { actions: result?.actions };
  };

  return { confirmCheckoutActionDefinition };
};
