import React from 'react';
import styled from 'styled-components';
import { ArrowRight, Warning } from '@appscore/web-components';
import PropTypes from 'prop-types';
import CheckoutPaymentMethodContainer from './CheckoutPaymentMethodContainer';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

Container.Message = styled.div`
  ${({ theme }) => theme.fontFamily.secondaryFont.smallRegular}
  color: ${({ theme }) => theme.colors.red};
  line-height: ${({ theme }) => `${theme.spacing(10)}`};
  padding-left: ${({ theme }) => `${theme.spacing(6)}`};
`;

Container.Action = styled.div`
  flex-grow: 2;
  text-align: end;
`;

function MissingCheckoutPaymentMethod({ title, header, onClick }) {
  return (
    <CheckoutPaymentMethodContainer header={header} onClick={onClick}>
      <Container>
        <Warning size="small" color="brand" />
        <Container.Message>{title}</Container.Message>
        <Container.Action>
          <ArrowRight size="small" color="brand" />
        </Container.Action>
      </Container>
    </CheckoutPaymentMethodContainer>
  );
}

MissingCheckoutPaymentMethod.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MissingCheckoutPaymentMethod;
