import { CACHE_KEYS, sessionCache } from '@app/services/cache';
import { ACTION_TYPES } from '../actionTypes';
import { STATE_KEYS } from '../constants';

export const updateHistoryLengthBeforeNavigation = (dispatch, checkoutId, length) => {
  sessionCache.set(CACHE_KEYS.HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS, { checkoutId, length });
  dispatch({
    type: ACTION_TYPES.UPDATE_HISTORY_LENGTH_BEFORE_NAVIGATION,
    payload: {
      [STATE_KEYS.HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS]: { checkoutId, length },
    },
  });
};
