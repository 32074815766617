import { CACHE_KEYS, sessionCache } from '@app/services/cache';
import { ACTION_TYPES } from '../actionTypes';
import { STATE_KEYS } from '../constants';

export const updateOnExpiredCheckoutRedirectTo = (dispatch, redirectUrl) => {
  sessionCache.set(CACHE_KEYS.ON_EXPIRED_CHECKOUT_REDIRECT_TO, redirectUrl);
  dispatch({
    type: ACTION_TYPES.UPDATE_EXPIRED_CHECKOUT_REDIRECT_URL,
    payload: { [STATE_KEYS.ON_EXPIRED_CHECKOUT_REDIRECT_TO]: redirectUrl },
  });
};
