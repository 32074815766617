import React, { createContext, useContext, useState } from 'react';

const MessageModalContext = createContext();
const { Provider } = MessageModalContext;

export const MessageModalProvider = ({ children }) => {
  const [globalModalProps, setGlobalModalProps] = useState({ open: false });

  return <Provider value={{ globalModalProps, setGlobalModalProps }}>{children}</Provider>;
};

export const useMessageModalContext = () => useContext(MessageModalContext);
