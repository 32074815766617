import { useIsCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import { useGoBackToCheckoutSummary } from '@hooks/navigation/useGoBackToCheckoutSummary';
import { CHECKOUT_TYPES, QUERY_PARAM } from '@commons/constants';
import CardErrorIcon from '@components/CardErrorIcon';
import ErrorDialog from '@components/ErrorDialog';
import Spinner from '@components/Spinner';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import { getAndParseAllQueryParams } from '@utils/domRouter';
import React, { useCallback } from 'react';
import { useHandleInstrumentSelection } from './hooks/useHandleInstrumentSelection';

function PaymentInstrumentSelectionSuccess() {
  const { navigateBack } = useNavigateBack();
  const { goBackToCheckoutSummary } = useGoBackToCheckoutSummary('PAYMENT_INSTRUMENT_SELECTION_FLOW');
  const selectedInstruments = getAndParseAllQueryParams(location, QUERY_PARAM.SELECTED_INSTRUMENTS);
  const defaultErrorAction = useCallback(() => navigateBack(), [navigateBack]);
  const isCheckoutType = useIsCheckoutType();

  const goBackToSummary = useCallback(() => {
    if (isCheckoutType(CHECKOUT_TYPES.CART_LEGACY)) {
      window.parent.postMessage({ event: 'submit' }, '*');
      return;
    }
    goBackToCheckoutSummary();
  }, [goBackToCheckoutSummary, isCheckoutType]);

  const { error: updatePaymentError } = useHandleInstrumentSelection(
    selectedInstruments,
    goBackToSummary,
    defaultErrorAction,
  );

  return (
    <>
      <Spinner />
      <ErrorDialog
        show={updatePaymentError.isError}
        title={updatePaymentError.data?.title || 'No pudimos actualizar tu medio de pago'}
        description={updatePaymentError.data?.description || '  '}
        firstOptionLabel={updatePaymentError.data?.firstOptionLabel || 'Cerrar'}
        icon={<CardErrorIcon icon={updatePaymentError.data?.icon} />}
        onFirstOptionAction={updatePaymentError.data?.onFirstOptionAction || defaultErrorAction}
      />
    </>
  );
}

export default PaymentInstrumentSelectionSuccess;
