import { useCallback, useEffect, useState } from 'react';
import logger, { initClientLogger } from '@app/logger';
import getPublicEnvironment from '@app/services/env/getPublicEnvironment';
import { setEnvironment } from '@utils/environment';

export const usePublicEnvironmentSetup = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const loadPublicEnvironment = useCallback(async () => {
    try {
      const { data: publicEnvData, success, error: publicEnvError } = await getPublicEnvironment();
      if (success) {
        if (publicEnvData.redirect) {
          window.location.href = publicEnvData.redirect.location;
          return null;
        }

        const { environment } = publicEnvData;
        setEnvironment(environment);
        // Client logger initialization
        initClientLogger(publicEnvData);

        setData(publicEnvData);
        setIsLoading(false);
      } else {
        logger.error('[loadPublicEnvironment]', 'service error:', publicEnvError);
        setError(publicEnvError);
        setIsLoading(false);
      }
    } catch (err) {
      logger.error('[loadPublicEnvironment]', 'exception', err);
      setError(err);
      setIsLoading(false);
    }

    return null;
  }, []);

  useEffect(() => {
    loadPublicEnvironment();
  }, [loadPublicEnvironment]);

  return {
    data,
    isLoading,
    error,
  };
};
