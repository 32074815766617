import IFrameWithSpinner from '@app/components/ResponsiveModal/components/IframeWithSpinner';
import { useGoBackToCheckoutSummary } from '@hooks/navigation/useGoBackToCheckoutSummary';
import { useInvalidateQueriesAndRefetchInactive } from '@app/contexts/reactQuery/hooks/useInvalidateQueriesAndRefetchInactive';
import { useIsDesktop } from '@hooks/layout/useIsDesktop';
import useOnce from '@hooks/useOnce';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { QUERIES_NAMES } from '../../queries';

const Container = styled.div`
  height: ${({ isDesktop }) => (isDesktop ? '100%' : '100vh')};
`;

function PaymentInstrument() {
  const { goBackToCheckoutSummary } = useGoBackToCheckoutSummary('PAYMENT_INSTRUMENT_FLOW');
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();
  const { state } = useLocation();
  const isDesktop = useIsDesktop();
  const url = state?.url;

  const handlePaymentInstrumentCallback = ({ data }) => {
    const { event } = data;
    if (event === 'submit') {
      invalidateQueriesAndRefetchInactive([QUERIES_NAMES.CHECKOUT]);
      goBackToCheckoutSummary();
    }
  };

  useOnce(() => {
    window.addEventListener('message', handlePaymentInstrumentCallback, false);
    return () => window.removeEventListener('message', handlePaymentInstrumentCallback, false);
  });

  return (
    <Container isDesktop={isDesktop}>
      <IFrameWithSpinner src={url} isDesktop={isDesktop} />
    </Container>
  );
}

export default PaymentInstrument;
