export const ACTION_TYPES = {
  UPDATE_CHECKOUT_CONFIRMATION_STATUS: 'UPDATE_CHECKOUT_CONFIRMATION_STATUS',
  UPDATE_CHECKOUT_BACKEND_ACTION_STATUS: 'UPDATE_CHECKOUT_BACKEND_ACTION_STATUS',
  UPDATE_EXPIRED_CHECKOUT_REDIRECT_URL: 'UPDATE_EXPIRED_CHECKOUT_REDIRECT_URL',
  UPDATE_CHECKOUT_TYPE: 'UPDATE_CHECKOUT_TYPE',
  UPDATE_TERMS_AND_CONDITIONS_STATUS: 'UPDATE_TERMS_AND_CONDITIONS_STATUS',
  ADD_PAYMENT_INSTRUMENT_CHALLENGE_RESULT: 'ADD_PAYMENT_INSTRUMENT_CHALLENGE_RESULT',
  RESET_PAYMENT_INSTRUMENTS_CHALLENGES: 'RESET_PAYMENT_INSTRUMENTS_CHALLENGES',
  UPDATE_HISTORY_LENGTH_BEFORE_NAVIGATION: 'UPDATE_HISTORY_LENGTH_BEFORE_NAVIGATION',
  INVALIDATE_SESSION_CACHE: 'INVALIDATE_SESSION_CACHE',
};
