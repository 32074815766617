import { useFlagVariation } from '@app/contexts/FwfFlags/hooks';
import { useIsCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import { useDeviceInfo } from '@app/contexts/mainContext/selectors/useDeviceInfo';
import { useIsMobile } from '@app/contexts/mainContext/selectors/useIsMobile';
import { parsePaymentInstrument } from '@app/modules/checkout/pages/CheckoutSummary/utils/parsePaymentInstrument';
import { useExternalRouterNavigation } from '@checkout-ui/backend-driven';
import { CHECKOUT_TYPES, QUERY_PARAM } from '@commons/constants';
import { BOOLEAN_FLAGS } from '@commons/fwf/constants';
import {
  EXTERNAL_URIS_FRONT,
  ROUTES,
  URIS_FRONT,
  createPaymentMethodsMicrositePath,
} from '@commons/services/routes.service';
import stringify from '@commons/utils/object/stringify';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNativeUserPaymentInstruments } from './useNativeUserPaymentInstruments';

export const useNavigateToUserPaymentInstruments = () => {
  const nativeNavigationFlag = useFlagVariation(BOOLEAN_FLAGS.WEB_MICRO_SUMMARY_NATIVE_INSTRUMENT.KEY);
  const isCheckoutType = useIsCheckoutType();
  const deviceInfo = useDeviceInfo();
  const isMobileWeb = useIsMobile();
  const { externalRouterNavigation } = useExternalRouterNavigation();
  const location = useLocation();
  const navigate = useNavigate();
  const successCallbackPath = URIS_FRONT.PAYMENT_INSTRUMENT_SELECTION_SUCCESS;
  const { openNativeToUserPaymentInstruments } = useNativeUserPaymentInstruments();

  /**
   * @param {string} resourceId Could be a checkoutId or a purchaseId
   * @param {object} paymentDetails Array of payment instruments with details
   * @param {string} instrumentIds String of instrument IDs separated by commas
   */
  const navigateToUserPaymentInstruments = useCallback(
    (resourceId, { paymentDetails, instrumentIds }) => {
      if (deviceInfo.isWebview() && !isMobileWeb && nativeNavigationFlag && !isCheckoutType(CHECKOUT_TYPES.DONATION)) {
        return openNativeToUserPaymentInstruments(resourceId, { paymentDetails, instrumentIds });
      }

      const selectedInstruments = parsePaymentInstrument({ paymentDetails, instrumentIds });

      const url = createPaymentMethodsMicrositePath(
        EXTERNAL_URIS_FRONT.PAYMENT_METHODS_USER_INSTRUMENTS,
        resourceId,
        successCallbackPath,
        {
          [QUERY_PARAM.SELECTED_INSTRUMENTS]: stringify(selectedInstruments),
        },
      );

      if (!isCheckoutType(CHECKOUT_TYPES.CART_LEGACY)) return externalRouterNavigation(url);

      return navigate(ROUTES.PAYMENT_INSTRUMENT, { state: { url, background: location } });
    },
    [
      deviceInfo,
      isMobileWeb,
      nativeNavigationFlag,
      openNativeToUserPaymentInstruments,
      successCallbackPath,
      isCheckoutType,
      externalRouterNavigation,
      navigate,
      location,
    ],
  );

  return { navigateToUserPaymentInstruments };
};
