import { Severity } from '@sentry/browser';

export const SENTRY_CONFIG = {
  dsn: SENTRY_DSN,
};

export const SENTRY_LOG_LEVEL = {
  debug: Severity.Debug,
  info: Severity.Info,
  warn: Severity.Warning,
  warning: Severity.Warning,
  error: Severity.Error,
  fatal: Severity.Fatal,
};
