import logger from '@app/logger';
import format from '@commons/utils/string/format';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function getSecurityCodeValidation(paymentInstrumentId, axios = getAxiosInstance()) {
  try {
    const url = format(URLS.SECURITY_CODE_VALIDATION, paymentInstrumentId);
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    logger.error('[services][getSecurityCodeValidation]', parseAxiosError(error).message);
    throw error;
  }
}

export default getSecurityCodeValidation;
