/**
 * @param {object} attributes
 * @param {object} data
 * @returns object
 */

import isString from '@commons/utils/string/isString';

export function getUpdatedAttributesFromData(attributes = {}, data = {}) {
  const updatedAttributes = Object.entries(attributes).reduce((acc, [key, value]) => {
    const shouldReplaceValue = value && isString(value) && value.startsWith('{') && value.endsWith('}');
    const dataValueExists = shouldReplaceValue && Boolean(data[value.slice(1, -1)]);

    return {
      ...acc,
      [key]: dataValueExists ? data[value.slice(1, -1)] : value,
    };
  }, {});

  return updatedAttributes;
}
