import React from 'react';
import Parcel from 'single-spa-react/parcel';
import PropTypes from 'prop-types';

const AddressFormParcel = ({ data, onSuccess, onClose }) => (
  <div id="parcel-dynamic-address-form">
    <Parcel
      flow="ADDRESS_FORM"
      mode="CREATE"
      data={data}
      onSuccess={(address) => onSuccess(address)}
      onClose={onClose}
      config={() => System.import('@microsite/web-location')}
    />
  </div>
);

AddressFormParcel.propTypes = {
  data: PropTypes.shape({}).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddressFormParcel;
