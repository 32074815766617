import { useCallback } from 'react';
import { useInvalidateQueriesAndRefetchInactive } from '@app/contexts/reactQuery/hooks/useInvalidateQueriesAndRefetchInactive';
import logger from '@app/logger';
import { usePerformBackendActionMutation } from '@app/modules/checkout/mutations';
import { QUERIES_NAMES } from '@app/modules/checkout/queries';
import { useQueryClient } from 'react-query';
import { updateCheckoutBackendActionStatus } from '@app/contexts/mainContext/actions/updateCheckoutBackendActionStatus';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useIsDesktop } from '@app/hooks/layout/useIsDesktop';

export const usePerformBackendActionDefinition = () => {
  const { mutateAsync: performBackendAction } = usePerformBackendActionMutation();
  const queryClient = useQueryClient();
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();
  const dispatch = useAppDispatch();
  const isDesktop = useIsDesktop();

  const performBackendActionDefinition = useCallback(
    async ({ request, response = {} }) => {
      const { path, method = 'PUT', body, timeout_in_millis: timeout } = request;
      const { response_type, prevent_reload } = response;

      try {
        updateCheckoutBackendActionStatus(dispatch, { isPerformingAction: true, error: false });
        const responseData = await performBackendAction({ path, method, body, timeout, isDesktop });
        logger.debug('[useBFFActionDefinitions][PERFORM_BACKEND_ACTION_SUCCESS]');
        switch (response_type) {
          // This means that we are able to render the checkout screen from this response and avoid a new getCheckout request
          case 'BFF':
            queryClient.setQueryData([QUERIES_NAMES.CHECKOUT, { isDesktop }], responseData);
            break;
          case 'CART':
          default:
            if (!prevent_reload) invalidateQueriesAndRefetchInactive([QUERIES_NAMES.CHECKOUT]);
            break;
        }
        updateCheckoutBackendActionStatus(dispatch, { success: true, isPerformingAction: false });
        return {
          success: true,
          data: responseData,
        };
      } catch (error) {
        updateCheckoutBackendActionStatus(dispatch, { error: true, isPerformingAction: false });
        logger.error('[useBFFActionDefinitions][PERFORM_BACKEND_ACTION_ERROR]', `error: ${error.message}`);
        return {
          success: false,
          data: error,
        };
      }
    },
    [dispatch, invalidateQueriesAndRefetchInactive, isDesktop, performBackendAction, queryClient],
  );

  return {
    performBackendActionDefinition,
  };
};
