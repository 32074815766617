import { getQueryParams } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import { useMemo } from 'react';

export const useMemoizedTracker = (tracker) => {
  const trackingOrigin = getQueryParams(window?.location, QUERY_PARAM.ORIGIN);
  const trackerContext = useMemo(() => ({ tracker, origin: trackingOrigin }), [tracker, trackingOrigin]);

  return trackerContext;
};
