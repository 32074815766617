import Button from '@pedidosya/web-fenix/atoms/Button';
import Modal from '@pedidosya/web-fenix/organisms/Modal';
import PropTypes from 'prop-types';
import React from 'react';

function ErrorDialog({
  show,
  title,
  description,
  icon,
  onFirstOptionAction,
  firstOptionLabel,
  onSecondaryOptionAction,
  secondaryOptionLabel,
}) {
  return (
    <Modal
      open={show}
      title={title}
      text={description}
      primaryButton={
        <Button fullWidth onClick={onFirstOptionAction} label={firstOptionLabel} size="large" hierarchy="primary" />
      }
      secondaryButton={
        onSecondaryOptionAction && secondaryOptionLabel ? (
          <Button
            fullWidth
            onClick={onSecondaryOptionAction}
            label={secondaryOptionLabel}
            size="large"
            hierarchy="secondary"
          />
        ) : null
      }
    >
      {icon}
    </Modal>
  );
}

ErrorDialog.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.node,
  firstOptionLabel: PropTypes.string.isRequired,
  onFirstOptionAction: PropTypes.func.isRequired,
  secondaryOptionLabel: PropTypes.string,
  onSecondaryOptionAction: PropTypes.func,
};

ErrorDialog.defaultProps = {
  show: false,
  firstOptionLabel: 'Cerrar',
};

export default ErrorDialog;
