import DeliveryDetailHeader from '../components/DeliveryDetailHeader';
import DeliveryDetailFooter from '../components/DeliveryDetailFooter';
import ItemList from '../components/ItemList';
import ShellBody from '@components/ShellBody';
import styled from 'styled-components';

export const StyledShellBody = styled(ShellBody)`
  padding-bottom: ${({ theme }) => theme.spacing(8)};
`;

export { DeliveryDetailFooter, DeliveryDetailHeader, ItemList };
