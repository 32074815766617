import IFrameWithSpinner from '@app/components/ResponsiveModal/components/IframeWithSpinner';
import { useIsDesktop } from '@hooks/layout/useIsDesktop';
import useOnce from '@hooks/useOnce';
import { ROUTES } from '@commons/services/routes.service';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  height: ${({ isDesktop }) => (isDesktop ? '100%' : '100vh')};
`;

function ChallengesFlow() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isDesktop = useIsDesktop();
  const url = state?.url;

  const handleChallengesFlowCallback = ({ data }) => {
    const { event } = data;
    if (event === 'submit') {
      navigate(ROUTES.CHALLENGES_FLOW_SUCCESS, { replace: true });
    }
  };

  useOnce(() => {
    window.addEventListener('message', handleChallengesFlowCallback, false);
    return () => window.removeEventListener('message', handleChallengesFlowCallback, false);
  });

  return (
    <Container isDesktop={isDesktop}>
      <IFrameWithSpinner src={url} />
    </Container>
  );
}

export default ChallengesFlow;
