import isString from '@commons/utils/string/isString';

const isBoolean = (x) => x === true || x === false;

const isObject = (obj) => typeof obj === 'object';

export const isVariation = (flagValue) => {
  const variation = flagValue ? flagValue.variation : false;

  if (isBoolean(variation)) {
    return variation;
  }

  if (isString(variation)) {
    return variation.includes('Variation');
  }

  if (isObject(variation)) {
    return variation;
  }

  return false;
};
