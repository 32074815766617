import Button from '@pedidosya/web-fenix/atoms/Button';
import PropTypes from 'prop-types';
import React from 'react';

const CallToAction = ({ disabled, hierarchy, label, onClick, state }) => {
  return (
    <Button
      size="large"
      type="button"
      fullWidth
      disabled={disabled}
      state={state}
      hierarchy={hierarchy}
      label={label}
      onClick={onClick}
    />
  );
};

CallToAction.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  state: PropTypes.string,
};

export default CallToAction;
