import logger from '@app/logger';
import { addDataToScope } from '@app/logger/sentry/addDataToScope';
import { CACHE_KEYS, sessionCache } from '@app/services/cache';
import startCheckout from '@app/services/checkout/startCheckout';
import { useCallback, useEffect, useState } from 'react';

export const useCheckoutSession = ({ basketId, checkoutType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkoutSession, setCheckoutSession] = useState(null);

  const loadCheckoutSession = useCallback(async () => {
    try {
      const savedCheckoutSession = sessionCache.get(CACHE_KEYS.CHECKOUT_SESSION);
      const shouldStartNewCheckout =
        basketId && (basketId !== savedCheckoutSession?.basketId || !savedCheckoutSession?.checkoutId);
      if (shouldStartNewCheckout) {
        const { id: checkoutId, purchaseId } = await startCheckout(basketId, checkoutType);

        const sentryData = [
          { key: 'checkoutId', value: checkoutId },
          { key: 'purchaseId', value: purchaseId },
        ];
        addDataToScope(sentryData);

        const newCheckoutSession = {
          basketId,
          checkoutType,
          checkoutId,
          purchaseId,
        };
        sessionCache.set(CACHE_KEYS.CHECKOUT_SESSION, newCheckoutSession);
        setCheckoutSession(newCheckoutSession);
      } else {
        setCheckoutSession(savedCheckoutSession);
      }

      setIsLoading(false);
    } catch (err) {
      logger.error('[loadCheckoutSession]', 'exception', err);
      setError(err);
      setIsLoading(false);
    }

    return null;
  }, [basketId, checkoutType]);

  // Checkout session initialization
  useEffect(() => {
    loadCheckoutSession();
  }, [loadCheckoutSession]);

  return {
    checkoutSession,
    isLoading,
    error,
  };
};
