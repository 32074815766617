import { TRACKING_EVENTS } from '@app/contexts/mainContext/tracker';
import { useComponentTracking } from '@hooks/tracking/useComponentTracking';

const useCheckoutTracking = ({ checkoutType, trackingData }) => {
  const actionEvents = [
    TRACKING_EVENTS.CHECKOUT_CLOSED(checkoutType),
    TRACKING_EVENTS.PAYMENT_METHOD_CLICKED(checkoutType),
    TRACKING_EVENTS.TRANSACTION_CLICKED(checkoutType),
    TRACKING_EVENTS.TRANSACTION_SUCCESS(checkoutType),
    TRACKING_EVENTS.TRANSACTION_FAILED(checkoutType),
    TRACKING_EVENTS.PURCHASE(checkoutType),
  ];
  const onMountEvent = TRACKING_EVENTS.CHECKOUT_LOADED(checkoutType);
  const [
    trackCloseClick,
    trackPaymentClick,
    trackTransactionClick,
    trackTransactionSuccess,
    trackTransactionFailed,
    trackPurchase,
  ] = useComponentTracking({
    commonTrackingData: trackingData,
    onMountEvent,
    actionEvents,
  });

  return {
    trackCloseClick,
    trackPaymentClick,
    trackTransactionClick,
    trackTransactionSuccess,
    trackTransactionFailed,
    trackPurchase,
  };
};

export default useCheckoutTracking;
