import { addQueryParamsToUrlString } from '@app/utils/queryString';
import { QUERY_PARAM } from '@commons/constants';

let BASENAME_PREFIX;
const CHECKOUT_BASENAME = '/checkout-mfe';

export const getBasename = () => {
  return BASENAME_PREFIX ? `${BASENAME_PREFIX}${CHECKOUT_BASENAME}` : CHECKOUT_BASENAME;
};

export const setBasenamePrefix = (prefix) => {
  BASENAME_PREFIX = prefix;
};

const getGlobalBasenamePrefix = () => {
  return BASENAME_PREFIX || '';
};

/**
 *
 * @param {String} route from the ROUTES object
 * @param {Object} pathParams key-value pairs to format the route
 * with the path params and the corresponding values
 * @returns {String} formatted route with params injected
 */
export const formatRouteWithParams = (route, pathParams = {}) =>
  Object.keys(pathParams).reduce(
    (currentRoute, currentParamKey) => currentRoute.replace(`:${currentParamKey}`, pathParams[currentParamKey]),
    route,
  );

export const ROUTES = {
  LANDING: '/',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PAYMENT_METHODS_USER_INSTRUMENTS: '/payment-methods-mfe',
  PAYMENT_INSTRUMENT: '/payment-instrument',
  PAYMENT_INSTRUMENT_SELECTION_SUCCESS: '/payment-instrument/selection',
  CONFIRMATION: '/confirmation',
  CHALLENGES_FLOW: '/challenges',
  CHALLENGES_FLOW_CALLBACK: '/challenges/callback',
  CHALLENGES_FLOW_SUCCESS: '/challenges/success',
  BILLING_FORM: '/billing',
  ORDER_TIMES: '/order-times',
  DELIVERY_ADDRESS: '/delivery-address',
  DELIVERY_ADDRESS_SELECTION_SUCCESS: '/delivery-address/selection',
  ADDRESS_FORM: '/address-form',
  DELIVERY_INFO: '/delivery-information/checkout/:checkoutId/:screenName',
  CUSTOM_TIP_FORM: '/custom-tip-form',

  // TODO: should be removed
  CARD_SECURITY_CODE: '/card-security-code',
};

/** URIS_FRONT identifies the full resource identifier for the pages
 * including the full basename and uses placeholders for path-params
 * in order to be used with format() utility
 * NOTE: URIS_FRONT and ROUTES keys are expected to be the same
 */
export const URIS_FRONT = {
  get LANDING() {
    return `${getBasename()}/`;
  },
  get TERMS_AND_CONDITIONS() {
    return `${getBasename()}/terms-and-conditions`;
  },
  get PAYMENT_INSTRUMENT_SELECTION_SUCCESS() {
    return `${getBasename()}/payment-instrument/selection`;
  },
  get CONFIRMATION() {
    return `${getBasename()}/confirmation`;
  },
  get CHALLENGES_FLOW_SUCCESS() {
    return `${getBasename()}/challenges/success`;
  },
  get CHALLENGES_FLOW_CALLBACK() {
    return `${getBasename()}/challenges/callback`;
  },
  get DELIVERY_ADDRESS_SELECTION_SUCCESS() {
    return `${getBasename()}/delivery-address/selection`;
  },

  // TODO: should be removed
  get CARD_SECURITY_CODE() {
    return `${getBasename()}/card-security-code`;
  },
};

export const EXTERNAL_URIS_FRONT = {
  get PAYMENT_METHODS_SECURITY_CODE() {
    return `${getGlobalBasenamePrefix()}/payment-methods-mfe/challenges/cvv-external`;
  },
  get PAYMENT_METHODS_CHALLENGES() {
    return `${getGlobalBasenamePrefix()}/payment-methods-mfe/challenges`;
  },
  get PAYMENT_METHODS_USER_INSTRUMENTS() {
    return `${getGlobalBasenamePrefix()}/payment-methods-mfe/user-instruments`;
  },
  get ORDER_ADDRESS() {
    return `${getGlobalBasenamePrefix()}/order-address-modification/checkout/{0}`;
  },
};

export function createPaymentMethodsMicrositePath(path, resourceId, successCallback, queryParams = {}) {
  if (!resourceId || !successCallback) {
    throw new Error(`missing any of required params: resourceId: ${resourceId}, successCallback: ${successCallback}`);
  }
  const params = {
    [QUERY_PARAM.PURCHASE_ID]: resourceId,
    [QUERY_PARAM.CALLBACKS.SUCCESS]: successCallback,
    ...queryParams,
  };
  const url = addQueryParamsToUrlString(path, params);
  return url;
}
