import { useCheckoutConfirmationStatus } from '@app/contexts/mainContext/selectors/useCheckoutConfirmationStatus';
import { useCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import { TRACKING_EVENTS } from '@app/contexts/mainContext/tracker';
import { useConfirmCheckout } from '@app/modules/checkout/hooks/useConfirmCheckout';
import { useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import CardErrorIcon from '@components/CardErrorIcon';
import ErrorDialog from '@components/ErrorDialog';
import Spinner from '@components/Spinner';
import { useGoBackToCheckoutSummary } from '@hooks/navigation/useGoBackToCheckoutSummary';
import { useComponentTracking } from '@hooks/tracking/useComponentTracking';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCheckoutContext } from '../../queries';
import { useCheckoutActionDefinitions } from '../CheckoutSummary/hooks/BFF/useCheckoutActionDefinitions';
import { useNavigateToUserPaymentInstruments } from '../CheckoutSummary/hooks/navigation/useNavigateToUserPaymentInstruments';

function ChallengesFlowSuccess() {
  const { navigateToUserPaymentInstruments } = useNavigateToUserPaymentInstruments();
  const { goBackToCheckoutSummary } = useGoBackToCheckoutSummary('CHALLENGE_SUCCESS_FLOW');
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const checkoutType = useCheckoutType();
  const { isLoading, isFetching, data } = useCheckoutContext();
  const { id: checkoutId, paymentDetails = [], trackingData, confirmationParams = {} } = data || {};
  const actionEvents = [
    TRACKING_EVENTS.TRANSACTION_SUCCESS(checkoutType),
    TRACKING_EVENTS.TRANSACTION_FAILED(checkoutType),
    TRACKING_EVENTS.PURCHASE(checkoutType),
  ];
  const [trackTransactionSuccess, trackTransactionFailed, trackPurchase] = useComponentTracking({
    commonTrackingData: trackingData,
    actionEvents,
  });
  const { confirmCheckout } = useConfirmCheckout();
  const { error: confirmError } = useCheckoutConfirmationStatus();
  const confirmationOptions = useMemo(
    () => ({
      errorCallback: () =>
        navigateToUserPaymentInstruments(checkoutId, {
          paymentDetails,
        }),
      secondaryErrorCallback: goBackToCheckoutSummary,
    }),
    [checkoutId, goBackToCheckoutSummary, navigateToUserPaymentInstruments, paymentDetails],
  );
  const { actionDefinitions } = useCheckoutActionDefinitions({
    trackingData,
    trackTransactionFailed,
    trackTransactionSuccess: (trackingData) => {
      trackTransactionSuccess(trackingData);
      trackPurchase(trackingData);
    },
    confirmationOptions,
    paymentDetails,
  });
  const { executeRecursiveActions } = useBFFActionsExecutor(actionDefinitions);

  const confirmAndHandleActions = useCallback(async () => {
    const confirmationResult = await confirmCheckout({
      trackTransactionFailed,
      trackTransactionSuccess: (trackingData) => {
        trackTransactionSuccess(trackingData);
        trackPurchase(trackingData);
      },
      version: confirmationParams?.version,
      isRepeatOrder: confirmationParams?.is_repeat_order,
      ...confirmationOptions,
    });

    if (confirmationResult?.actions)
      executeRecursiveActions(confirmationResult.actions, confirmationResult.fallbackFunction);
  }, [
    confirmCheckout,
    confirmationOptions,
    confirmationParams?.is_repeat_order,
    confirmationParams?.version,
    executeRecursiveActions,
    trackPurchase,
    trackTransactionFailed,
    trackTransactionSuccess,
  ]);

  useEffect(() => {
    if (!hasEffectRun && !isLoading && !isFetching) {
      setHasEffectRun(true);
      confirmAndHandleActions();
    }
  }, [confirmAndHandleActions, hasEffectRun, isFetching, isLoading]);

  return (
    <>
      <Spinner />
      <ErrorDialog
        show={confirmError?.isError}
        title={confirmError?.data?.title || 'No pudimos procesar tu pago'}
        description={confirmError?.data?.description || '  '}
        icon={confirmError?.data?.icon ? <CardErrorIcon icon={confirmError?.data?.icon} /> : null}
        firstOptionLabel={confirmError?.data?.firstOptionLabel}
        onFirstOptionAction={confirmError?.data?.onFirstOptionAction || goBackToCheckoutSummary}
        secondaryOptionLabel={confirmError?.data?.secondaryOptionLabel}
        onSecondaryOptionAction={confirmError?.data?.onSecondaryOptionAction}
      />
    </>
  );
}

export default ChallengesFlowSuccess;
