import styled from 'styled-components';

const BottomSheetBody = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  display: flex;
  flex-direction: column;
`;

export default BottomSheetBody;
