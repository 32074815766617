import { useEffect } from 'react';
import { addResourceHint } from '@app/utils/browser/addResourceHint';
import logger from '@app/logger';

export const useMicrositesPrefetch = (micrositesIds = []) => {
  useEffect(() => {
    if (micrositesIds?.length) {
      try {
        micrositesIds.forEach((micrositeId) => {
          // eslint-disable-next-line no-undef
          addResourceHint({ href: System.resolve(`@microsite/${micrositeId}`), crossOrigin: true });
        });
      } catch (err) {
        logger.error(
          `[useMicrositesPrefetch]', 'fail prefetching microsites ${JSON.stringify(micrositesIds)}, error: ${
            err.message
          }`,
        );
      }
    }
  }, [micrositesIds, micrositesIds.length]);
};
