import ServiceResponse from '@commons/services/models/ServiceResponse';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';
import getAxiosInstance from '../axios';
import { URLS } from './constants';

export default async function getPublicEnvironment(axios = getAxiosInstance()) {
  try {
    const { data } = await axios.get(URLS.ENV);
    return new ServiceResponse({ data });
  } catch (error) {
    return new ServiceResponse({ error: parseAxiosError(error).message });
  }
}
