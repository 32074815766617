import React from 'react';
import Skeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import { Paragraph, Header } from './components';

const TermsAndConditionsSkeleton = () => {
  return (
    <>
      <Header>
        <Skeleton shape="square" height="shape-size-action-10" width="shape-size-action-70" />
      </Header>
      <Paragraph>
        <Skeleton shape="square" height="shape-size-action-10" width="" />
        <Skeleton shape="square" height="shape-size-action-10" width="" />
        <Skeleton shape="square" height="shape-size-action-10" width="" />
        <Skeleton shape="square" height="shape-size-action-10" width="" />
        <Skeleton shape="square" height="shape-size-action-10" width="" />
      </Paragraph>
      <Paragraph>
        <Skeleton shape="square" height="shape-size-action-10" width="" />
        <Skeleton shape="square" height="shape-size-action-10" width="" />
        <Skeleton shape="square" height="shape-size-action-10" width="" />
        <Skeleton shape="square" height="shape-size-action-10" width="" />
        <Skeleton shape="square" height="shape-size-action-10" width="" />
      </Paragraph>
    </>
  );
};

TermsAndConditionsSkeleton.propTypes = {};

export default TermsAndConditionsSkeleton;
