import styled from 'styled-components';

const BottomSheetContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(130%);
  transition-timing-function: ease-in;
  transition: 0.3s;
  max-height: 100%;
`;

export default BottomSheetContent;
