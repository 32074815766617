import { useOpenGlobalModal } from '@app/contexts/GlobalModal/hooks/useOpenGlobalModal';
import { useAppTracker } from '@app/contexts/mainContext/selectors/useAppTracker';
import { BFF_ACTION_TYPES } from '@app/modules/checkout/constants/bff';
import { useExternalRouterNavigation } from '@checkout-ui/backend-driven';
import { useMemo } from 'react';
import { usePerformBackendActionDefinition } from './usePerformBackendActionDefinition';
import transformEvent from '../tracking/transformEvent';

export const useBFFActionDefinitions = () => {
  const { tracker } = useAppTracker();
  const { externalRouterNavigation } = useExternalRouterNavigation();
  const { performBackendActionDefinition } = usePerformBackendActionDefinition();
  const openGlobalModal = useOpenGlobalModal();

  const actionDefinitions = useMemo(
    () => ({
      [BFF_ACTION_TYPES.TRACKING]: ({ event, attributes }) => {
        tracker.track(transformEvent(event), attributes);
      },
      [BFF_ACTION_TYPES.EXTERNAL_NAVIGATE]: (payload) => {
        externalRouterNavigation(payload?.redirect_url, { queryParams: payload?.query_params });
      },
      [BFF_ACTION_TYPES.PERFORM_BACKEND_ACTION]: async ({ request, response = {} }) => {
        return await performBackendActionDefinition({ request, response });
      },
      [BFF_ACTION_TYPES.OPEN_MODAL]: (modalProps) => {
        openGlobalModal(modalProps);
      },
    }),
    [tracker, externalRouterNavigation, performBackendActionDefinition, openGlobalModal],
  );

  return { actionDefinitions };
};
