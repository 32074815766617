import { PROD_ENVIRONMENT } from '@commons/constants/environments';
import { BrowserClient, Hub } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { SENTRY_CONFIG } from './constants';

let sentryHubInstance;

export const initSentry = ({ environment }) => {
  try {
    const sampleRate = environment && environment === PROD_ENVIRONMENT ? 0.2 : 1;

    const client = new BrowserClient({
      dsn: SENTRY_CONFIG.dsn,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', /^\//],
          beforeNavigate: (context) => ({
            ...context,
          }),
        }),
      ],
      environment,
      release: `${APP_NAME}@${APP_VERSION}`,
      tracesSampleRate: sampleRate,
      sampleRate,
    });
    sentryHubInstance = new Hub(client);
    return sentryHubInstance;
  } catch (error) {
    // Log to console.error because at this point the Sentry instance did not work
    console.error(error);
    throw error;
  }
};

export default sentryHubInstance;
