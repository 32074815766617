import logger from '@app/logger';
import defaultAxios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { isProduction } from '@utils/environment';
import CardTokenizationError from '../../errors/cardTokenizationError';

const HTTP_STATUS_CODE_BAD_REQUEST = 400;

const SPREEDLY_URL = 'https://core.spreedly.com';

const SPREEDLY_KEY_DEV = 'NTmDXmQgoLjYAdoX8iUensIsBVx'; // gitleaks:allow
const SPREEDLY_KEY_PROD = 'I8mmcHW3PLQk5V2nJG7Jw9X6W3v'; // gitleaks:allow

const getPublicKey = () => (isProduction() ? SPREEDLY_KEY_PROD : SPREEDLY_KEY_DEV);

const parseSpreedlyErrors = (response) => {
  const errors = response?.data?.errors || [];
  const errorMessages = errors.map((e) => e.message);
  const errorMsg = errorMessages.join('|');
  return errorMsg || JSON.stringify(response);
};

const validateSpreedlyResponse = (response) => {
  if (response?.status >= HTTP_STATUS_CODE_BAD_REQUEST || response?.data?.status >= HTTP_STATUS_CODE_BAD_REQUEST) {
    const errorMessage = parseSpreedlyErrors(response);
    logger.error('[createTokenForNewCard]', 'spreedly tokenization error', errorMessage);
    throw new CardTokenizationError(`spreedly tokenization error: ${errorMessage}`);
  }
};

export const createTokenForNewCard = async ({
  cardHolderName,
  cardNumber,
  cardSecurityCode,
  cardExpirationMonth,
  cardExpirationYear,
  axios = defaultAxios,
}) => {
  const url = new URL(`/v1/payment_methods.js`, SPREEDLY_URL);
  url.searchParams.set('environment_key', getPublicKey());
  url.searchParams.set('kind', 'credit_card');
  url.searchParams.set('full_name', cardHolderName);
  url.searchParams.set('number', cardNumber);
  url.searchParams.set('verification_value', cardSecurityCode);
  url.searchParams.set('month', cardExpirationMonth);
  url.searchParams.set('year', cardExpirationYear);

  // TODO: Forzar el campo "retained" a false para tarjetas nuevas
  // (puede que el default sea false pero por las dudas)

  const response = await axios({
    url: url.toString(),
    adapter: jsonpAdapter,
  });

  validateSpreedlyResponse(response);

  return response;
};

export const createTokenForExistingCard = async ({ spreedlyCardId, cardSecurityCode, axios = defaultAxios }) => {
  const url = new URL(`/v1/payment_methods/${spreedlyCardId}/recache.js`, SPREEDLY_URL);
  url.searchParams.set('environment_key', getPublicKey());
  url.searchParams.set('kind', 'credit_card');
  url.searchParams.set('verification_value', cardSecurityCode);

  const response = await axios({
    url: url.toString(),
    adapter: jsonpAdapter,
  });

  validateSpreedlyResponse(response);

  return response;
};
