import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';
import { addQueryParamsToUrlString } from '@app/utils/queryString';

async function getCustomTipScreen(options = {}, axios = getAxiosInstance()) {
  try {
    const { isDesktop } = options;
    const url = addQueryParamsToUrlString(URLS.GET_CUSTOM_TIP_SCREEN, {
      isDesktop: isDesktop ? 'true' : 'false',
    });

    const { data } = await axios.get(url, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    return data;
  } catch (error) {
    logger.error('[services][getCustomTipScreen]', parseAxiosError(error).message);
    throw error;
  }
}

export default getCustomTipScreen;
