import { BREAKPOINTS, DEVICE_TYPE } from './constants';

const isMobile = (width) => {
  if (!width) return false;
  return width < BREAKPOINTS.MOBILE;
};

const isTablet = (width) => {
  if (!width) return false;
  return BREAKPOINTS.MOBILE <= width && width <= BREAKPOINTS.DESKTOP;
};

const isDesktop = (width) => {
  if (!width) return false;
  return width > BREAKPOINTS.DESKTOP;
};

const getDevice = () => {
  const width = window.innerWidth;

  let deviceType;

  if (isMobile(width)) deviceType = DEVICE_TYPE.MOBILE;

  if (isTablet(width)) deviceType = DEVICE_TYPE.TABLET;

  if (isDesktop(width)) deviceType = DEVICE_TYPE.DESKTOP;

  return deviceType;
};

export default getDevice;
