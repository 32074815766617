import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.space('spacing-layout-large')}`};
`;

export const Paragraph = styled.div`
  margin-bottom: ${({ theme }) => theme.space('spacing-layout-medium')};
  width: 100%;

  > * {
    margin-bottom: ${({ theme }) => theme.space('spacing-layout-xsmall')};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.space('spacing-layout-large')};
  width: 100%;
  display: flex;
  justify-content: center;
`;
