import { getSentryInstance } from './instance';
import { withBuffer } from './withBuffer';

function setTag(tagKey, tagValue) {
  const instance = getSentryInstance();

  instance.configureScope((scope) => {
    scope.setTag(tagKey, tagValue);
  });
}

export const setTagInScope = withBuffer(setTag, getSentryInstance);

function addData(data) {
  data.forEach(({ key, value }) => {
    if (value) {
      setTagInScope(key, value);
    }
  });
}

export const addDataToScope = withBuffer(addData, getSentryInstance);
