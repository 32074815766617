import React from 'react';
import PropTypes from 'prop-types';
import Parcel from 'single-spa-react/parcel';

const DeliveryInfoParcel = ({ checkoutId, ...rest }) => (
  <Parcel
    config={() => System.import('@microsite/delivery-information-parcel')}
    fwfPromise={() => {}}
    resourceType="checkout"
    resourceId={checkoutId}
    {...rest}
  />
);

DeliveryInfoParcel.propTypes = {
  flow: PropTypes.string,
  checkoutId: PropTypes.string.isRequired,
  header: PropTypes.node,
  footerNavigationStyle: PropTypes.shape({}),
};

export default DeliveryInfoParcel;
