import React from 'react';
import Skeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import { StyledBottomNavigation, StyledBox } from './commons';

const DonationSkeleton = () => {
  return (
    <div aria-label="Cargando Checkout Summary - Donation">
      <StyledBox padding="space-08">
        <StyledBox height="space-22" gap="space-15" align="center">
          <Skeleton shape="square" height="shape-size-action-7" width="shape-size-action-7" />
          <Skeleton shape="square" height="shape-size-action-5" width="shape-size-action-46" />
        </StyledBox>
      </StyledBox>

      <StyledBox direction="column" padding="space-08">
        <Skeleton shape="square" height="" width="" outlined>
          <StyledBox padding="space-10" gap="space-10" align="center">
            <Skeleton shape="square" height="shape-size-action-13" width="shape-size-action-13" />
            <StyledBox flex="1">
              <Skeleton shape="square" height="shape-size-action-5" width="" />
            </StyledBox>
          </StyledBox>
        </Skeleton>
      </StyledBox>

      <StyledBox direction="column" padding="space-08">
        <StyledBox direction="column" gap="space-9">
          <Skeleton shape="square" height="shape-size-action-5" width="shape-size-action-50" />
          <Skeleton shape="square" height="" width="" outlined>
            <StyledBox padding="space-10" gap="space-4" align="flex-start">
              <Skeleton shape="square" height="shape-size-action-5" width="shape-size-action-6" />
              <StyledBox flex="1">
                <Skeleton shape="square" height="shape-size-action-5" width="shape-size-action-30" />
              </StyledBox>
              <Skeleton shape="square" height="shape-size-action-4" width="shape-size-action-4" />
            </StyledBox>
          </Skeleton>
        </StyledBox>
      </StyledBox>

      <StyledBox direction="column" padding="space-08">
        <StyledBox direction="column" gap="space-9">
          <Skeleton shape="square" height="shape-size-action-4" width="shape-size-action-30" />
          <Skeleton shape="square" height="" width="" outlined>
            <StyledBox padding="space-10">
              <StyledBox flex="1" align="center">
                <Skeleton shape="square" height="shape-size-action-3" width="shape-size-action-24" />
              </StyledBox>
              <Skeleton shape="square" height="shape-size-action-4" width="shape-size-action-4" />
            </StyledBox>
          </Skeleton>
        </StyledBox>
      </StyledBox>

      <StyledBottomNavigation>
        <Skeleton shape="square" height="shape-size-action-12" width="" />
      </StyledBottomNavigation>
    </div>
  );
};

export default DonationSkeleton;
