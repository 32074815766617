function isAndroid() {
  return Boolean(window?.NavigationWebInterface);
}

function isIos() {
  return Boolean(
    window?.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.NavigationWebInterface,
  );
}

export function goBack() {
  if (isAndroid()) {
    window?.NavigationWebInterface.onBack();
  } else if (isIos()) {
    window.webkit.messageHandlers.NavigationWebInterface.postMessage({});
  }
}

export default {
  goBack,
};
