import { useState, useContext, useEffect } from 'react';
import FwfFlagsContext from '.';
import { isVariation } from '@commons/fwf/utils';
import logger from '@app/logger';

export const useFlagVariation = (flagKey) => {
  const { fwf, ...allFlagValues } = useContext(FwfFlagsContext);
  const [flagValue, setFlagValue] = useState(allFlagValues[flagKey] || false);

  useEffect(() => {
    if (fwf) {
      fwf
        .getFlag(flagKey)
        .then((value) => {
          setFlagValue(isVariation(value));
        })
        .catch((error) => {
          logger.error('FWF_GETTING_FLAG_ERROR', {
            hook: 'useFlagVariation',
            flagKey,
            error,
          });
        });
    }
  }, [fwf, flagKey]);

  return flagValue;
};

export const useFlag = (flagKey) => {
  const { fwf, ...allFlagValues } = useContext(FwfFlagsContext);
  const [flagValue, setFlagValue] = useState(allFlagValues[flagKey]);

  useEffect(() => {
    if (fwf) {
      fwf
        .getFlag(flagKey)
        .then((value) => {
          setFlagValue(value.variation);
        })
        .catch((error) => {
          logger.error('FWF_GETTING_FLAG_ERROR', {
            hook: 'useFlag',
            flagKey,
            error,
          });
        });
    }
  }, [fwf, flagKey]);

  return flagValue;
};
