import styled, { css } from 'styled-components';

const OptionRowRadioContainer = styled.div`
  display: flex;
  min-height: 53px;
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid #dae0e5ff;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export default OptionRowRadioContainer;
