import { Card, FadeIn } from '@appscore/web-components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Success from './Success';

const CardContainer = styled.div`
  position: absolute;
  transform: translate(0, 50px);
`;

function FullScreenSuccess({ children }) {
  return (
    <>
      <Success />
      <CardContainer>
        <FadeIn delay={2000}>
          <Card>{children}</Card>
        </FadeIn>
      </CardContainer>
    </>
  );
}

FullScreenSuccess.propTypes = { children: PropTypes.node };

export default FullScreenSuccess;
