import { updateCheckoutType } from '@app/contexts/mainContext/actions/updateCheckoutType';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import { getQueryParams } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import { useEffect } from 'react';

export const useCheckoutTypeUpdate = () => {
  const dispatch = useAppDispatch();
  const checkoutType = useCheckoutType();

  const checkoutTypeFromQuery = getQueryParams(window?.location, QUERY_PARAM.CHECKOUT.TYPE);

  useEffect(() => {
    if (checkoutTypeFromQuery && checkoutTypeFromQuery !== checkoutType) {
      updateCheckoutType(dispatch, checkoutTypeFromQuery);
    }
  }, [dispatch, checkoutTypeFromQuery, checkoutType]);
};
