import { useHistoryLengthBeforeNavigation } from '@app/contexts/mainContext/selectors/useHistoryLengthBeforeNavigation';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavigateBack } from './useNavigateBack';
import logger from '@app/logger';

export const useGoBackToCheckoutSummary = (flow = '') => {
  const { navigateBack } = useNavigateBack();
  const navigate = useNavigate();
  const { length: prevHistoryLength } = useHistoryLengthBeforeNavigation();

  const goBackToCheckoutSummary = useCallback(() => {
    const backDelta = prevHistoryLength - window.history.length;

    if (backDelta >= 0) {
      logger.error(`[${flow}][INVALID_BACK_DELTA]`, 'invalid backDelta:', backDelta);
      // In these cases at least go back
      navigateBack();
    } else {
      navigate(backDelta);
    }
  }, [flow, navigate, navigateBack, prevHistoryLength]);

  return { goBackToCheckoutSummary };
};
