import { CACHE_KEYS, sessionCache } from '../../../services/cache';
import { ACTION_TYPES } from '../actionTypes';

// TODO: Check if we are missing any keys to invalidate
export const invalidateSessionCache = (dispatch) => {
  sessionCache.remove(CACHE_KEYS.MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED);
  // TODO: delete this and use CHECKOUT_SESSION instead
  sessionCache.remove(CACHE_KEYS.CHECKOUT_TYPE);
  sessionCache.remove(CACHE_KEYS.HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS);
  sessionCache.remove(CACHE_KEYS.CHECKOUT_SESSION);
  dispatch({ type: ACTION_TYPES.INVALIDATE_SESSION_CACHE });
};
