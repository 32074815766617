import { Confirm } from '@app/components/Confirm';
import { useRedirectToPage } from '@hooks/navigation/useRedirectToPage';
import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import checkoutNativeBridge from '@app/utils/checkoutNativeBridge';

function Confirmation() {
  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');
  const subtitle = searchParams.get('subtitle');
  const redirectPage = searchParams.get('redirectPage');
  const orderId = searchParams.get('orderId');
  const country = searchParams.get('country');
  const redesign = searchParams.get('redesign');
  const { redirectToPage } = useRedirectToPage();

  const onAnimationEnd = useCallback(() => {
    // Comes from checkout redesign screen
    if (redesign === 'true') {
      checkoutNativeBridge.closeWebview({ canRefreshSummary: true });
    } else {
      redirectToPage(redirectPage, { orderId, country, replace: true });
    }
  }, [country, orderId, redesign, redirectPage, redirectToPage]);

  return <Confirm title={title} subtitle={subtitle} onAnimationEnd={onAnimationEnd} />;
}

export default Confirmation;
