import { useCallback } from 'react';

export const useHardNavigate = () => {
  const hardNavigate = useCallback((url, { replace = false } = {}) => {
    if (replace) {
      window?.location?.replace(url);
    } else {
      window?.location?.assign(url);
    }
  }, []);

  return hardNavigate;
};
