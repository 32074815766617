import { useCallback } from 'react';
import { STATE_KEYS } from '../constants';
import { useAppState } from '../context';

export const useCheckoutType = () => {
  const [state] = useAppState();
  return state[STATE_KEYS.CHECKOUT_TYPE];
};

export const useIsCheckoutType = () => {
  const checkoutType = useCheckoutType();

  return useCallback(
    (type) => {
      return checkoutType === type;
    },
    [checkoutType],
  );
};
