import React from 'react';
import Spinner from '@components/Spinner';
import useOnce from '@hooks/useOnce';

function DeliveryAddressSelectionSuccess() {
  useOnce(() => {
    window.parent.postMessage({ event: 'submit' }, '*');
  });

  return <Spinner />;
}

export default DeliveryAddressSelectionSuccess;
