import { BrandError } from '@app/components/Error';
import FixedHeader from '@app/components/FixedHeader';
import GenericButton from '@app/components/GenericButton';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTermsAndConditionsDetail } from '../../queries';
import TermsAndConditionsSkeleton from './TermsAndConditionsSkeleton';
import { Container } from './components';

function TermsAndConditionsDetail() {
  const { navigateBack } = useNavigateBack();
  const { state } = useLocation();
  const { data, isLoading, isError, refetch } = useTermsAndConditionsDetail(state?.url);

  if (isError)
    return (
      <BrandError
        onClick={refetch}
        secondaryActionLabel="Volver"
        secondaryActionClick={() => {
          navigateBack();
        }}
      />
    );

  return (
    <>
      <FixedHeader
        action={
          <GenericButton title="close" onClick={() => navigateBack()}>
            <ChevronLeft size="medium" />
          </GenericButton>
        }
      />
      <Container>
        <Typography token="font-label-midcontrast-medium">
          {isLoading ? <TermsAndConditionsSkeleton /> : <div dangerouslySetInnerHTML={{ __html: data }} />}
        </Typography>
      </Container>
    </>
  );
}

export default TermsAndConditionsDetail;
