import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export default Container;
