import confirmCheckout from '@app/services/checkout/confirmCheckout';
import performBackendAction from '@app/services/checkout/performBackendAction';
import updateAddress from '@app/services/checkout/updateAddress';
import updatePaymentInstruments from '@app/services/paymentMethod/updatePaymentInstruments';
import { useMutation } from 'react-query';

export const MUTATIONS_NAMES = {
  SET_CHECKOUT_INSTRUMENT: 'SET_CHECKOUT_INSTRUMENT',
  CONFIRM_CHECKOUT: 'CONFIRM_CHECKOUT',
  SET_CHECKOUT_ADDRESS: 'SET_CHECKOUT_ADDRESS',
  PERFORM_BACKEND_ACTION: 'PERFORM_BACKEND_ACTION',
};

export const useSetCheckoutInstruments = (selectedInstruments) =>
  useMutation([MUTATIONS_NAMES.SET_CHECKOUT_INSTRUMENT, selectedInstruments], () =>
    updatePaymentInstruments(selectedInstruments),
  );

export const useConfirmCheckoutMutation = (checkoutId) =>
  useMutation([MUTATIONS_NAMES.CONFIRM_CHECKOUT, checkoutId], ({ version, isRepeatOrder }) =>
    confirmCheckout({ version, isRepeatOrder }),
  );

export const useUpdateAddress = () =>
  useMutation([MUTATIONS_NAMES.SET_CHECKOUT_ADDRESS], (address) => updateAddress(address));

export const usePerformBackendActionMutation = () =>
  useMutation([MUTATIONS_NAMES.PERFORM_BACKEND_ACTION], (request) => performBackendAction(request));
