import { useIsCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import { getQueryParams } from '@app/utils/domRouter';
import { globalStyleSheet as FirstCutStyles } from '@appscore/web-components';
import { CHECKOUT_TYPES, QUERY_PARAM } from '@commons/constants';
import FenixStyles from '@pedidosya/web-fenix/theme/GlobalStyles';
import React from 'react';
import CheckoutGlobalStyleSheet from './CheckoutGlobalStyleSheet';

const StyleSheet = ({ isFenix }) => (isFenix ? <FenixStyles /> : <FirstCutStyles />);

export const RootGlobalStyleSheet = () => {
  const checkoutType = getQueryParams(window?.location, QUERY_PARAM.CHECKOUT.TYPE);

  return (
    <>
      <CheckoutGlobalStyleSheet />
      <StyleSheet isFenix={checkoutType !== CHECKOUT_TYPES.CART_LEGACY} />
    </>
  );
};

export const AppGlobalStyleSheet = () => {
  const isCheckoutType = useIsCheckoutType();

  return (
    <>
      <CheckoutGlobalStyleSheet />
      <StyleSheet isFenix={!isCheckoutType(CHECKOUT_TYPES.CART_LEGACY)} />
    </>
  );
};
