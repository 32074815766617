import axios from 'axios';
import axiosRetry from 'axios-retry';
import logger from '@app/logger';
import isRetryAllowed from 'is-retry-allowed';

let instance = null;

function isNetworkError(error) {
  return (
    !error.response &&
    Boolean(error.code) && // Prevents retrying cancelled requests
    error.code !== 'ECONNABORTED' && // Prevents retrying timed out requests
    isRetryAllowed(error) // Prevents retrying unsafe errors
  );
}

function errorResponseHandler(error) {
  try {
    const { message } = error;
    const data = error.response?.data || {};
    const url = error.config?.url;
    const status = error.response?.status || 'unknown';

    if (isNetworkError(error)) {
      return Promise.reject(error);
    }

    const dataToLog = {
      status,
      url,
      message,
      data: JSON.stringify(data),
    };

    logger.error(`[AXIOS_ERROR_INTERCEPTOR]`, dataToLog);

    return Promise.reject(error);
  } catch {
    return Promise.reject(error);
  }
}

const errorInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use((response) => response, errorResponseHandler);
};

const retryInterceptor = (axiosInstance) =>
  axiosRetry(axiosInstance, {
    retries: 3,
    // only retries on internet connection errors
    retryCondition: isNetworkError,
    retryDelay: axiosRetry.exponentialDelay,
  });

export function prefixAxiosRequestPath(prefix) {
  const axiosInstance = getInstance();

  axiosInstance.interceptors.request.use(function (config) {
    if (!prefix || config.url.includes(prefix)) {
      return config;
    }

    config.url = `${prefix}${config.url}`;
    return config;
  });
}

export function initialize(props) {
  instance = (props && axios.create(props)) || axios.create();

  retryInterceptor(instance);
  errorInterceptor(instance);

  return instance;
}

const getInstance = (props) => instance || initialize(props);

export default getInstance;
