import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { initializeInstance as initializeFWF } from '@commons/fwf/instance';
import logger from '@app/logger';

const FwfFlagsContext = React.createContext();

export const FwfFlagsProvider = ({ fwfPromise, contextData, children }) => {
  const [fwf, setFwf] = useState(null);

  useEffect(() => {
    if (contextData) {
      const { userId, country, deviceInfo, device } = contextData;
      initializeFWF(fwfPromise, {
        userData: {
          userId: userId,
        },
        contextData: {
          country: country?.shortName,
          device: deviceInfo?.type || device,
          os: deviceInfo?.os,
          appVersion: deviceInfo?.appVersion,
        },
      })
        .then(setFwf)
        .catch((error) => {
          logger.error('FWF_INITIALIZATION_ERROR', error.message);
        });
    }
  }, [fwfPromise, contextData]);

  return <FwfFlagsContext.Provider value={{ fwf }}>{children}</FwfFlagsContext.Provider>;
};

FwfFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fwfPromise: PropTypes.instanceOf(Promise),
};

export default FwfFlagsContext;
