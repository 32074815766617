import styled from 'styled-components';

const IconAndMessageWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  min-height: -webkit-fill-available;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export default IconAndMessageWrapper;
