import ButtonLoader from '@pedidosya/web-fenix/animations/ButtonLoader';
import React from 'react';
import styled from 'styled-components';

const ButtonContent = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${({ theme }) => theme.space('shape-size-action-10')};
  gap: ${({ theme }) => `0 ${theme.space('spacing-component-medium')}`};
`;

const ConfirmButtonLoader = () => (
  <ButtonContent>
    <ButtonLoader color="icon-color-action-loading-inverted" />
  </ButtonContent>
);

export default ConfirmButtonLoader;
