import { useIsAndroid, useIsIos } from '@app/contexts/mainContext/deviceInfo/hooks';
import { useInvalidateQueriesAndRefetchInactive } from '@app/contexts/reactQuery/hooks/useInvalidateQueriesAndRefetchInactive';
import logger from '@app/logger';
import { parsePaymentInstrument } from '@app/modules/checkout/pages/CheckoutSummary/utils/parsePaymentInstrument';
import { QUERIES_NAMES } from '@app/modules/checkout/queries';
import { QUERY_PARAM } from '@commons/constants';
import { ROUTES } from '@commons/services/routes.service';
import stringify from '@commons/utils/object/stringify';
import { addQueryParamsToUrlString } from '@utils/queryString';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const PAYMENT_INSTRUMENT_EVENT = 'payment_instrument_changed';

export const useNativeUserPaymentInstruments = () => {
  const navigate = useNavigate();
  const isIos = useIsIos();
  const isAndroid = useIsAndroid();
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();

  const openNativeToUserPaymentInstruments = useCallback(
    (resourceId, { paymentDetails, instrumentIds }) => {
      try {
        const handlePaymentInstrument = (event) => {
          const { paymentInstrumentSelected } = event.detail;
          if (paymentInstrumentSelected === null) {
            return invalidateQueriesAndRefetchInactive([QUERIES_NAMES.CHECKOUT]);
          }

          const successPath = ROUTES.PAYMENT_INSTRUMENT_SELECTION_SUCCESS;
          const queryParams = {
            [QUERY_PARAM.SELECTED_INSTRUMENTS]: stringify({ id: paymentInstrumentSelected }),
          };
          const url = addQueryParamsToUrlString(successPath, queryParams);
          navigate(url);
        };

        const selectedInstruments = parsePaymentInstrument({ paymentDetails, instrumentIds });
        const paymentInstrumentData = {
          purchaseId: resourceId,
          instrumentSelected: selectedInstruments.length === 0 ? '' : selectedInstruments[0].id,
        };

        // Add listener to get info from native event related to payment instrument selected
        window.addEventListener(PAYMENT_INSTRUMENT_EVENT, handlePaymentInstrument);

        // Interface to open deeplink on Loyalty flow
        if (isIos) {
          window.webkit.messageHandlers.ListenEventsInterface.postMessage({
            event: 'open_payment_instrument_deeplink',
            payload: paymentInstrumentData,
          });
        }

        if (isAndroid) {
          window.ListenEventsInterface.openPaymentInstrumentDeeplink(stringify(paymentInstrumentData));
        }

        return () => window.removeEventListener(PAYMENT_INSTRUMENT_EVENT, handlePaymentInstrument);
      } catch (e) {
        logger.error('[useNativeUserPaymentInstruments][ERROR]', e.message);
      }
    },
    [invalidateQueriesAndRefetchInactive, isAndroid, isIos, navigate],
  );

  return { openNativeToUserPaymentInstruments };
};
