import styled from 'styled-components';

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  margin: 15px ${({ theme }) => theme.constants.lateralSpace} 15px 15px;
  width: 86%;
  flex-flow: column;
  text-align: left;
`;

export default Body;
