import querystring from 'query-string';
import getNestedProp from '@commons/utils/object/getNestedProp';

function getQuery(location) {
  return location.search;
}
function getQueryAsObject(location) {
  return querystring.parse(getQuery(location));
}

export function getQueryParams(location, paramKey) {
  return getNestedProp(getQueryAsObject(location), paramKey);
}

export function getAllQueryParams(location, paramKey) {
  const params = new URLSearchParams(getQuery(location));
  return params.getAll(paramKey);
}

export function getAndParseAllQueryParams(location, paramKey) {
  return getAllQueryParams(location, paramKey).map((param) => JSON.parse(decodeURI(param)));
}
