import logger from '@app/logger';
import { CHALLENGES_CODES } from '@commons/constants/challengesCodes';
import { useNavigateToChallenge } from '@hooks/navigation/useNavigateToChallenge';
import { useCallback } from 'react';

export const useHandleChallengeConfirmation = () => {
  const navigateToChallenge = useNavigateToChallenge();

  const handleChallengeConfirmation = useCallback(
    (challengeCode, payload) => {
      logger.info(`[useHandleChallengeConfirmation] Handling '${challengeCode}' challenge`);
      switch (challengeCode) {
        case CHALLENGES_CODES.CVV_REQUIRED:
          navigateToChallenge(CHALLENGES_CODES.CVV_REQUIRED);
          break;
        default:
          throw new Error('[useHandleChallengeConfirmation] Unknown challenge code');
      }
    },
    [navigateToChallenge],
  );

  return handleChallengeConfirmation;
};
