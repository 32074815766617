import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Image from '@pedidosya/web-fenix/foundations/Image';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CheckoutPaymentMethodContainer from './components/CheckoutPaymentMethodContainer';

const SelectedPaymentMethod = styled.div`
  flex-grow: 1;
  margin: ${({ theme }) => `0 ${theme.space('spacing-component-medium')}`};
`;

SelectedPaymentMethod.Title = styled.div`
  line-height: ${({ theme }) => `${theme.space('spacing-component-2xlarge')}`};
`;

SelectedPaymentMethod.Subtitle = styled.div`
  margin-top: 5px;
  line-height: ${({ theme }) => `${theme.space('spacing-component-xlarge')}`};
`;

const CardLogo = styled.div`
  align-self: flex-start;
`;

function CheckoutPaymentMethod({ selectedPaymentMethod, onClick }) {
  return (
    <CheckoutPaymentMethodContainer header={selectedPaymentMethod.header} onClick={onClick}>
      <CardLogo>
        <Image alt="card-logo" src={selectedPaymentMethod.icon} height={8} width={22} />
      </CardLogo>
      <SelectedPaymentMethod>
        <SelectedPaymentMethod.Title>
          <Typography token="font-body-midcontrast-sentence-medium" color="text-color-primary">
            {selectedPaymentMethod.title}
          </Typography>
        </SelectedPaymentMethod.Title>
        {selectedPaymentMethod.subtitle ? (
          <SelectedPaymentMethod.Subtitle>
            <Typography token="font-body-midcontrast-sentence-small" color="text-color-tertiary">
              {selectedPaymentMethod.subtitle}
            </Typography>
          </SelectedPaymentMethod.Subtitle>
        ) : null}
      </SelectedPaymentMethod>
      <ChevronRight size="small" />
    </CheckoutPaymentMethodContainer>
  );
}

CheckoutPaymentMethod.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedPaymentMethod: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.string,
    header: PropTypes.string,
  }).isRequired,
};

export default CheckoutPaymentMethod;
