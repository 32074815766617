import React from 'react';
import styled from 'styled-components';

import securityCodeFrontIcon from '@assets/images/security_code_front.svg';
import securityCodeBackIcon from '@assets/images/security_code_back.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(18)};
  margin-top: ${({ theme }) => theme.spacing(8)};

  > *:first-child {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export default function SecurityCodeModalIcon() {
  return (
    <Container>
      <img alt="cvv-front" src={securityCodeFrontIcon} />
      <img alt="cvv-back" src={securityCodeBackIcon} />
    </Container>
  );
}
