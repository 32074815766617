import { QueryClient, setLogger } from 'react-query';
import logger from '@app/logger';
import { ERROR_CODES } from '@commons/constants';

const MAX_DEFAULT_RETRIES = 2;

export function initializeReactQueryClient() {
  setLogger(logger);
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          if (error?.response?.data?.code === ERROR_CODES.SESSION_EXPIRED) {
            logger.debug('[QueryClient][retry]', 'fail due session expired');
            return false;
          }
          return failureCount < MAX_DEFAULT_RETRIES;
        },

        // Use ErrorBoundary component for session expired error => this makes react-query re-throws the error
        useErrorBoundary: (error) => error?.response?.data?.code === ERROR_CODES.SESSION_EXPIRED,
      },
    },
  });
}
