import React from 'react';
import Button from '@pedidosya/web-fenix/atoms/Button';
import styled from 'styled-components';

export const StyledWrapperButton = styled.div`
  padding-top: ${({ theme, isDesktop }) => (isDesktop ? theme.space('space-15') : 0)};
  padding-bottom: ${({ theme, isDesktop }) => (isDesktop ? theme.space('space-10') : 0)};
`;

const ConfirmButton = ({ isDesktop, ...buttonProps }) => {
  return (
    <StyledWrapperButton isDesktop={isDesktop}>
      <Button {...buttonProps} />
    </StyledWrapperButton>
  );
};

export default ConfirmButton;
