import { updateOnExpiredCheckoutRedirectTo } from '@app/contexts/mainContext/actions/updateOnExpiredCheckoutRedirectTo';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useDeviceInfo } from '@app/contexts/mainContext/selectors/useDeviceInfo';
import { useOnExpiredCheckoutRedirectTo } from '@app/contexts/mainContext/selectors/useOnExpiredCheckoutRedirectTo';
import { getHomeLink } from '@app/utils/deeplinks';
import { getQueryParams } from '@app/utils/domRouter';
import { QUERY_PARAM } from '@commons/constants';
import { useEffect } from 'react';

export const useExpiredCheckoutUrlUpdate = () => {
  const dispatch = useAppDispatch();
  const deviceInfo = useDeviceInfo();
  const redirectTo = useOnExpiredCheckoutRedirectTo();

  const redirectToFromQuery = getQueryParams(window?.location, QUERY_PARAM.CHECKOUT.ON_EXPIRED_REDIRECT_TO);
  const homeLink = getHomeLink({ ...deviceInfo, isWebview: () => deviceInfo?.isWebview });
  const redirectUriOrHomeLink = redirectToFromQuery || homeLink;

  useEffect(() => {
    if (redirectUriOrHomeLink && redirectUriOrHomeLink !== redirectTo) {
      updateOnExpiredCheckoutRedirectTo(dispatch, redirectUriOrHomeLink);
    }
  }, [dispatch, redirectTo, redirectUriOrHomeLink]);
};
