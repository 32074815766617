export const BFF_COMPONENTS_TYPES = {
  PAYMENT_INSTRUMENTS: 'PAYMENT_INSTRUMENTS',
  PAYMENT_INSTRUMENT: 'PAYMENT_INSTRUMENT',
  INPUT_TEXT: 'INPUT_TEXT',
  HELP_CVV_DIALOG: 'HELP_CVV_DIALOG',
  NEW_CARD_FORMS: {
    SPREEDLY: 'SPREEDLY_FORM',
  },
  ERROR_DIALOG: 'ERROR_DIALOG',
};
