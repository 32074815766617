import styled, { css } from 'styled-components';
import BottomSheetContent from '@components/BottomSheet/components/BottomSheetContent';

const Overlay = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  position: fixed;
  z-index: ${({ theme }) => theme.constants.zIndex.bottomSheet};
  left: 0;
  top: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${({ theme, showOverlay }) => (showOverlay ? theme.commons.global.overlay : 'transparent')};
  ${({ toggle }) =>
    toggle &&
    css`
      visibility: visible;
      opacity: 1;
      ${BottomSheetContent} {
        transition: 0.3s;
        transition-timing-function: ease-out;
        transform: translateY(0);
      }
    `}
`;

export default Overlay;
