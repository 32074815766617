import React, { useState } from 'react';
import styled from 'styled-components';
import { useIsDesktop } from '@app/hooks/layout/useIsDesktop';
import { BackendDrivenPage, useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import { BFF_ACTION_TYPES, BFF_COMPONENTS_TYPES } from '../../constants/bff';
import InputCurrencyFormatter from '@app/components/InputCurrencyFormatter';
import { useForm } from '@app/hooks/useForm';
import CustomTipLoading from './components/CustomTipLoading';
import ConfirmButton from './components/ConfirmButton';
import { GlobalStyle } from './GlobalStyle';
import { useCustomTipActionDefinitions } from './hooks/useCustomTipActionDefinitions';
import { useCustomTipScreen } from '@app/modules/checkout/queries';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import checkoutNativeBridge from '@app/utils/checkoutNativeBridge';
import { BrandError } from '@components/Error';
import Notification from './components/Notification';
import { useCheckoutBackendActionStatus } from '@app/contexts/mainContext/selectors/useCheckoutBackendActionStatus';
import { useDeviceInfo } from '@app/contexts/mainContext/selectors/useDeviceInfo';

const StyledBackendDrivenPage = styled(BackendDrivenPage)`
  padding-top: 0;
`;

function CustomTipForm() {
  const isDesktop = useIsDesktop();
  const { isLoading, isFetching, refetch, error, data } = useCustomTipScreen();
  const { render = {} } = data || {};
  const { components = [], bottom_bar: bottomBar = {}, app_bar: appBar = {} } = render;
  const { form, register, isFormValid } = useForm();
  const { actionDefinitions } = useCustomTipActionDefinitions(form);
  const { executeRecursiveActions } = useBFFActionsExecutor(actionDefinitions);
  const { navigateBack } = useNavigateBack();
  const { isPerformingAction: isSubmitting } = useCheckoutBackendActionStatus();
  const { isWebview } = useDeviceInfo();
  const [isNotificationActive, setIsNotificationActive] = useState(false);

  const handleSubmit = async (actions) => {
    setIsNotificationActive(false);

    if (!Boolean(actions?.length)) return;
    const response = await executeRecursiveActions(actions);
    const backendActions = response.find(({ type }) => type === BFF_ACTION_TYPES.PERFORM_BACKEND_ACTION);
    const { result } = backendActions;
    if (!result?.success) {
      setIsNotificationActive(true);
      return;
    }
    if (isWebview()) {
      checkoutNativeBridge.closeWebview({ canRefreshSummary: true });
      return;
    }
    navigateBack();
  };

  const componentResolvers = {
    [BFF_COMPONENTS_TYPES.INPUT]: ({ data }) => {
      const {
        id: name,
        rules: { is_required: isRequired, min_value: minValue, max_value: maxValue },
        currency,
        help_text: helpText,
        ...inputProps
      } = data;
      const inputRules = { isRequired, minValue, maxValue };
      return {
        component: InputCurrencyFormatter,
        props: {
          currency: {
            countryCode: currency?.country_code,
            ...currency,
          },
          helpText,
          ...inputProps,
          ...register(name, inputRules),
        },
      };
    },
    [BFF_COMPONENTS_TYPES.CONFIRMATION_BUTTON]: ({ data: { title, style }, actions }) => {
      const getState = () => {
        if (!isFormValid) return 'disabled';
        return isSubmitting ? 'loading' : 'enabled';
      };
      return {
        component: ConfirmButton,
        props: {
          ...style,
          fullWidth: style?.full_width,
          isDesktop,
          type: 'submit',
          state: getState(),
          label: title,
          disabled: !isFormValid || isSubmitting,
          onClick: () => handleSubmit(actions),
        },
      };
    },
  };

  if (isLoading || isFetching) {
    return <CustomTipLoading isDesktop={isDesktop} />;
  }

  if (error) {
    return (
      <BrandError
        onClick={refetch}
        secondaryActionLabel="Volver"
        secondaryActionClick={() => {
          if (isWebview()) {
            checkoutNativeBridge.closeWebview({ canRefreshSummary: true });
            return;
          }
          navigateBack();
        }}
      />
    );
  }

  return (
    <>
      <GlobalStyle />
      <Notification isDesktop={isDesktop} show={isNotificationActive} />
      <StyledBackendDrivenPage
        actionDefinitions={actionDefinitions}
        componentResolvers={componentResolvers}
        components={components}
        header={appBar}
        footer={!isDesktop && bottomBar}
      />
    </>
  );
}

export default CustomTipForm;
