import PedidosYaPagos from '@assets/images/pedidosya_pagos.svg';
import Image from '@pedidosya/web-fenix/foundations/Image';
import React from 'react';
import styled from 'styled-components';
import SummarySectionTitle from './SummarySectionTitle';
import SummarySectionSubtitle from './SummarySectionSubtitle';

const Container = styled.section`
  margin: ${({ theme, margin }) => margin || `${theme.space('spacing-layout-small')} 0`};
`;

const StyledTitle = styled(SummarySectionTitle)`
  margin: ${({ margin }) => margin || '0'};
`;

const StyledSubtitle = styled(SummarySectionSubtitle)`
  margin: ${({ theme, margin }) => margin || `${theme.space('spacing-layout-xsmall')} 0 0`};
`;

const ComponentContainer = styled.div`
  margin: ${({ theme, margin }) => margin || `${theme.space('spacing-layout-small')} 0 0`};
`;

const IMAGES = {
  'ic-pedidos-ya-pagos': PedidosYaPagos,
};

const SummarySectionBFF = ({
  titleImage,
  title,
  subtitle,
  ComponentBFF,
  containerMargin,
  titleMargin,
  subtitleMargin,
  componentMargin,
  componentProps,
}) => {
  return (
    <Container margin={containerMargin}>
      <StyledTitle margin={titleMargin}>
        {titleImage ? <Image src={IMAGES[titleImage.key]} alt={title} /> : title}
      </StyledTitle>
      {subtitle ? <StyledSubtitle margin={subtitleMargin}>{subtitle}</StyledSubtitle> : null}
      <ComponentContainer margin={componentMargin}>
        <ComponentBFF {...componentProps} />
      </ComponentContainer>
    </Container>
  );
};

export default SummarySectionBFF;
