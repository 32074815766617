export const BFF_COMPONENTS_TYPES = {
  SUMMARY: 'SUMMARY',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  PAYMENT_INSTRUMENTS: 'PAYMENT_INSTRUMENTS',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  PRICE_BOX: 'PRICE_BOX',
  BILLING_DATA: 'BILLING_DATA',
  ERROR_DIALOG: 'ERROR_DIALOG',
  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  BUTTON: 'BUTTON',
  DELIVERY_DETAIL: 'DELIVERY_DETAIL',
  COLUMN: 'COLUMN',
  CALL_TO_ACTION: 'CALL_TO_ACTION',
  INFO_LIST: 'INFO_LIST',
  BANNER: 'BANNER',
  CONFIRMATION_BUTTON: 'CONFIRMATION_BUTTON',
  INPUT: 'INPUT',
  TIPS: 'TIPS',
};

export const BFF_ACTION_TYPES = {
  NAVIGATE: 'NAVIGATE',
  NAVIGATE_BACK: 'NAVIGATE_BACK',
  EXTERNAL_NAVIGATE: 'EXTERNAL_NAVIGATE',
  TRACKING: 'TRACKING',
  PERFORM_BACKEND_ACTION: 'PERFORM_BACKEND_ACTION',
  NAVIGATE_TO_WEBVIEW: 'NAVIGATE_TO_WEBVIEW',
  TRACKING_LEGACY: 'TRACKING_LEGACY',
  OPEN_MODAL: 'OPEN_MODAL',
  ORDER_EVENT: 'ORDER_EVENT',
  CONFIRM_CHECKOUT: 'CONFIRM_CHECKOUT',
  DING_DONG: 'DING_DONG',
};
