import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

export const useInvalidateQueriesAndRefetchInactive = () => {
  const queryClient = useQueryClient();

  const invalidateQueriesAndRefetchInactive = useCallback(
    async (queryNames, options) => {
      await Promise.all(
        queryNames.map((queryName) => {
          return queryClient.invalidateQueries(queryName, {
            refetchInactive: true,
            ...options,
          });
        }),
      );
    },
    [queryClient],
  );

  return { invalidateQueriesAndRefetchInactive };
};
