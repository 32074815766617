import logger from '@app/logger';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';
import { URLS } from './constants';

async function performBackendAction(options = {}, axios = getAxiosInstance()) {
  try {
    const { data } = await axios.post(URLS.PERFORM_BACKEND_ACTION, options);
    return data;
  } catch (error) {
    logger.error('[services][performBackendAction]', parseAxiosError(error).message);
    throw error;
  }
}

export default performBackendAction;
