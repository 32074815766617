import { CHECKOUT_TYPES } from '@commons/constants';

const typeKeys = {
  [CHECKOUT_TYPES.LOYALTY_SUBSCRIPTION]: 'loyalty',
  [CHECKOUT_TYPES.BNPL_DEBT]: 'bnpl',
  [CHECKOUT_TYPES.DONATION]: 'donation',
  [CHECKOUT_TYPES.CART_LEGACY]: '',
};

const buildEventName = (suffix) => (checkoutType) => {
  const prefix =
    typeKeys[checkoutType] !== undefined
      ? typeKeys[checkoutType]
      : (checkoutType && String(checkoutType).toLowerCase()) || '';

  return prefix ? `${prefix}_${suffix}` : suffix;
};

export const TRACKING_EVENTS = {
  CHECKOUT_LOADED: buildEventName('checkout.loaded'),
  CHECKOUT_CLOSED: buildEventName('checkout.closed'),
  PAYMENT_METHOD_CLICKED: buildEventName('payment_method.clicked'),
  TRANSACTION_SUCCESS: buildEventName('transaction'),
  PURCHASE: buildEventName('purchase'),
  TRANSACTION_CLICKED: buildEventName('transaction.clicked'),
  TRANSACTION_FAILED: buildEventName('transaction.failed'),
  // TODO: remove once CVV is migrated to Payment Methods micro site
  CODE_VALIDATION_LOADED: buildEventName('cvv_code.loaded'),
  CODE_VALIDATION_CLOSED: buildEventName('cvv_code.closed'),
  CODE_VALIDATION_ADDED: buildEventName('cvv_code.added'),
};
