import React from 'react';
import OptionRowRadio from './OptionRowRadio';
import { Card } from '@appscore/web-components';
import { useIntl } from 'react-intl';
import messages from '../messages';

export const getOrderTimeFormatted = (orderTimeDate) => {
  const date = new Date(orderTimeDate);
  const fullMinutes = (date.getUTCMinutes() < 10 ? '0' : '') + date.getUTCMinutes();
  return `${date.getUTCHours()}:${fullMinutes}`;
};

const isOptionSelected = ({ selectedTime, from, availability }) => {
  if (availability) {
    return Boolean(selectedTime?.fromZTime === from && availability > 0);
  }
  return Boolean(selectedTime?.fromZTime === from);
};

const isOptionDisabled = (availability) => {
  if (!isNaN(availability)) {
    return availability < 1;
  }
  return false;
};

const ItemList = ({ times, selectedTime, deliveryTime, onSelectTime, showAsSoonAsPossible }) => {
  const { formatMessage } = useIntl();

  return (
    <Card direction="column">
      {showAsSoonAsPossible && (
        <OptionRowRadio
          key="asap"
          text={formatMessage(messages.asSoonAsPossibleItem, {
            minFrom: deliveryTime.minMinutes,
            maxTo: deliveryTime.maxMinutes,
          })}
          selected={Boolean(selectedTime?.asSoonAsPossible)}
          onClick={() => onSelectTime({ asSoonAsPossible: true })}
        />
      )}
      {times.map(({ from, to, availability, message }) => (
        <OptionRowRadio
          key={`${from}-${to}`}
          selected={isOptionSelected({ selectedTime, from, availability })}
          onClick={() => onSelectTime({ fromZTime: from })}
          text={formatMessage(messages.orderTime, { from: getOrderTimeFormatted(from), to: getOrderTimeFormatted(to) })}
          secondaryText={message}
          disabled={isOptionDisabled(availability)}
        />
      ))}
    </Card>
  );
};

export default ItemList;
