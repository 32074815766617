import { isStaging, isProduction } from '@app/utils/environment';
import { isObject } from '@app/utils/object';
import { Severity } from '@sentry/browser';
import { getDatadogInstance } from './datadog/instance';
import { getSentryInstance } from './sentry/instance';
import { initDataDogLogger } from './datadog/datadog-logger';
import { initializeSentry, logEvent, logError } from './sentry';
import { SENTRY_LOG_LEVEL } from './sentry/constants';
import { DATADOG_LOG_LEVEL } from './datadog/constants';

const DATADOG_LOGGER_ENABLED = false;
const SENTRY_LOGGER_ENABLED = true;

const instance = {
  // eslint-disable-next-line no-console
  trace: console.trace,
  // eslint-disable-next-line no-console
  debug: console.debug,
  info: console.info,
  warn: console.warn,
  warning: console.warn,
  error: console.error,
  fatal: (error, data) => console.error(error?.message),
};

const logToDataDog = (level, context, args) => {
  const ddInstance = getDatadogInstance();
  if (ddInstance) {
    if (context) {
      ddInstance[DATADOG_LOG_LEVEL[level]](args.join(' '), { ...context });
    } else {
      ddInstance[DATADOG_LOG_LEVEL[level]](args.join(' '));
    }
  }
};

const logToSentry = (level, context = {}, args) => {
  const sentryInstance = getSentryInstance();
  if (!sentryInstance) return;
  if (SENTRY_LOG_LEVEL[level] === Severity.Fatal) {
    const error = context?.error;
    delete context?.error;
    logError(error, context);
  } else {
    const eventName = args.join(' ');
    logEvent(eventName, {
      level: SENTRY_LOG_LEVEL[level],
      data: {
        ...context,
      },
    });
  }
};

const logWithJoinedMessageAndContext = (level, ...args) => {
  const lastArg = args[args.length - 1];
  let context;
  if (isObject(lastArg)) {
    context = args.pop();
  }
  if (DATADOG_LOGGER_ENABLED) {
    logToDataDog(level, context, args);
  }
  if (SENTRY_LOGGER_ENABLED) {
    logToSentry(level, context, args);
  }
};

export const initClientLogger = ({ country, environment, deviceInfo, userId } = {}) => {
  if ((!isStaging() && !isProduction()) || (!SENTRY_LOGGER_ENABLED && !DATADOG_LOGGER_ENABLED)) return instance;

  const ddLogLevel = isProduction() ? 'info' : 'debug';
  const countryName = country?.name || 'NOT_SET';
  const ddLoggerMetadata = {
    isWebview: deviceInfo?.isWebview,
    country: countryName,
    environment,
    userId,
  };

  if (SENTRY_LOGGER_ENABLED) initializeSentry({ country, environment, deviceInfo, userId });
  if (DATADOG_LOGGER_ENABLED) initDataDogLogger(ddLogLevel, environment, deviceInfo?.isWebview, ddLoggerMetadata);

  instance.debug = (...args) => logWithJoinedMessageAndContext('debug', ...args);
  instance.info = (...args) => logWithJoinedMessageAndContext('info', ...args);
  instance.warn = (...args) => logWithJoinedMessageAndContext('warn', ...args);
  instance.warning = (...args) => logWithJoinedMessageAndContext('warning', ...args);
  instance.error = (...args) => logWithJoinedMessageAndContext('error', ...args);
  instance.fatal = (error, data) =>
    logWithJoinedMessageAndContext('fatal', error?.message, {
      errorMessage: error?.message,
      errorStack: error?.stack,
      error,
      ...data,
    });

  return instance;
};

export default instance;
