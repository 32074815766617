// TODO: should be removed
import { useQuery } from 'react-query';
import getSecurityCodeValidation from '@app/services/paymentMethod/getSecurityCodeValidation';

export const QUERIES_NAMES = {
  PAYMENT_INSTRUMENTS: 'PAYMENT_INSTRUMENTS',
  SECURITY_CODE_VALIDATION: 'SECURITY_CODE_VALIDATION',
};

export const useSecurityCodeValidation = (paymentInstrumentId) =>
  useQuery([QUERIES_NAMES.SECURITY_CODE_VALIDATION, paymentInstrumentId], () =>
    getSecurityCodeValidation(paymentInstrumentId),
  );
