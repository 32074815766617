import { useLocation, useNavigate } from 'react-router-dom';
import { EXTERNAL_URIS_FRONT, ROUTES, URIS_FRONT } from '@commons/services/routes.service';
import { useCallback } from 'react';
import format from '@commons/utils/string/format';
import { QUERY_PARAM } from '@commons/constants';
import { addQueryParamsToUrlString } from '@app/utils/queryString';

export const useNavigateToDeliveryAddress = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const successCallbackPath = URIS_FRONT.DELIVERY_ADDRESS_SELECTION_SUCCESS;
  /**
   * @param {string} checkoutId cartId
   * @param {string} instrumentIds String of addressId selected
   */
  const navigateToDeliveryAddress = useCallback(
    (checkoutId, { addressSelected } = {}) => {
      const orderAddressPath = format(EXTERNAL_URIS_FRONT.ORDER_ADDRESS, checkoutId);
      const url = addQueryParamsToUrlString(orderAddressPath, {
        [QUERY_PARAM.ORDER_ADDRESS.CALLBACK]: successCallbackPath,
      });
      navigate(ROUTES.DELIVERY_ADDRESS, { state: { url, background: location } });
    },
    [location, navigate, successCallbackPath],
  );
  return { navigateToDeliveryAddress };
};
