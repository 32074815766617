/**
 * This function will correctly transform the event names
 * provided by BFF and will be replaced with the new definition
 */

function transformEvent(event) {
  const modifiedEventName = EVENT_NAMES_TO_MODIFY[event] || event;
  return formatEvent(modifiedEventName);
}

const EVENT_NAMES_TO_MODIFY = {
  ecommerce_purchase: 'transaction',
};

/**
 * @param {String} event A tracking event
 * @returns {String} A tracking event guaranteed to be in the correct format (payment_method.clicked)
 */

const formatEvent = (event) => event.replace(/_(?!.*(_|\.))/g, '.');

export default transformEvent;
