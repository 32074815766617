import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

const CircleAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  45% {
    transform: scale(0.05);
  }
  55% {
    transform: scale(0.04);
  }
  100% {
    transform: scale(1);
  }
`;

const CheckmarkAnimation = keyframes`
  45% {
    opacity: 0
  }
  55% {
    opacity: 1
  }
  70% {
    transform: translate(0, 0);
    opacity: 1
  }
  100% {
    transform: translate(-100px, -125px);
    opacity: 1
  }
`;

const CheckmarkMainAnimation = keyframes`
  55% {
    height: 34px;
  }
  65% {
    height: 121px;
  }
  100% {
    height: 121px;
  }
`;

const CheckmarkSecondaryAnimation = keyframes`
  65% {
    width: 26px;
  }
  75% {
    width: 46px;
  }
  100% {
    width: 46px;
  }
`;

const Circle = styled.span`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 100%;
  animation: ${CircleAnimation} 2s ease-out forwards;
  background: ${({ theme }) => theme.colors.lime_lighter_1};
  display: inline-table;
  position: absolute;
`;

const InnerCircle = styled.span`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.lime_darker_1};
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CheckmarkContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(35deg);
`;

const Checkmark = styled.span`
  display: inline-block;
  width: 22px;
  height: 82px;
  opacity: 0;
  transform: rotate(35deg) scale(14);
  animation: ${CheckmarkAnimation} 2s ease forwards;
  position: relative;
`;

const CheckmarkMain = styled.span`
  position: absolute;
  width: 26px;
  height: 34px;
  background-color: white;
  left: 11px;
  bottom: 7px;
  border-radius: 13px 13px 13px 0;
  animation: ${CheckmarkMainAnimation} 2s ease forwards;
`;

const CheckmarkSecondary = styled.span`
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: white;
  right: 10px;
  bottom: 7px;
  border-radius: 13px 0 0 13px;
  animation: ${CheckmarkSecondaryAnimation} 2s ease forwards;
`;

function getCircleSize(screenWidth, screenHeight) {
  return Math.max(screenWidth, screenHeight) * 2;
}

function getInnerCircleSize(circleSize) {
  return circleSize * 0.75;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Success = () => {
  const { width: screenWidth, height: screenHeight } = useMemo(() => getWindowDimensions(), []);

  const circleSize = getCircleSize(screenWidth, screenHeight);
  const innerCircleSize = getInnerCircleSize(circleSize);

  return (
    <Circle size={circleSize}>
      <InnerCircle size={innerCircleSize}>
        <CheckmarkContainer>
          <Checkmark>
            <CheckmarkMain />
            <CheckmarkSecondary />
          </Checkmark>
        </CheckmarkContainer>
      </InnerCircle>
    </Circle>
  );
};

export default Success;
