export const HOME_LINKS = {
  WEB: '/',
  IOS: 'pedidosya://launcher',
  ANDROID: 'pedidosya://',
};

const WEB = 'web';
const HOME_CONFIG = {
  ios: HOME_LINKS.IOS,
  android: HOME_LINKS.ANDROID,
  web: HOME_LINKS.WEB,
};

export const getHomeLink = ({ os = '', isWebview = () => false }) => {
  const option = isWebview() ? os : WEB;
  return HOME_CONFIG[option] || '';
};
