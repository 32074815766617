import useOnce from '@hooks/useOnce';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  margin: 1vh 3vw;
`;

const ColumnSection = ({ children, onHasColumnComponent }) => {
  useOnce(() => onHasColumnComponent(), []);
  return <Container>{children}</Container>;
};

export default ColumnSection;
