import { defineMessages } from 'react-intl';

export default defineMessages({
  metaTitle: {
    id: 'commons.metaTitle',
    defaultMessage: 'Delivery de Comida Online - Elegí, Pedí y Ya | PedidosYa',
  },
  metaDescription: {
    id: 'commons.metaDescription',
    defaultMessage:
      '¿Con hambre y nada te copa? Acá tu comida sabrosa. Hoy puede tener otro gusto. PedidosYa, te llena el corazón  ❤',
  },
});
