import { format } from '@pedidosya/currency-formatter';

/**
 * The function is responsible for removing any character other than digits
 * Dot or comma entries are also not allowed and will be removed
 * The precision parameter defines whether the format is done with or without decimals
 * The country code parameter defines the currency format according to currency-formatter lib:
 * https://github.com/pedidosya/currency-formatter/tree/staging
 * @param {object} options
 * @param {string} options.inputValue
 * @param {number} options.precision - precision = 0 (without decimals) | precision > 0 (with decimals)
 * @param {string} options.country - Para Argentina es "ar" o Peru "pe"
 */

export function getFormattedValueToCurrency({ inputValue, precision = 0, country }) {
  if (!country) {
    throw Error('country code cannot be an empty value');
  }
  let value = getOnlyDigits(String(inputValue));
  if (Boolean(precision)) {
    value = getValueAsDecimalFormat(value, precision);
  }
  return {
    currencyFormattedValue: getFormattedValueByCountry({
      inputValue: value,
      country,
      precision,
    }),
    numericValue: value && value !== '0.00' ? parseFloat(value) : '',
  };
}

/**
 * @param {string} inputValue
 */
export function getOnlyDigits(inputValue) {
  return inputValue.replace(/[^0-9]/g, '');
}

/**
 * @param {string} inputValue
 * @param {number} precision
 */
export function getValueAsDecimalFormat(inputValue, precision) {
  if (!inputValue) return inputValue;
  const divisor = Math.pow(10, precision);
  return (parseFloat(inputValue) / divisor).toFixed(precision);
}

/**
 * @param {string} inputValue
 * @param {string} country
 */
export function getFormattedValueByCountry({ inputValue, country, precision = 0 }) {
  if (!parseFloat(inputValue)) return '';

  const result = format(parseFloat(inputValue), country);

  if (!result) {
    throw Error(`the country code [${country}] is not valid`);
  }

  const { integerValue, fractionValue, decimalValue } = result;

  if (!Boolean(precision)) return integerValue;

  if (!fractionValue) {
    return `${integerValue}${decimalValue}${'0'.repeat(precision)}`;
  }
  return `${integerValue}${decimalValue}${fractionValue.slice(0, precision)}`;
}
