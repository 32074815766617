import { useNavigateToChallenge } from '@app/hooks/navigation/useNavigateToChallenge';
import { useConfirmCheckout } from '@app/modules/checkout/hooks/useConfirmCheckout';
import { CHALLENGES_CODES } from '@commons/constants/challengesCodes';
import { useMemo } from 'react';

export const ORDER_EVENTS = {
  OPEN_BNPL: 'OPEN_BNPL',
  RETRY_ORDER: 'RETRY_ORDER',
};

export const useOrderEventActionDefinition = ({
  trackTransactionFailed,
  trackTransactionSuccess,
  errorCallback,
  secondaryErrorCallback,
}) => {
  const { confirmCheckout } = useConfirmCheckout();
  const navigateToChallenge = useNavigateToChallenge();

  const orderEventDefinitions = useMemo(
    () => ({
      [ORDER_EVENTS.RETRY_ORDER]: async () => {
        const result = await confirmCheckout({
          errorCallback,
          secondaryErrorCallback,
          trackTransactionFailed,
          trackTransactionSuccess,
        });
        return { actions: result?.actions };
      },
      [ORDER_EVENTS.OPEN_BNPL]: (payload) => navigateToChallenge(CHALLENGES_CODES.BNPL_REQUIRED, payload),
    }),
    [
      confirmCheckout,
      errorCallback,
      navigateToChallenge,
      secondaryErrorCallback,
      trackTransactionFailed,
      trackTransactionSuccess,
    ],
  );

  const orderEventActionDefinition = ({ event, purchase_id: purchaseId }) => {
    return orderEventDefinitions[event]({ purchaseId });
  };

  return { orderEventActionDefinition };
};
