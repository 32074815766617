import { STATE_KEYS } from '../constants';
import { useAppState } from '../context';

export const DISMISS_VALUES = {
  CLOSE: 'close',
  BACK: 'back',
};

function getIcon(value) {
  if (!value) return;
  if (value === DISMISS_VALUES.CLOSE) return 'CROSS';
}

export const useDismiss = () => {
  const [state] = useAppState();
  const value = state[STATE_KEYS.DISMISS];
  return {
    value,
    isClose: value === DISMISS_VALUES.CLOSE,
    icon: getIcon(value),
  };
};
