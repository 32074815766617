import CheckoutSummaryLoading from '@app/components/CheckoutSummaryLoading';
import { updateCheckoutConfirmationStatus } from '@app/contexts/mainContext/actions/updateCheckoutConfirmationStatus';
import { updateTermsAndConditionsStatus } from '@app/contexts/mainContext/actions/updateTermsAndConditionsStatus';
import { useHistoryPositionUpdate } from '@app/contexts/mainContext/hooks/useHistoryPositionUpdate';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useAppTermsAndConditions } from '@app/contexts/mainContext/selectors/useAppTermsAndConditions';
import { useAppTracker } from '@app/contexts/mainContext/selectors/useAppTracker';
import { useCheckoutConfirmationStatus } from '@app/contexts/mainContext/selectors/useCheckoutConfirmationStatus';
import { useCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import { useDeviceInfo } from '@app/contexts/mainContext/selectors/useDeviceInfo';
import { useDismiss } from '@app/contexts/mainContext/selectors/useDismiss';
import { useEntryPoint } from '@app/contexts/mainContext/selectors/useEntryPoint';
import { useGetMissingChallengesForInstrument } from '@app/contexts/mainContext/selectors/useGetMissingChallengesForInstrument';
import { useConfirmCheckout } from '@app/modules/checkout/hooks/useConfirmCheckout';
import { useCheckout } from '@app/modules/checkout/queries';
import { getHomeLink } from '@app/utils/deeplinks';
import { BackendDrivenPage, useBFFActionsExecutor } from '@checkout-ui/backend-driven';
import Banner from '@checkout-ui/banner';
import CheckoutBillingDataBFF from '@checkout-ui/billing-data';
import CheckoutDeliveryDetailBFF from '@checkout-ui/delivery-detail';
import CheckoutPriceboxBFF from '@checkout-ui/fenix-pricebox';
import InfoList from '@checkout-ui/info-list';
import CheckoutPaymentMethodsBFF from '@checkout-ui/payment-methods';
import { deepFindComponent } from '@commons/utils/components/findComponent';
import CardErrorIcon from '@components/CardErrorIcon';
import { BrandError } from '@components/Error';
import ErrorDialog from '@components/ErrorDialog';
import { useIsDesktop } from '@hooks/layout/useIsDesktop';
import { useHardNavigate } from '@hooks/navigation/useHardNavigate';
import { useNavigateToChallenge } from '@hooks/navigation/useNavigateToChallenge';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css, useTheme } from 'styled-components';
import { BFF_ACTION_TYPES, BFF_COMPONENTS_TYPES } from '../../constants/bff';
import { useCheckoutActionDefinitions } from '../CheckoutSummary/hooks/BFF/useCheckoutActionDefinitions';
import { useNavigateToUserPaymentInstruments } from '../CheckoutSummary/hooks/navigation/useNavigateToUserPaymentInstruments';
import useCheckoutTracking from '../CheckoutSummary/hooks/useCheckoutTracking';
import CallToAction from './components/CallToAction';
import CheckoutPaymentMethod from './components/CheckoutPaymentMethod';
import MissingCheckoutPaymentMethod from './components/CheckoutPaymentMethod/components/MissingCheckoutPaymentMethod';
import CheckoutResume from './components/CheckoutResume';
import ColumnSection from './components/ColumnSection';
import SummarySectionBFF from './components/SummarySectionBFF';
import TermsAndConditions from './components/TermsAndConditions';
import messages from './messages';

const StyledBackendDrivenPage = styled(BackendDrivenPage)`
  padding-top: 0;
  ${({ hasColumnComponent }) =>
    hasColumnComponent &&
    css`
      display: flex;
    `};
`;

function FenixCheckoutSummary() {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const [hasColumnComponent, setHasColumnComponent] = useState(false);
  useHistoryPositionUpdate();
  const hardNavigate = useHardNavigate();
  const savedCheckoutType = useCheckoutType();
  const dispatch = useAppDispatch();
  const getMissingChallengesForInstrument = useGetMissingChallengesForInstrument();
  const areTermsAccepted = useAppTermsAndConditions();
  const deviceInfo = useDeviceInfo();
  const isDesktop = useIsDesktop();
  const { isLoading, isFetching, error, refetch, data } = useCheckout();
  const {
    checkout_id: checkoutId,
    render = {},
    payment_details: paymentDetails = [],
    steps_before_cta: stepsBeforeCTA,
    tracking: trackingData,
  } = data || {};
  const checkoutType = trackingData?.checkoutType || savedCheckoutType;
  const {
    trackCloseClick,
    trackPaymentClick,
    trackTransactionClick,
    trackTransactionSuccess,
    trackTransactionFailed,
    trackPurchase,
  } = useCheckoutTracking({
    checkoutType,
    trackingData,
  });
  const { confirmCheckout } = useConfirmCheckout();
  const { isConfirming: isConfirmingCheckout, error: confirmError } = useCheckoutConfirmationStatus();
  const { tracker } = useAppTracker();
  const navigateToChallenge = useNavigateToChallenge();
  const { navigateToUserPaymentInstruments } = useNavigateToUserPaymentInstruments();
  const dismiss = useDismiss();
  const defaultCheckoutConfirmationErrorFirstOption = () =>
    updateCheckoutConfirmationStatus(dispatch, { isConfirming: false, error: { data: {}, isError: false } });
  const confirmationOptions = {
    errorCallback: () => navigateToUserPaymentInstruments(checkoutId, { paymentDetails }),
    secondaryErrorCallback: defaultCheckoutConfirmationErrorFirstOption,
  };
  const { actionDefinitions } = useCheckoutActionDefinitions({
    isNativeClose: dismiss.isClose,
    trackCloseClick,
    trackingData,
    trackTransactionFailed,
    trackTransactionSuccess: (trackingData) => {
      trackTransactionSuccess(trackingData);
      trackPurchase(trackingData);
    },
    confirmationOptions,
    paymentDetails,
  });
  const { executeBFFActions, executeRecursiveActions } = useBFFActionsExecutor(actionDefinitions);
  const entryPoint = useEntryPoint();

  useEffect(() => {
    if (!isDesktop) setHasColumnComponent(false);
  }, [isDesktop]);

  const { components = [], bottom_bar: bottomBar = {}, title = 'Último paso' } = render;
  const paymentMethodComponent = deepFindComponent(components, BFF_COMPONENTS_TYPES.PAYMENT_METHOD);
  const paymentInstrumentsComponent = deepFindComponent(components, BFF_COMPONENTS_TYPES.PAYMENT_INSTRUMENTS);
  const isMissingPaymentInstruments = paymentInstrumentsComponent?.data.sections
    .filter((s) => s.type === 'INSTRUMENTS')
    .some((s) => s.data.items.some((i) => i.type === 'EMPTY_STATE'));
  const hasTermsAndConditionsComponent = Boolean(
    deepFindComponent(components, BFF_COMPONENTS_TYPES.TERMS_AND_CONDITIONS),
  );

  const toggleTermsAndConditionsAcceptation = () => updateTermsAndConditionsStatus(dispatch, !areTermsAccepted);
  const hasPaymentInstrument = paymentMethodComponent !== undefined;
  const hasPaymentInstrumentsComponent = paymentInstrumentsComponent !== undefined;

  const isCTADisabled =
    (!areTermsAccepted && hasTermsAndConditionsComponent) ||
    (!hasPaymentInstrument && !hasPaymentInstrumentsComponent) ||
    isMissingPaymentInstruments ||
    isConfirmingCheckout;

  const header = {
    actions: [
      {
        type: BFF_ACTION_TYPES.NAVIGATE_BACK,
      },
    ],
    title,
    isOnlyAction: false,
    icon: dismiss.icon,
  };

  const handleConfirmCheckout = async () => {
    if (isConfirmingCheckout) return;
    if (stepsBeforeCTA && stepsBeforeCTA.length > 0) return executeBFFActions(stepsBeforeCTA);

    trackTransactionClick();

    const paymentInstrumentToChallenge = paymentDetails.find((paymentInstrument) => {
      const uncompletedChallenges = getMissingChallengesForInstrument(paymentInstrument);
      return uncompletedChallenges?.length > 0;
    });

    if (paymentInstrumentToChallenge) {
      const paymentInstrumentId = paymentInstrumentToChallenge?.instrument_id;
      const uncompletedChallenges = getMissingChallengesForInstrument(paymentInstrumentToChallenge);
      navigateToChallenge(uncompletedChallenges[0], { paymentInstrumentId });
      return;
    }

    const confirmationResult = await confirmCheckout({
      trackTransactionFailed,
      trackTransactionSuccess: (trackingData) => {
        trackTransactionSuccess(trackingData);
        trackPurchase(trackingData);
      },
      ...confirmationOptions,
    });

    if (confirmationResult?.actions)
      executeRecursiveActions(confirmationResult.actions, confirmationResult.fallbackFunction);
  };

  const onPaymentMethodClick = () => {
    if (isConfirmingCheckout) return;
    trackPaymentClick();
    navigateToUserPaymentInstruments(checkoutId, { paymentDetails, checkoutId, handleGoBack: refetch });
  };

  const getCTAState = () => {
    if (isConfirmingCheckout) return 'loading';
    return isCTADisabled ? 'disabled' : 'enabled';
  };

  if (isLoading || isFetching) {
    return <CheckoutSummaryLoading checkoutType={checkoutType} entryPoint={entryPoint} isDesktop={isDesktop} />;
  }

  if (error)
    return (
      <BrandError
        onClick={refetch}
        secondaryActionLabel="Ir al inicio"
        secondaryActionClick={() => {
          hardNavigate(getHomeLink(deviceInfo));
        }}
      />
    );

  const componentResolvers = {
    [BFF_COMPONENTS_TYPES.BANNER]: ({ data }) => ({
      component: SummarySectionBFF,
      props: {
        containerMargin: `${theme.space('spacing-layout-none')} -${theme.space('layout-gutter-default')}`,
        titleMargin: theme.space('spacing-layout-none'),
        componentProps: { ...data },
        ComponentBFF: Banner,
      },
    }),
    [BFF_COMPONENTS_TYPES.INFO_LIST]: ({ data }) => ({
      component: SummarySectionBFF,
      props: {
        // eslint-disable-next-line prettier/prettier
        containerMargin: theme.space('spacing-layout-none'),
        titleMargin: theme.space('spacing-layout-none'),
        componentProps: { ...data },
        ComponentBFF: InfoList,
      },
    }),
    [BFF_COMPONENTS_TYPES.SUMMARY]: (props) => ({
      component: CheckoutResume,
      props,
    }),
    [BFF_COMPONENTS_TYPES.PAYMENT_METHOD]: (props) => ({
      component: CheckoutPaymentMethod,
      props: {
        selectedPaymentMethod: { ...props },
        onClick: onPaymentMethodClick,
      },
    }),
    [BFF_COMPONENTS_TYPES.ADD_PAYMENT_METHOD]: (props) => ({
      component: MissingCheckoutPaymentMethod,
      props: {
        ...props,
        onClick: onPaymentMethodClick,
      },
    }),
    [BFF_COMPONENTS_TYPES.PRICE_BOX]: ({ title_image: titleImage, title, data: { sections } }) => ({
      component: SummarySectionBFF,
      props: {
        containerMargin: `${theme.space('spacing-component-xlarge')} 0 ${theme.space('spacing-component-medium')}`,
        titleImage,
        title,
        componentProps: {
          sections,
          onClick: (tracking) => tracking && tracker.track(tracking.event, tracking.attributes),
        },
        ComponentBFF: CheckoutPriceboxBFF,
      },
    }),
    [BFF_COMPONENTS_TYPES.TERMS_AND_CONDITIONS]: ({ title, link_text, link_url }) => ({
      component: TermsAndConditions,
      props: {
        isSelected: areTermsAccepted,
        handleClick: toggleTermsAndConditionsAcceptation,
        fullText: title,
        linkText: link_text,
        linkUrl: link_url,
      },
    }),
    [BFF_COMPONENTS_TYPES.PAYMENT_INSTRUMENTS]: ({ title_image: titleImage, title, data: { sections } }) => ({
      component: SummarySectionBFF,
      props: {
        titleImage,
        title,
        componentProps: {
          sections: sections,
          onClick: (actions) => !isConfirmingCheckout && executeBFFActions(actions),
        },
        ComponentBFF: CheckoutPaymentMethodsBFF,
      },
    }),
    [BFF_COMPONENTS_TYPES.DELIVERY_DETAIL]: ({ title_image: titleImage, title, data: { sections } }) => ({
      component: SummarySectionBFF,
      props: {
        titleImage,
        title,
        componentProps: {
          sections: sections,
          onClick: (actions) => !isConfirmingCheckout && executeBFFActions(actions),
        },
        ComponentBFF: CheckoutDeliveryDetailBFF,
      },
    }),
    [BFF_COMPONENTS_TYPES.BILLING_DATA]: ({
      title,
      data: { title: billingTitle, subtitle, icon, actions, chevron },
    }) => ({
      component: SummarySectionBFF,
      props: {
        title,
        componentProps: {
          title: billingTitle,
          subtitle,
          icon,
          onClick: () => !isConfirmingCheckout && executeBFFActions(actions),
          chevron,
        },
        ComponentBFF: CheckoutBillingDataBFF,
      },
    }),
    [BFF_COMPONENTS_TYPES.COLUMN]: ({ data: { components } }) => ({
      component: ColumnSection,
      props: {
        onHasColumnComponent: () => setHasColumnComponent(true),
      },
    }),
    [BFF_COMPONENTS_TYPES.CONFIRMATION_BUTTON]: ({ data: { title, style, actions } }) => ({
      component: CallToAction,
      props: {
        ...style,
        disabled: isCTADisabled,
        type: 'submit',
        state: getCTAState(),
        label: title,
        onClick: Boolean(actions?.length)
          ? () => !isConfirmingCheckout && executeBFFActions(actions)
          : handleConfirmCheckout,
      },
    }),
  };

  return (
    <>
      <StyledBackendDrivenPage
        hasColumnComponent={hasColumnComponent}
        actionDefinitions={actionDefinitions}
        componentResolvers={componentResolvers}
        components={components}
        header={header}
        footer={!hasColumnComponent && bottomBar}
      />
      <ErrorDialog
        show={confirmError?.isError}
        title={confirmError?.data?.title || formatMessage(messages.errorDefaultTitle)}
        description={confirmError?.data?.description || '  '}
        icon={confirmError?.data?.icon ? <CardErrorIcon icon={confirmError?.data?.icon} /> : null}
        firstOptionLabel={confirmError?.data?.firstOptionLabel || formatMessage(messages.errorDefaultButtonLabel)}
        onFirstOptionAction={confirmError?.data?.onFirstOptionAction || defaultCheckoutConfirmationErrorFirstOption}
        secondaryOptionLabel={confirmError?.data?.secondaryOptionLabel}
        onSecondaryOptionAction={confirmError?.data?.onSecondaryOptionAction}
      />
    </>
  );
}

export default FenixCheckoutSummary;
