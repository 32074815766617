const ERROR = 'error trying stringify';

function stringify(obj) {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return ERROR;
  }
}

export default stringify;
