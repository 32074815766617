import stringify from '@commons/utils/object/stringify';
import parse from '@commons/utils/string/parse';

class LocalStorage {
  get(key) {
    try {
      return parse(localStorage.getItem(key));
    } catch (error) {
      return undefined;
    }
  }

  set(key, value) {
    try {
      localStorage.setItem(key, stringify(value));
      return true;
    } catch (error) {
      return false;
    }
  }

  remove(key) {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }
}

export default LocalStorage;
