import React from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import { theme } from '@app/theme';
import { BrandError } from '@appscore/web-components/BrandError';
import sentryHubInstance from '@app/logger/sentry/initialize';

export const ErrorBoundary = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHubInstance?.captureException(error);

  return (
    <ThemeProvider theme={theme}>
      <BrandError {...props} />
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
