import logger from '@app/logger';
import { useEffect, useState } from 'react';

export const noop = { track: (...args) => logger.info('[MISS_TRACKING_IMPL]', 'No tracker implementation', ...args) };

export const useTrackerLoader = (trackerPromise) => {
  const [tracker, setTracker] = useState(null);

  const setNoop = () => setTracker(noop);

  useEffect(() => {
    trackerPromise.then(setTracker).catch(setNoop);
  }, [trackerPromise]);

  return tracker;
};
