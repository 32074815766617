import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Alert from '@pedidosya/web-fenix/icons/Alert';
import ChevronRight from '@pedidosya/web-fenix/icons/ChevronRight';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CheckoutPaymentMethodContainer from './CheckoutPaymentMethodContainer';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

Container.Message = styled.div`
  line-height: ${({ theme }) => `${theme.space('spacing-component-2xlarge')}`};
  padding-left: ${({ theme }) => `${theme.space('spacing-component-large')}`};
`;

Container.Action = styled.div`
  flex-grow: 2;
  text-align: end;
`;

function MissingCheckoutPaymentMethod({ title, header, onClick }) {
  return (
    <CheckoutPaymentMethodContainer header={header} onClick={onClick}>
      <Container>
        <Alert size="small" color="icon-color-feedback-error" />
        <Container.Message>
          <Typography token="font-subtitle-highcontrast-sentence-medium" color="text-color-feedback-error">
            {title}
          </Typography>
        </Container.Message>
        <Container.Action>
          <ChevronRight size="small" color="icon-color-feedback-error" />
        </Container.Action>
      </Container>
    </CheckoutPaymentMethodContainer>
  );
}

MissingCheckoutPaymentMethod.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MissingCheckoutPaymentMethod;
