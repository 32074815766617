import { resetPaymentInstrumentsChallenges } from '@app/contexts/mainContext/actions/resetPaymentInstrumentsChallenges';
import { updateCheckoutConfirmationStatus } from '@app/contexts/mainContext/actions/updateCheckoutConfirmationStatus';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useCheckoutId } from '@app/contexts/mainContext/selectors/useCheckoutId';
import { useIsCheckoutType } from '@app/contexts/mainContext/selectors/useCheckoutType';
import logger from '@app/logger';
import { useConfirmCheckoutMutation } from '@app/modules/checkout/mutations';
import { CHECKOUT_TYPES } from '@commons/constants';
import { useCallback } from 'react';
import { useCartLegacyConfirmation } from './useCartLegacyConfirmation';
import { useCheckoutBFFConfirmation } from './useCheckoutBFFConfirmation';
import { useHandleChallengeConfirmation } from './useHandleChallengeConfirmation';

const CHALLENGES_ERROR_CODE = 419;

export const useConfirmCheckout = () => {
  const checkoutId = useCheckoutId();
  const isCheckoutType = useIsCheckoutType();
  const { mutateAsync: confirmCheckoutMutation } = useConfirmCheckoutMutation(checkoutId);
  const dispatch = useAppDispatch();
  const { handleCheckoutBFFConfirmation } = useCheckoutBFFConfirmation();
  const { handleCartLegacyConfirmation } = useCartLegacyConfirmation();
  const handleChallengeConfirmation = useHandleChallengeConfirmation();

  const handleFailedConfirmation = useCallback(
    (err, { trackTransactionFailed }) => {
      logger.error(
        '[useConfirmCheckout][CONFIRM_CHECKOUT_ERR]',
        'error confirming checkout:',
        err.message,
        err.response?.data,
      );
      trackTransactionFailed();
      updateCheckoutConfirmationStatus(dispatch, { isConfirming: false, error: { data: {}, isError: true } });
      resetPaymentInstrumentsChallenges(dispatch);
    },
    [dispatch],
  );

  const confirmCheckout = useCallback(
    async ({
      errorCallback,
      secondaryErrorCallback,
      trackTransactionFailed,
      trackTransactionSuccess,
      version,
      isRepeatOrder,
    }) => {
      try {
        updateCheckoutConfirmationStatus(dispatch, { isConfirming: true });
        const response = await confirmCheckoutMutation({ version, isRepeatOrder });
        updateCheckoutConfirmationStatus(dispatch, { isConfirming: false });

        const handler = isCheckoutType(CHECKOUT_TYPES.CART_LEGACY)
          ? handleCartLegacyConfirmation
          : handleCheckoutBFFConfirmation;

        if (response.actions) {
          return {
            actions: response.actions,
            fallbackFunction: () =>
              handler(response, {
                errorCallback,
                secondaryErrorCallback,
                trackTransactionFailed,
                trackTransactionSuccess,
              }),
          };
        }

        return handler(response, {
          errorCallback,
          secondaryErrorCallback,
          trackTransactionFailed,
          trackTransactionSuccess,
        });
      } catch (err) {
        updateCheckoutConfirmationStatus(dispatch, { isConfirming: false });

        const fallbackFunction = () => {
          if (err.response?.status === CHALLENGES_ERROR_CODE) {
            try {
              return handleChallengeConfirmation(err.response?.data?.code);
            } catch (challengeErr) {
              return handleFailedConfirmation(challengeErr, { trackTransactionFailed });
            }
          }
          return handleFailedConfirmation(err, { trackTransactionFailed });
        };

        if (err.response?.data?.actions) {
          return { actions: err.response.data.actions, fallbackFunction };
        }

        return fallbackFunction();
      }
    },
    [
      dispatch,
      confirmCheckoutMutation,
      isCheckoutType,
      handleCartLegacyConfirmation,
      handleCheckoutBFFConfirmation,
      handleFailedConfirmation,
      handleChallengeConfirmation,
    ],
  );
  return { confirmCheckout };
};
