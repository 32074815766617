import { Flags } from '@commons/fwf/models/Flags';
import { FLAGS_KEYS } from './constants';

const FLAGS_UPDATED_EVENT_NAME = '@fwf/FLAGS_UPDATED_IN_BACKGROUND';
const CONTEXT_UPDATED_EVENT_NAME = '@fwf/RELEVANT_CONTEXT_CHANGE';

function getUser({ userData = {}, contextData = {} }) {
  let user = {
    ...contextData,
  };

  const { userId, userEmail, ...userDataRest } = userData;
  if (userId) user.userId = userId.toString();
  if (userEmail) user.email = userEmail;
  if (userId || userEmail) user = { ...user, ...userDataRest };
  return user;
}

export async function initializeInstance(fwfPromise, { userData, contextData }, onUpdate) {
  const fwf = await fwfPromise;

  if (!fwf) return null;

  const user = getUser({ userData, contextData });
  await fwf.setContext(user);
  await fwf.subscribeFeatures(FLAGS_KEYS);
  fwf.subscribeObserver((response) => {
    if (response.type === FLAGS_UPDATED_EVENT_NAME || response.type === CONTEXT_UPDATED_EVENT_NAME) {
      const flags = Flags(response.data);
      onUpdate && onUpdate(flags);
    }
  });

  return fwf;
}
