import Spinner from '@app/components/Spinner';
import useOnce from '@hooks/useOnce';
import React from 'react';

function ChallengesFlowCallback() {
  useOnce(() => {
    window.parent.postMessage({ event: 'submit' }, '*');
  });

  return <Spinner />;
}

export default ChallengesFlowCallback;
