import React, { useState } from 'react';
import useOnce from '@hooks/useOnce';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FixedFooter from './FixedFooter';

const Container = styled(FixedFooter)`
  background-color: white;
  padding: 5% ${(props) => props.theme.constants.lateralSpace};
  width: 100%;
  position: ${({ isDesktop }) => (isDesktop ? 'sticky' : 'fixed')};
  bottom: ${({ bottomOffset }) => bottomOffset};
  z-index: 100;
  box-sizing: border-box;
  border-top-right-radius: ${({ rounded }) => (rounded ? '40px' : 0)};
  border-top-left-radius: ${({ rounded }) => (rounded ? '40px' : 0)};
  box-shadow: 0px -16px 28px -34px;
`;

export function ShellFooter({ children, className, hideBorderTop, rounded, isDesktop }) {
  // Add bottom offset in order to show footer when OSK appears on Safari
  // Source: https://tkte.ch/2019/09/23/iOS-VisualViewport.html
  const [bottomOffset, setBottomOffset] = useState('0px');
  useOnce(() => {
    let pendingUpdate = false;

    const viewportHandler = (event) => {
      if (pendingUpdate) return;
      pendingUpdate = true;

      requestAnimationFrame(() => {
        pendingUpdate = false;
        const offset = window.innerHeight - event.target.height;
        setBottomOffset(`${offset}px`);
      });
    };

    // Check Visual Viewport API is supported
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', viewportHandler);
      window.visualViewport.addEventListener('scroll', viewportHandler);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', viewportHandler);
        window.visualViewport.removeEventListener('scroll', viewportHandler);
      }
    };
  });

  const T = typeof children === 'function' ? children() : children;
  if (!T) return null;

  return (
    <Container
      className={className}
      hideBorderTop={hideBorderTop}
      rounded={rounded}
      bottomOffset={bottomOffset}
      isDesktop={isDesktop}
    >
      {T}
    </Container>
  );
}

ShellFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  className: PropTypes.string,
  hideBorderTop: PropTypes.bool,
  rounded: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

ShellFooter.defaultProps = {
  hideBorderTop: false,
  rounded: false,
  isDesktop: false,
};
// Do not export as styled component, will break Shell/Main.js logic.
export default ShellFooter;
