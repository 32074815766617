import { PROVIDERS_NAMES } from './providers/constants';
import { createTokenForNewCard, createTokenForExistingCard } from './providers/spreedly/spreedlyTokenization';

// TODO: should be removed (all cardTokenization folder) when CVV challenge is removed
export default class CardTokenizer {
  constructor(providerName) {
    // TODO: Do dynamic imports only for the corresponding provider
    switch (providerName) {
      case PROVIDERS_NAMES.SPREEDLY:
      default:
        this.createTokenForCard = (isNewCard, { ...params }) => {
          if (isNewCard) {
            return createTokenForNewCard({ ...params });
          }
          return createTokenForExistingCard({ ...params });
        };
    }
  }
}
