import { noop } from '@hooks/setup/useTrackerLoader';
import { CACHE_KEYS, sessionCache } from '@app/services/cache';

export const STATE_KEYS = {
  BASKET_ID: 'basketId',
  CHECKOUT_ID: 'checkoutId',
  PURCHASE_ID: 'purchaseId',
  CHECKOUT_TYPE: 'checkoutType',
  ON_EXPIRED_CHECKOUT_REDIRECT_TO: 'onExpiredCheckoutRedirectTo',
  MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED: 'areTermsAccepted',
  PAYMENT_INSTRUMENTS_CHALLENGES_RESULTS: 'paymentInstrumentsChallengesResults',
  HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS: 'HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS',
  TRACKER_CONTEXT: 'trackerContext',
  DISMISS: 'dismiss',
  ENTRY_POINT: 'entryPoint',
  CHECKOUT_CONFIRMATION_STATUS: 'checkoutConfirmationStatus',
  CHECKOUT_BACKEND_ACTION_STATUS: 'checkoutBackendActionStatus',
};

export const generateInitialState = () => ({
  [STATE_KEYS.BASKET_ID]: '',
  [STATE_KEYS.CHECKOUT_ID]: '',
  [STATE_KEYS.PURCHASE_ID]: '',
  [STATE_KEYS.CHECKOUT_TYPE]: sessionCache.get(CACHE_KEYS.CHECKOUT_TYPE),
  [STATE_KEYS.ON_EXPIRED_CHECKOUT_REDIRECT_TO]: sessionCache.get(CACHE_KEYS.ON_EXPIRED_CHECKOUT_REDIRECT_TO),
  [STATE_KEYS.MICROSITE_CHECKOUT_ARE_TERMS_ACCEPTED]: false,
  [STATE_KEYS.PAYMENT_INSTRUMENTS_CHALLENGES_RESULTS]: {},
  [STATE_KEYS.HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS]:
    sessionCache.get(CACHE_KEYS.HISTORY_LENGTH_BEFORE_NAVIGATING_TO_PAYMENTS_METHODS) || {},
  [STATE_KEYS.TRACKER_CONTEXT]: { tracker: noop },
  [STATE_KEYS.DISMISS]: '',
  [STATE_KEYS.CHECKOUT_CONFIRMATION_STATUS]: { isConfirming: false, error: { isError: false, data: {} } },
  [STATE_KEYS.CHECKOUT_BACKEND_ACTION_STATUS]: { isPerformingAction: false, error: false, success: false },
});
