import {
  DEV_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
  PROD_ENVIRONMENT,
  STG_ENVIRONMENT,
  TEST_ENVIRONMENT,
} from '@commons/constants/environments';
import PropTypes from 'prop-types';
import React from 'react';
import { generateInitialState, STATE_KEYS } from './constants';
import { AppProvider } from './context';
import { reducer } from './reducer';
import { useMemoizedTracker } from './tracker/useMemoizedTracker';

const AppProviderWithResources = ({ children, tracker, context, initialState }) => {
  const trackerContext = useMemoizedTracker(tracker);

  return (
    <AppProvider
      initialState={{
        ...generateInitialState(),
        context: {
          ...context,
        },
        [STATE_KEYS.TRACKER_CONTEXT]: trackerContext,
        ...initialState,
      }}
      reducer={reducer}
    >
      {children}
    </AppProvider>
  );
};

AppProviderWithResources.propTypes = {
  tracker: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
  context: PropTypes.shape({
    environment: PropTypes.oneOf([
      LOCAL_ENVIRONMENT,
      TEST_ENVIRONMENT,
      DEV_ENVIRONMENT,
      STG_ENVIRONMENT,
      PROD_ENVIRONMENT,
    ]).isRequired,
    locale: PropTypes.string.isRequired,
    deviceInfo: PropTypes.shape({
      isWebview: PropTypes.bool.isRequired,
      os: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    device: PropTypes.oneOf(['WEBVIEW', 'MOBILE', 'DESKTOP']).isRequired,
    country: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      shortName: PropTypes.string.isRequired,
    }).isRequired,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  }).isRequired,
  initialState: PropTypes.shape({
    basketId: PropTypes.string,
    checkoutId: PropTypes.string,
    checkoutType: PropTypes.string,
    purchaseId: PropTypes.string,
  }),
};

export default AppProviderWithResources;
