import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Image from '@pedidosya/web-fenix/foundations/Image';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(CardContainer)`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.space('spacing-component-2xlarge')};
  width: auto;
  margin: ${({ theme }) => `${theme.space('spacing-component-3xlarge')} 0 ${theme.space('spacing-component-4xlarge')}`};
`;

const Resume = styled.div`
  margin-left: ${({ theme }) => theme.space('spacing-component-large')};
  line-height: ${({ theme }) => theme.space('spacing-component-3xlarge')};
`;

const Title = styled.h2`
  margin: 0;
`;

const Subtitle = styled.p`
  margin: 0;
`;

const Logo = styled(Image)`
  border-radius: 4px;
  color: ${({ theme }) => theme.color('shape-color-stroke-primary')};
`;

function CheckoutResume({ title, subtitle, icon }) {
  return (
    <StyledCard withShadow={false}>
      <Logo alt="resume-logo" src={icon} height={48} width={48} />
      <Resume>
        <Title>
          <Typography token="font-subtitle-highcontrast-sentence-large" color="text-color-primary">
            {title}
          </Typography>
        </Title>
        <Subtitle>
          <Typography token="font-body-midcontrast-sentence-small" color="text-color-primary">
            {subtitle}
          </Typography>
        </Subtitle>
      </Resume>
    </StyledCard>
  );
}

CheckoutResume.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default CheckoutResume;
