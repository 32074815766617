import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function getCheckoutContext(axios = getAxiosInstance()) {
  try {
    const { data } = await axios.get(URLS.GET_CHECKOUT_CONTEXT);
    return data;
  } catch (error) {
    logger.error('[services][getCheckoutContext]', parseAxiosError(error).message);
    throw error;
  }
}

export default getCheckoutContext;
