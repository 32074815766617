import getCheckout from '@app/services/checkout/getCheckout';
import getCustomTipScreen from '@app/services/custom-tip/getCustomTipScreen';
import getCheckoutContext from '@app/services/checkout/getCheckoutContext';
import validateHTML from '@app/services/html/validateHTML';
import { useQuery } from 'react-query';
import { isDesktopDevice } from '@hooks/layout/useIsDesktop';
import { useEntryPoint } from '@app/contexts/mainContext/selectors/useEntryPoint';

export const QUERIES_NAMES = {
  CHECKOUT: 'CHECKOUT',
  TERMS_AND_CONDITIONS_DETAILS: 'TERMS_AND_CONDITIONS_DETAIL',
  CHECKOUT_CONTEXT: 'CHECKOUT_CONTEXT',
  CUSTOM_TIP_SCREEN: 'CUSTOM_TIP_SCREEN',
};

export const useCheckout = (queryOpts = {}) => {
  const isDesktop = isDesktopDevice();
  const entryPoint = useEntryPoint();
  return useQuery(
    [QUERIES_NAMES.CHECKOUT, { isDesktop, entryPoint }],
    () => getCheckout({ isDesktop, entryPoint }),
    queryOpts,
  );
};

export const useTermsAndConditionsDetail = (url) =>
  useQuery([QUERIES_NAMES.TERMS_AND_CONDITIONS_DETAILS, url], () => validateHTML(url));

export const useCheckoutContext = () => useQuery(QUERIES_NAMES.CHECKOUT_CONTEXT, () => getCheckoutContext());

export const useCustomTipScreen = (queryOpts = {}) => {
  const params = { isDesktop: isDesktopDevice() };
  return useQuery([QUERIES_NAMES.CUSTOM_TIP_SCREEN, params], () => getCustomTipScreen(params), queryOpts);
};
