import { REDIRECT_PAGES } from '@app/utils/redirection';
import { useHistory } from '@hooks/navigation/useHistory';
import { useRedirectToPage } from '@hooks/navigation/useRedirectToPage';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNavigateBack = () => {
  const { length } = useHistory();
  const { redirectToPage } = useRedirectToPage();
  const navigate = useNavigate();

  const navigateBack = useCallback(() => {
    const isFirstHistoryEntry = length === 1;
    if (isFirstHistoryEntry) {
      redirectToPage(REDIRECT_PAGES.HOME);
    } else {
      navigate(-1);
    }
  }, [length, navigate, redirectToPage]);

  return { navigateBack };
};
