import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Image, ArrowRight } from '@appscore/web-components';
import CheckoutPaymentMethodContainer from './components/CheckoutPaymentMethodContainer';

const SelectedPaymentMethod = styled.div`
  flex-grow: 1;
  margin: ${({ theme }) => `0 ${theme.spacing(4)}`};
`;

SelectedPaymentMethod.Title = styled.div`
  ${({ theme }) => theme.fontFamily.secondaryFont.smallRegular}
  color: ${({ theme }) => theme.colors.night_blue_1};
  line-height: ${({ theme }) => `${theme.spacing(10)}`};
`;

SelectedPaymentMethod.Subtitle = styled.div`
  ${({ theme }) => theme.fontFamily.secondaryFont.xSmallRegular}
  color: ${({ theme }) => theme.colors.night_blue_2};
  margin-top: 5px;
  line-height: ${({ theme }) => `${theme.spacing(8)}`};
`;

const CardLogo = styled(Image)`
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
`;

function CheckoutPaymentMethod({ selectedPaymentMethod, onClick }) {
  return (
    <CheckoutPaymentMethodContainer header={selectedPaymentMethod.header} onClick={onClick}>
      <CardLogo alt="card-logo" src={selectedPaymentMethod.icon} height={20} width={32} />
      <SelectedPaymentMethod>
        <SelectedPaymentMethod.Title>{selectedPaymentMethod.title}</SelectedPaymentMethod.Title>
        {selectedPaymentMethod.subtitle ? (
          <SelectedPaymentMethod.Subtitle>{selectedPaymentMethod.subtitle}</SelectedPaymentMethod.Subtitle>
        ) : null}
      </SelectedPaymentMethod>
      <ArrowRight size="small" />
    </CheckoutPaymentMethodContainer>
  );
}

CheckoutPaymentMethod.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedPaymentMethod: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.string,
    header: PropTypes.string,
  }).isRequired,
};

export default CheckoutPaymentMethod;
