import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function getPreOrderTimes(axios = getAxiosInstance()) {
  try {
    const { data } = await axios.get(URLS.GET_ORDER_TIMES);
    return data;
  } catch (error) {
    logger.error('[services][getPreOrderTimes]', parseAxiosError(error).message);
    throw error;
  }
}

export default getPreOrderTimes;
