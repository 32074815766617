import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputText } from '@appscore/web-components';
import useOnce from '@hooks/useOnce';

const StyledInputText = styled(InputText)`
  label {
    width: 100%;
  }
`;

const BillingInput = ({ initValue, value, onChange, placeholder, errorFromConfirm, ...rest }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [currentValue, setCurrentValue] = useState(initValue);

  useOnce(() => {
    onChange(value ?? initValue);
  });

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (event) => {
    const inputValue = event?.target?.value;
    onChange(inputValue);
  };

  useEffect(() => {
    if (errorFromConfirm) {
      const { error, message } = errorFromConfirm;
      setErrorMessage(error || message);
    } else setErrorMessage('');
  }, [errorFromConfirm]);

  return (
    <StyledInputText
      type="text"
      {...rest}
      label={placeholder}
      onChange={handleChange}
      value={currentValue}
      max={100}
      helpText={errorMessage}
      error={Boolean(errorFromConfirm)}
      inputProps={{
        'data-testid': rest?.name,
      }}
    />
  );
};

BillingInput.propTypes = {
  initValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  errorFromConfirm: PropTypes.object,
};

export default BillingInput;
