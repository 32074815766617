import { DEVICE_TYPE } from '@app/utils/constants';
import getDevice from '@app/utils/device';
import { useCallback, useEffect, useState } from 'react';
import useOnce from '../useOnce';

export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState();

  useOnce(() => handleResize());

  const handleResize = useCallback(() => {
    setIsDesktop(isDesktopDevice());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return isDesktop;
};

export const isDesktopDevice = () => {
  const deviceType = getDevice();
  return deviceType === DEVICE_TYPE.DESKTOP;
};
