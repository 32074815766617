import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Image } from '@appscore/web-components';

const StyledCard = styled(Card)`
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(10)};
`;

const Icon = styled(Image)`
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

const Title = styled.span`
  ${({ theme }) => theme.fontFamily.secondaryFont.xSmallRegular}
  color: ${({ theme }) => theme.colors.night_blue_1};
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

const Subtitle = styled.span`
  ${({ theme }) => theme.fontFamily.secondaryFont.xSmallRegular}
  color: ${({ theme }) => theme.colors.night_blue_2};
  flex-grow: 1;
  text-align: right;
`;

function PaymentInstrumentToValidate({ title, subtitle, icon }) {
  return (
    <StyledCard>
      <Icon alt="card-log" src={icon} height={20} width={32} />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </StyledCard>
  );
}

PaymentInstrumentToValidate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default PaymentInstrumentToValidate;
