import { useMemo } from 'react';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import { BFF_ACTION_TYPES } from '@app/modules/checkout/constants/bff';
import { useAppTracker } from '@app/contexts/mainContext/selectors/useAppTracker';
import checkoutNativeBridge from '@app/utils/checkoutNativeBridge';
import { usePerformBackendActionDefinition } from '@app/hooks/BFF/usePerformBackendActionDefinition';
import { getUpdatedAttributesFromData } from '@app/utils/bff/getUpdatedAttributesFromData';
import { useDeviceInfo } from '@app/contexts/mainContext/selectors/useDeviceInfo';
import { useInvalidateQueriesAndRefetchInactive } from '@app/contexts/reactQuery/hooks/useInvalidateQueriesAndRefetchInactive';
import { QUERIES_NAMES } from '@app/modules/checkout/queries';

export const useCustomTipActionDefinitions = (tipData) => {
  const { tracker } = useAppTracker();
  const { performBackendActionDefinition } = usePerformBackendActionDefinition();
  const { navigateBack } = useNavigateBack();
  const { isWebview } = useDeviceInfo();
  const { invalidateQueriesAndRefetchInactive } = useInvalidateQueriesAndRefetchInactive();

  const actionDefinitions = useMemo(
    () => ({
      [BFF_ACTION_TYPES.NAVIGATE]: () => {
        if (isWebview()) {
          checkoutNativeBridge.closeWebview({ canRefreshSummary: true });
          return;
        }
        navigateBack();
        invalidateQueriesAndRefetchInactive([QUERIES_NAMES.CHECKOUT]);
      },
      [BFF_ACTION_TYPES.TRACKING]: ({ event, attributes }) => {
        tracker.track(event, getUpdatedAttributesFromData(attributes, tipData));
      },
      [BFF_ACTION_TYPES.PERFORM_BACKEND_ACTION]: async ({ request, response = {} }) => {
        return await performBackendActionDefinition({
          request: {
            ...request,
            body: getUpdatedAttributesFromData(request.body, tipData),
          },
          response,
        });
      },
    }),
    [invalidateQueriesAndRefetchInactive, isWebview, navigateBack, performBackendActionDefinition, tipData, tracker],
  );

  return { actionDefinitions };
};
