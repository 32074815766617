export const parsePaymentInstrument = ({ paymentDetails, instrumentIds }) => {
  return paymentDetails
    ? paymentDetails.map(({ instrument_id }) => ({
        id: instrument_id,
      }))
    : instrumentIds
        .replace(/ /g, '')
        .split(',')
        .map((instrumentId) => ({ id: instrumentId }));
};
