import { BFF_ACTION_TYPES } from '@app/modules/checkout/constants/bff';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import { useMemo } from 'react';

export const useBillingActionDefinitions = () => {
  const { navigateBack } = useNavigateBack();

  const actionDefinitions = useMemo(
    () => ({
      [BFF_ACTION_TYPES.NAVIGATE_BACK]: () => navigateBack(),
    }),
    [navigateBack],
  );

  return { actionDefinitions };
};
