import logger from '@app/logger';
import { URLS } from './constants';
import getAxiosInstance from '../axios';
import parseAxiosError from '@commons/utils/axios/parseAxiosError';

async function getBillingScreen(axios = getAxiosInstance()) {
  try {
    const { data } = await axios.get(URLS.GET_BILLING_SCREEN);
    return data;
  } catch (error) {
    logger.error('[services][getBillingScreen]', parseAxiosError(error).message);
    throw error;
  }
}

export default getBillingScreen;
