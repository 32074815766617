import semverValid from 'semver/functions/valid';
import semverCoerce from 'semver/functions/coerce';
import { useAppState } from '../context';

export const useDeviceInfo = () => {
  const [{ context }] = useAppState();
  const { deviceInfo } = context;

  return {
    ...deviceInfo,
    isWebview: () => deviceInfo.isWebview,
    isAndroid: () => deviceInfo.os === 'android',
    isIos: () => deviceInfo.os === 'ios',
    appVersion: semverValid(semverCoerce(deviceInfo.appVersion)),
  };
};
