import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from '@appscore/web-components';
import Container from './components/Container';
import Body from './components/Body';
import Right from './components/Right';
import Text from './components/Text';
import SecondaryText from './components/SecondaryText';

function OptionRowRadio({ text, selected, onClick, rightText, secondaryText, disabled }) {
  return (
    <Container
      onClick={onClick}
      tabIndex={disabled ? '-1' : '0'}
      role="radio"
      disabled={disabled}
      aria-checked={selected.toString()}
    >
      <RadioButton selected={selected} />
      <Body>
        <Text selected={selected}>{text}</Text>
        {rightText ? <Right>{rightText}</Right> : null}
        {secondaryText ? <SecondaryText>{secondaryText}</SecondaryText> : null}
      </Body>
    </Container>
  );
}

OptionRowRadio.propTypes = {
  text: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  rightText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default OptionRowRadio;
