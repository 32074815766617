import { invalidateSessionCache } from '@app/contexts/mainContext/actions/invalidateSessionCache';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useNavigateBack } from '@hooks/navigation/useNavigateBack';
import { useCallback } from 'react';
import nativeBridge from '@app/utils/nativeBridge';

export const useCloseCheckout = (options = {}) => {
  const { isNativeClose = false } = options;
  const { navigateBack } = useNavigateBack();
  const dispatch = useAppDispatch();

  const closeCheckout = useCallback(
    (trackFunc) => {
      if (trackFunc && typeof trackFunc === 'function') trackFunc();
      invalidateSessionCache(dispatch);
      if (isNativeClose) nativeBridge.goBack();
      else navigateBack();
    },
    [dispatch, isNativeClose, navigateBack],
  );

  return { closeCheckout };
};
