import { updateHistoryLengthBeforeNavigation } from '@app/contexts/mainContext/actions/updateHistoryLengthBeforeNavigation';
import { useAppDispatch } from '@app/contexts/mainContext/selectors/useAppDispatch';
import { useCheckoutId } from '@app/contexts/mainContext/selectors/useCheckoutId';
import { useHistoryLengthBeforeNavigation } from '@app/contexts/mainContext/selectors/useHistoryLengthBeforeNavigation';
import { useHistory } from '@hooks/navigation/useHistory';
import { useEffect } from 'react';

export const useHistoryPositionUpdate = () => {
  const { length } = useHistory();
  const { checkoutId: checkoutIdBeforeNavigation, length: lengthBeforeNavigation } = useHistoryLengthBeforeNavigation();
  const checkoutId = useCheckoutId();
  const dispatch = useAppDispatch();

  const historyPositionAlreadyExists = checkoutId === checkoutIdBeforeNavigation && Boolean(lengthBeforeNavigation);

  useEffect(() => {
    if (checkoutId && !historyPositionAlreadyExists) {
      updateHistoryLengthBeforeNavigation(dispatch, checkoutId, length);
    }
  }, [checkoutId, dispatch, historyPositionAlreadyExists, length]);
};
