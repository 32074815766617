import dingDong from '@assets/audio/ding_dong.mp3';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import BottomSheet from '../BottomSheet';
import Container from './components/Container';
import Content from './components/Content';
import FullScreenSuccess from './components/FullScreenSuccess';
import IconWrapper from './components/IconWrapper';
import Success from './components/Success';
import { DING_DONG_DURATION, SHOW_BOTTOM_SHEET_TIMEOUT } from './constants';

export const Confirm = ({ title, subtitle, isDesktopLayout, onAnimationEnd }) => {
  const audioRef = useRef();
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  useEffect(() => {
    const showBottomSheet = () => {
      setIsBottomSheetOpen(true);
      if (audioRef.current) {
        audioRef.current.muted = false;
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
    };

    const bottomSheetTimeout = setTimeout(showBottomSheet, SHOW_BOTTOM_SHEET_TIMEOUT);
    const navigationTimeout = setTimeout(onAnimationEnd, DING_DONG_DURATION);

    return () => {
      clearTimeout(bottomSheetTimeout);
      clearTimeout(navigationTimeout);
    };
  }, [onAnimationEnd]);

  return (
    <>
      <audio ref={audioRef} src={dingDong} autoPlay muted />
      {isDesktopLayout ? (
        <Container id="desktop_confirm_success">
          <IconWrapper>
            <FullScreenSuccess>
              <Content title={title} subtitle={subtitle} desktopMode />
            </FullScreenSuccess>
          </IconWrapper>
        </Container>
      ) : (
        <>
          <Container id="success">
            <IconWrapper>
              <Success />
            </IconWrapper>
          </Container>
          <BottomSheet open={isBottomSheetOpen} showOverlay={false}>
            <Content title={title} subtitle={subtitle} />
          </BottomSheet>
        </>
      )}
    </>
  );
};

Confirm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isDesktopLayout: PropTypes.bool,
  onAnimationEnd: PropTypes.func,
};

Confirm.defaultProps = {
  subtitle: '',
  isDesktopLayout: false,
  onAnimationEnd: () => {},
};

export default Confirm;
