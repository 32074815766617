export const OS = {
  ANDROID: 'Android',
  IOS: 'iOS',
  UNKNOWN: 'unknown',
};

export const webNavigationHeaderVersion = {
  ANDROID_VERSION: '5.24.0',
  IOS_VERSION: '10.24.0',
};

export const DEVICE_TYPE = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export const BREAKPOINTS = {
  MOBILE: 768,
  DESKTOP: 1024,
};
